import { Settings, RoleType } from '@seliaco/red-panda'

export const SettingsStore = {
  namespaced: true,
  state: {
    settings: null,
    loading: false
  },
  getters: {
    getSettings (state) {
      return { ...state.settings }
    },
    getLoading (state) {
      return state.loading
    },
  },
  mutations: {
    SET_SETTINGS (state, payload) {
      state.settings = {
        ...payload
      }
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit, state, rootState }) {
      const role = rootState.auth.role

      if (!state.settings) {
        commit('SET_LOADING', { loading: true })
      }

      let keyBanner = 'CROSS_SELLING_BANNER_CONFIGURATION'

      if (RoleType.USER_HIDE_PRICES === role) {
        keyBanner = 'CROSS_SELLING_BANNER_HIDE_PRICES_CONFIGURATION'
      }

      const [conferenceModule, countries, banners, sharedAccountOptions, videoCallVersion] =
        await Promise.all([
          Settings.get('SELIA_CONFERENCES').then((response) => response.parse_value),
          Settings.get('COUNTRIES').then((response) => response.parse_value),
          Settings.get(keyBanner).then((setting) => setting.parse_value),
          Settings.get('SHARED_ACCOUNT_ROLE').then((response) => response.parse_value),
          Settings.get('VIDEO_CALL_VERSION').then((response) => response.parse_value),
        ])

      commit('SET_SETTINGS', {
        countries,
        conferenceModule,
        banners,
        sharedAccountOptions,
        videoCallVersion
      })

      commit('SET_LOADING', { loading: false })
    }
  }
}
