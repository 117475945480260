<template>
  <DialogContainer
    :icon="handlerIcon.icon"
    :contentIconBackground="handlerIcon.background"
    @closeDialog="handlerClose"
  >
    <template v-slot:content>
      <template v-if="showIntro">
        <HtmlViewer class="heading" :html-content="startTranslations.title" />

        <div
          class="start-modal-init"
          :class="responsiveClass('margin-top', 'margin-top-m-2')"
        >
          <div class="body" v-text="startTranslations.content" />
          <div
            class="start-modal-init-content body"
            :class="responsiveClass('margin-top', 'margin-top-m-2')"
          >
            <span class="icon-checkmark-semi-circle icon-display text-purple" />
            <span v-text="startTranslations.content1" />
          </div>
          <div
            class="body"
            :class="responsiveClass('margin-top', 'margin-top-m-2')"
            v-text="startTranslations.content2"
          />
        </div>

        <Button
          :class="responsiveClass('margin-top-lg-2', 'margin-top-lg-3')"
          class="margin-bottom-xs"
          :text="startTranslations.action"
          @clicked="showIntro = false"
        />
        <Button
          type="outline"
          :text="startTranslations.close"
          @clicked="handlerClose"
        />
      </template>

      <template v-else-if="isChild">
        <div
          class="heading"
          :class="responsiveClass('margin-top', 'margin-top-m-2')"
          v-text="childTranslations.title"
        />
        <div
          class="body"
          :class="responsiveClass('margin-top', 'margin-top-m-2')"
          v-text="childTranslations.content"
        />

        <Button
          :class="responsiveClass('margin-top-lg-2', 'margin-top-lg-3')"
          color="red"
          :text="childTranslations.action"
          @clicked="handlerClose"
        />
      </template>

      <template v-else>
        <div
          class="heading"
          :class="responsiveClass('margin-top', 'margin-top-m-2')"
          v-text="inviteTranslations.title"
        />
        <div
          class="body"
          :class="responsiveClass('margin-top', 'margin-top-m-2')"
          v-text="inviteTranslations.content"
        />

        <SharedAccountForm
          @form="form = $event"
          :class="responsiveClass('margin-top-lg-2', 'margin-top-lg-3')"
        />

        <Button
          :class="responsiveClass('margin-top-lg-2', 'margin-top-lg-3')"
          :text="inviteTranslations.action"
          :disable="!form.name || !form.email || !form.role"
          @clicked="handlerSubmit"
        />
        <Button
          class="margin-top-s"
          type="outline"
          :text="inviteTranslations.close"
          @clicked="handlerClose"
        />
      </template>
    </template>
  </DialogContainer>
</template>

<script>
import {
  calculateAge,
  Button,
  DialogContainer,
  HtmlViewer,
  ResponsiveMixin
} from '@seliaco/red-panda'

import { mapGetters } from 'vuex'

import SharedAccountForm from '../form/SharedAccountForm.vue'

export default {
  name: 'SharedAccountStartModal',
  components: {
    Button,
    DialogContainer,
    HtmlViewer,
    SharedAccountForm
  },
  props: {
    invitation: Boolean
  },
  mixins: [ResponsiveMixin],
  data () {
    const startModalTranslations = {
      title: this.$translations['shared-account'].modal.start.title,
      content: this.$translations['shared-account'].modal.start.content,
      content1: this.$translations['shared-account'].modal.start.content1,
      content2: this.$translations['shared-account'].modal.start.content2,
      action: this.$translations['shared-account'].modal.start.buttons.action,
      close: this.$translations['shared-account'].modal.start.buttons.close
    }

    const childModalTranslations = {
      title: this.$translations['shared-account'].modal.child.title,
      content: this.$translations['shared-account'].modal.child.content,
      action: this.$translations['shared-account'].modal.child.buttons.action
    }

    const InviteModalTranslations = {
      title: this.$translations['shared-account'].modal.invite.title,
      content: this.$translations['shared-account'].modal.invite.content,
      action: this.$translations['shared-account'].modal.invite.buttons.action,
      close: this.$translations['shared-account'].modal.invite.buttons.close
    }

    return {
      startTranslations: startModalTranslations,
      childTranslations: childModalTranslations,
      inviteTranslations: InviteModalTranslations,
      showIntro: true,
      isChild: true,
      form: {
        name: '',
        email: '',
        role: ''
      }
    }
  },
  created () {
    if (this.invitation) {
      this.showIntro = false
      this.isChild = false
    }

    this.isChild = calculateAge(this.user.birthday) < 18
  },
  methods: {
    handlerSubmit () {
      this.handlerClose()

      if (this.invitation) {
        return this.setInvite()
      }
      this.$store.dispatch('sharedAccount/createAccount').then(() => {
        this.setInvite()
      })
    },
    setInvite () {
      const props = {
        first_name: this.form.name,
        email: this.form.email,
        role: this.form.role
      }

      this.$store.dispatch('sharedAccount/add', props).then(() => {
        this.$toast({
          severity: 'success',
          text: this.$translations['shared-account'].toast['invite-success']
        })
        this.$router.push({ name: 'SharedAccounts' })
      })
    },
    handlerClose () {
      this.$globalEvent.$emit('modal/shared-account-start', {
        showDialog: { sharedAccountStartModal: { open: false, close: true } }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    }),
    handlerIcon () {
      let icon = ''
      let background = ''

      if (!this.showIntro) {
        icon = 'icon-group text-purple'
        background = 'bg-purple-10'

        if (this.isChild) {
          icon = 'icon-close-circle-on text-red'
          background = 'bg-red-10'
        }
      }

      return {
        icon,
        background
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.start-modal-init
  text-align: left
  &-content
    display: flex
    gap: 8px
</style>
