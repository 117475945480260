import {
  Appointments,
  Toast,
  SharedAccountAppointment
} from '@seliaco/red-panda'
import { DateTime } from 'luxon'
import translationsFile from '@/assets/translations.json'

const mapperAppointments = (appointments, user) => {
  const appointmentMapper = {
    upcoming: {},
    past: {}
  }

  const currentTime = DateTime.local()
    .setZone(user.time_zone)
    .setLocale(user.language)

  for (const appointment of appointments) {
    const appointmentStartsAt = DateTime.fromISO(appointment.starts_at, {
      zone: user.time_zone
    })
      .plus({ minutes: appointment.service.service_type.duration })
      .setLocale(user.language)

    const appointmentDate = DateTime.fromISO(appointment.starts_at, {
      zone: user.time_zone
    }).toFormat('yyyy-MM-dd')

    if (appointmentStartsAt < currentTime) {
      if (appointmentMapper.past[appointmentDate] === undefined) {
        appointmentMapper.past[appointmentDate] = []
      }

      appointmentMapper.past[appointmentDate].push(appointment)
    } else {
      if (appointmentMapper.upcoming[appointmentDate] === undefined) {
        appointmentMapper.upcoming[appointmentDate] = []
      }

      appointmentMapper.upcoming[appointmentDate].push(appointment)
    }
  }

  return { ...appointmentMapper }
}

export const MyAppointmentsStore = {
  namespaced: true,

  state: {
    appointments: null,
    loading: true,
    preventMultipleCalls: true
  },
  getters: {
    getAppointments (state) {
      return state.appointments
    },
    getLoading (state) {
      return state.loading
    },
    getPreventMultipleCalls (state) {
      return state.preventMultipleCalls
    }
  },
  mutations: {
    SET_APPOINTMENTS (state, { appointments }) {
      state.appointments = appointments
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    },
    SET_PREVENT_MULTIPLE_CALLS (state, { preventMultipleCalls }) {
      state.preventMultipleCalls = preventMultipleCalls
    }
  },
  actions: {
    async get ({ commit, state, rootState }, query = { page: 1, per_page: 10 }) {
      const lang = rootState.language.lang
      const user = rootState.auth.user

      if (!state.appointments) {
        commit('SET_LOADING', { loading: true })
      }

      await commit('SET_PREVENT_MULTIPLE_CALLS', { preventMultipleCalls: true })
      const appointmentsReq = (await Appointments.listUsrAppointments(
        query
      ).catch((e) => {
        Toast({
          severity: 'error',
          text: e.message || translationsFile[lang].error.appointments
        })
      })) || { data: [], headers: {} }
      await commit('SET_PREVENT_MULTIPLE_CALLS', {
        preventMultipleCalls: false
      })

      const { data: appointments, headers } = appointmentsReq

      let appointmentsMapped = {
        upcoming: {},
        past: {},
        totalAppointments: appointments,
        pages: headers.pages,
        count: headers.count
      }

      if (appointments.length === 0) {
        commit('SET_LOADING', { loading: false })
        commit('SET_APPOINTMENTS', { appointments: appointmentsMapped })
        return
      }

      const { upcoming, past } = mapperAppointments(appointments, user)

      if (query.page > 1 && appointments.length > 0) {
        appointmentsMapped = {
          upcoming: { ...state.appointments.upcoming, ...upcoming },
          past: { ...state.appointments.past, ...past },
          totalAppointments:
            state.appointments.totalAppointments.concat(appointments),
          pages: headers.pages,
          count: headers.count
        }

        commit('SET_APPOINTMENTS', { appointments: appointmentsMapped })
        commit('SET_LOADING', { loading: false })
        return
      }

      appointmentsMapped = {
        upcoming: { ...upcoming },
        past: { ...past },
        totalAppointments: appointments,
        pages: headers.pages,
        count: headers.count
      }

      const appointmentsResult =
        appointmentsMapped.upcoming || appointmentsMapped.past
          ? appointmentsMapped
          : null

      commit('SET_APPOINTMENTS', { appointments: appointmentsResult })
      commit('SET_LOADING', { loading: false })
    },
    async getByPatientId (
      { commit, state, rootState },
      query = { page: 1, per_page: 10 }
    ) {
      if (!state.appointments) {
        commit('SET_LOADING', { loading: true })
      }

      const sharedAccountId = rootState.sharedAccount.sharedAccountId
      const memberId = rootState.sharedAccount.member?.id
      const user = rootState.auth.user

      await commit('SET_PREVENT_MULTIPLE_CALLS', { preventMultipleCalls: true })

      const appointmentsReq = (await SharedAccountAppointment.list(
        sharedAccountId,
        memberId,
        query
      )) || { data: [], headers: {} }

      await commit('SET_PREVENT_MULTIPLE_CALLS', {
        preventMultipleCalls: false
      })

      const { data: appointments, headers } = appointmentsReq

      let appointmentsMapped = {
        upcoming: {},
        past: {},
        totalAppointments: appointments,
        pages: headers.pages,
        count: headers.count
      }

      if (appointments.length === 0) {
        commit('SET_LOADING', { loading: false })
        commit('SET_APPOINTMENTS', { appointments: appointmentsMapped })
        return
      }

      const { upcoming, past } = mapperAppointments(appointments, user)

      if (query.page > 1 && appointments.length > 0) {
        appointmentsMapped = {
          upcoming: { ...state.appointments.upcoming, ...upcoming },
          past: { ...state.appointments.past, ...past },
          totalAppointments:
            state.appointments.totalAppointments.concat(appointments),
          pages: headers.pages,
          count: headers.count
        }

        commit('SET_APPOINTMENTS', { appointments: appointmentsMapped })
        commit('SET_LOADING', { loading: false })
        return
      }

      appointmentsMapped = {
        upcoming: { ...upcoming },
        past: { ...past },
        totalAppointments: appointments,
        pages: headers.pages,
        count: headers.count
      }

      const appointmentsResult =
        appointmentsMapped.upcoming || appointmentsMapped.past
          ? appointmentsMapped
          : null

      commit('SET_APPOINTMENTS', { appointments: appointmentsResult })
      commit('SET_LOADING', { loading: false })
    }
  }
}
