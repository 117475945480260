import { split } from '@seliaco/growthbook'

export const GrowthBookStore = {
  namespaced: true,
  state: {
    scheduleSurvey: false,
    specialistProfile: false,
    dateOfBirthV2: false,
    serviceCardVer: 0,
    specialistCertifications: false,
    warrentyBanner: false,
    matchingResult: 2
  },
  getters: {
    getSpecialistProfile (state) {
      return state.specialistProfile
    },
    getDateOfBirthV2 (state) {
      return state.dateOfBirthV2
    },
    getServiceCardVer (state) {
      return state.serviceCardVer
    },
    getSpecialistCertifications (state) {
      return state.specialistCertifications
    },
    warrentyBanner (state) {
      return state.warrentyBanner
    },
    matchingResult (state) {
      return state.matchingResult
    }
  },
  mutations: {
    SET_SCHEDULE_SURVEY (state, { scheduleSurvey }) {
      state.scheduleSurvey = scheduleSurvey
    },
    SET_SPECIALIST_PROFILE (state, { specialistProfile }) {
      state.specialistProfile = specialistProfile
    },
    SET_DATEPICKER_VIEW (state, { datepickerView }) {
      state.datepickerView = datepickerView
    },
    SET_DATE_OF_BIRTH_V2 (state, { dateOfBirthV2 }) {
      state.dateOfBirthV2 = dateOfBirthV2
    },
    SET_SPECIALIST_CERTIFICATIONS (state, { specialistCertifications }) {
      state.specialistCertifications = specialistCertifications
    },
    SET_SPECIALIST_CARD_V2 (state, { specialistCardV2 }) {
      state.specialistCardV2 = specialistCardV2
    },
    SET_SERVICE_CARD_VER (state, { serviceCardVer }) {
      state.serviceCardVer = serviceCardVer
    },
    SET_WARRENTY_BANNER (state, { warrentyBanner }) {
      state.warrentyBanner = warrentyBanner
    },
    SET_MATCHING_RESULT (state, { matchingResult }) {
      state.matchingResult = matchingResult
    },
    SET_VALUES (state, { key, value }) {
      state[key] = value
    }
  },
  actions: {
    async getGeneralConfig ({ dispatch }) {
      dispatch('getSpecialistProfile')
      dispatch('getDateOfBirthV2')
      dispatch('getServiceCardVer')
      dispatch('getSpecialistCertifications')
      dispatch('getWarrentyBanner')
      dispatch('getMatchingResult')
    },
    async getSpecialistCardV2Config ({ commit }) {
      const specialistCardV2Result = await split.growthbook.isOn(
        'specialist-card-v2'
      )
      commit('SET_SPECIALIST_CARD_V2', {
        specialistCardV2: specialistCardV2Result
      })
    },
    async getSpecialistProfile ({ commit }) {
      const specialistProfile = await split.growthbook.isOn(
        'profile-datepicker'
      )
      commit('SET_SPECIALIST_PROFILE', { specialistProfile: specialistProfile })
    },
    async getDateOfBirthV2 ({ commit }) {
      const dateOfBirthV2 = await split.growthbook.isOn(
        'date-of-birth-input-field'
      )
      commit('SET_DATE_OF_BIRTH_V2', { dateOfBirthV2 })
    },
    async getServiceCardVer ({ commit }) {
      const serviceCardVer = await split.growthbook.isOn('package-savings-card')
      commit('SET_SERVICE_CARD_VER', { serviceCardVer: serviceCardVer ? 1 : 0 })
    },
    async getSpecialistCertifications ({ commit }) {
      const specialistCertifications = await split.growthbook.isOn(
        'specialist-profile-certifications'
      )
      commit('SET_SPECIALIST_CERTIFICATIONS', { specialistCertifications })
    },
    async getWarrentyBanner ({ commit }) {
      const warrentyBanner = await split.growthbook.isOn('reservations-timer')
      commit('SET_WARRENTY_BANNER', { warrentyBanner })
    },
    async getMatchingResult ({ commit }) {
      const matchingResult = await split.growthbook.getFeatureValue(
        'matching-results'
      )
      commit('SET_MATCHING_RESULT', { matchingResult })
    }
  }
}
