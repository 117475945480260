export const messagesByKey = (lang, key = 'DEFAULT') => {
  const messages = {
    es: {
      MATCHING: [
        'No está mal sentirse mal, lo que está mal es no pedir ayuda cuando lo necesites.',
        'No esperes más, hoy es el día para buscar ayuda.',
        'Tu bienestar emocional es importante, busca apoyo profesional.',
        'No lleves tus cargas en silencio, busca ayuda psicológica.',
        'La psicoterapia puede ser la clave para superar tus desafíos.',
        'Todos merecemos apoyo emocional, busca ayuda psicológica.',
        'No dejes que tus problemas te controlen, busca ayuda para enfrentarlos.',
        'La psicoterapia puede ayudarte a descubrir tu verdadero potencial.',
        'Buscar ayuda cuando la necesitas, es el mejor regalo que te puedes dar.',
        'La psicoterapia te ayuda a construir una mente fuerte y saludable.',
        'El mejor día para pedir ayuda fue ayer, ¿el segundo mejor? ¡hoy mismo!',
        'La psicoterapia te ayuda a construir herramientas para una vida equilibrada.',
        'No estás solo en tu lucha, busca apoyo y comprensión en la terapia.',
        'No hay problema demasiado grande o demasiado pequeño para buscar ayuda.',
        'No permitas que la vergüenza te impida buscar ayuda, todos necesitamos apoyo.',
        'No te escondas detrás de una sonrisa falsa, busca ayuda para sanar desde adentro.',
        'La psicoterapia te ayuda a reconstruir tu camino hacia la felicidad.',
        'Buscar ayuda no es señal de debilidad, es una muestra de amor propio.'
      ],
      DEFAULT: [
        'Encontrar a un psicólogo es un paso valiente hacia el autocuidado.',
        'La terapia es un acto de amor propio. Comienza tu viaje de autoamor hoy.',
        'Tu salud mental es valiosa. Buscar apoyo es un gran logro.',
        'Todos merecen apoyo emocional. Estamos aquí para ayudarte.',
        'Tomar la decisión de buscar ayuda es un signo de fortaleza.',
        'Tu bienestar importa. Estamos aquí para apoyarte.',
        '¡71% de nuestros pacientes están viviendo vidas más felices!',
        'La salud mental es tan importante como la física. ¡Priorízala!',
        'No estás solo. Más de 19,000 personas ya han encontrado ayuda aquí.',
        'Cuida de tu bienestar. Estás en buenas manos con Selia.',
        'Encuentra la calma en medio de la tormenta con Selia.',
        'El viaje hacia la sanación comienza con el primer paso. ¿Listo para darlo?',
        'Cada cita es un paso hacia una versión más fuerte y feliz de ti mismo.',
        'Confía en el proceso. Estamos aquí para guiarte en cada paso del camino.',
        'Habla de tus sentimientos. No hay vergüenza en pedir ayuda.',
        'Haz algo que te haga feliz todos los días, sin importar lo pequeño que sea.',
        'Cada sesión es un espacio seguro para crecer y sanar. Te estamos esperando.',
        'No importa cuán oscuro parezca ahora, hay esperanza en cada sesión.',
        'La vida puede ser difícil, pero no tienes que enfrentarla solo. Estamos aquí para ayudarte a brillar incluso en los días más oscuros.',
        '¿Listo para sentirte mejor? Con Selia, no estás solo en este viaje.'
      ],
      NO_MESSAGE: []
    },
    en: {
      MATCHING: [
        "It's not bad to feel bad, what's wrong is not asking for help when you need it.",
        "Don't wait any longer, today is the day to seek help.",
        'Your emotional well-being is important, seek professional support.',
        "Don't carry your burdens in silence, seek psychological help.",
        'Psychotherapy can be the key to overcoming your challenges.',
        'We all deserve emotional support, seek psychological help.',
        "Don't let your problems control you, seek help to face them.",
        'Psychotherapy can help you discover your true potential.',
        'Seeking help when you need it is the best gift you can give yourself.',
        'Psychotherapy helps you build a strong and healthy mind.',
        'The best day to ask for help was yesterday, the second best? Today!',
        'Psychotherapy helps you build tools for a balanced life.',
        'You are not alone in your struggle, seek support and understanding in therapy.',
        'No problem is too big or too small to seek help.',
        "Don't let shame stop you from seeking help, we all need support.",
        "Don't hide behind a fake smile, seek help to heal from within.",
        'Psychotherapy helps you rebuild your path to happiness.',
        'Seeking help is not a sign of weakness, it is a sign of self-love.'
      ],
      DEFAULT: [
        'Finding a psychologist is a brave step towards self-care.',
        'Therapy is an act of self-love. Start your journey of self-love today.',
        'Your mental health is valuable. Seeking support is a great achievement.',
        'Everyone deserves emotional support. We are here to help you.',
        'Making the decision to seek help is a sign of strength.',
        'Your well-being matters. We are here to support you.',
        '71% of our patients are living happier lives!',
        'Mental health is as important as physical health. Prioritize it!',
        'You are not alone. Over 19,000 people have already found help here.',
        'Take care of your well-being. You are in good hands with Selia.',
        'Find calm in the midst of the storm with Selia.',
        'The journey to healing begins with the first step. Ready to take it?',
        'Each appointment is a step towards a stronger and happier version of yourself.',
        'Trust the process. We are here to guide you every step of the way.',
        'Talk about your feelings. There is no shame in asking for help.',
        'Do something that makes you happy every day, no matter how small.',
        'Each session is a safe space to grow and heal. We are waiting for you.',
        'No matter how dark it may seem now, there is hope in every session.',
        "Life can be tough, but you don't have to face it alone. We are here to help you shine even on the darkest days.",
        'Ready to feel better? With Selia, you are not alone on this journey.'
      ],
      NO_MESSAGE: []
    },
    pt: {
      MATCHING: [
        'Não é ruim se sentir mal, o que está errado é não pedir ajuda quando você precisa.',
        'Não espere mais, hoje é o dia para procurar ajuda.',
        'Seu bem-estar emocional é importante, procure apoio profissional.',
        'Não carregue seus fardos em silêncio, procure ajuda psicológica.',
        'A psicoterapia pode ser a chave para superar seus desafios.',
        'Todos merecemos apoio emocional, procure ajuda psicológica.',
        'Não deixe seus problemas controlarem você, procure ajuda para enfrentá-los.',
        'A psicoterapia pode ajudá-lo a descobrir seu verdadeiro potencial.',
        'Procurar ajuda quando você precisa é o melhor presente que você pode se dar.',
        'A psicoterapia ajuda você a construir uma mente forte e saudável.',
        'O melhor dia para pedir ajuda foi ontem, o segundo melhor? Hoje mesmo!',
        'A psicoterapia ajuda você a construir ferramentas para uma vida equilibrada.',
        'Você não está sozinho em sua luta, procure apoio e compreensão na terapia.',
        'Nenhum problema é grande ou pequeno demais para procurar ajuda.',
        'Não deixe que a vergonha o impeça de procurar ajuda, todos nós precisamos de apoio.',
        'Não se esconda atrás de um sorriso falso, procure ajuda para curar de dentro para fora.',
        'A psicoterapia ajuda você a reconstruir seu caminho para a felicidade.',
        'Procurar ajuda não é sinal de fraqueza, é um sinal de amor próprio.'
      ],
      DEFAULT: [
        'Encontrar um psicólogo é um passo corajoso em direção ao autocuidado.',
        'A terapia é um ato de amor próprio. Comece sua jornada de autoamor hoje.',
        'Sua saúde mental é valiosa. Buscar apoio é uma grande conquista.',
        'Todos merecem apoio emocional. Estamos aqui para ajudar você.',
        'Tomar a decisão de procurar ajuda é um sinal de força.',
        'Seu bem-estar importa. Estamos aqui para apoiar você.',
        '71% dos nossos pacientes estão vivendo vidas mais felizes!',
        'A saúde mental é tão importante quanto a física. Priorize-a!',
        'Você não está sozinho. Mais de 19.000 pessoas já encontraram ajuda aqui.',
        'Cuide do seu bem-estar. Você está em boas mãos com Selia.',
        'Encontre a calma no meio da tempestade com Selia.',
        'A jornada rumo à cura começa com o primeiro passo. Pronto para dar esse passo?',
        'Cada consulta é um passo em direção a uma versão mais forte e feliz de si mesmo.',
        'Confie no processo. Estamos aqui para orientar você a cada passo do caminho.',
        'Fale sobre seus sentimentos. Não há vergonha em pedir ajuda.',
        'Faça algo que o faça feliz todos os dias, não importa o quão pequeno seja.',
        'Cada sessão é um espaço seguro para crescer e se curar. Estamos esperando por você.',
        'Não importa o quão sombrio pareça agora, há esperança em cada sessão.',
        'A vida pode ser difícil, mas você não precisa enfrentá-la sozinho. Estamos aqui para ajudá-lo a brilhar mesmo nos dias mais escuros.',
        'Pronto para se sentir melhor? Com Selia, você não está sozinho nesta jornada.'
      ],
      NO_MESSAGE: []
    }
  }
  return messages[lang][key]
}
