<template>
  <ModalFields
    icon="icon-close"
    @back-action="handlerBack"
    @close-action="closeModal"
    v-if="config"
    :without-header="true"
    height="700px"
  >
    <template v-slot:content>
      <div class="check-in-dialog-welcome" v-if="!showForm">
        <img width="400px" :src="welcome.img" alt="welcome" />
        <div class="heading-big text-white" v-text="welcome.title" />
        <div class="body text-white" v-text="welcome.text" />
        <Button
          :type="welcome.button.type"
          :text="welcome.button.text"
          color="white"
          @clicked="showForm = true"
        />
      </div>
      <div class="check-in-dialog-form" v-if="showForm">
        <FormContainer
          @finish-form-values="isDone"
          @config-with-values="safeData"
          @previous-page="changeConfig"
          :config="config"
          :lang="$store.getters.language.lang"
          :next-step="nextStep"
          :has-back-button="true"
        />
      </div>
    </template>
  </ModalFields>
</template>

<script>
import {
  CheckIn,
  FormContainer,
  Settings,
  ModalFields,
  PNG,
  Button
} from '@seliaco/red-panda'
import { CheckInEvent } from '@/types/events'
export default {
  name: 'CheckInDialog',
  components: {
    FormContainer,
    ModalFields,
    Button
  },
  data () {
    const welcome = {
      img: PNG.DudesResearch,
      title: this.$translations['check-in']['modal-welcome'].title,
      text: this.$translations['check-in']['modal-welcome'].text,
      button: {
        text: this.$translations['check-in']['modal-welcome'].button,
        type: 'block'
      }
    }

    return {
      config: null,
      nextStep: false,
      welcome,
      showForm: false,
      counter: null
    }
  },
  created () {
    this.start()
    this.getStep()
  },
  methods: {
    async start () {
      const body = {
        type: 'WELLNESS',
        always_create: true
      }
      this.counter = await CheckIn.startWellnessCheckIn(body)
    },
    async getStep () {
      const key = 'CHECK_IN_WELLNESS_CONFIGURATION'

      const options = await Settings.get(key)
      this.config = options.parse_value
    },
    changeConfig () {
      this.config = JSON.parse(
        localStorage.getItem('selia-check-in-modal-form')
      )
    },
    callSegmentEvent (value) {
      const month = new Date(
        new Date().getFullYear(),
        value.month - 1
      ).toLocaleString(`${this.$store.getters.language.lang}`, {
        month: 'short'
      })
      let score = value.score
      if (!value.score) {
        score = '+'
      }
      const body = {
        user: this.$store.getters['auth/user'],
        month: month,
        month_number: value.month,
        type: value.type,
        score: score
      }
      CheckInEvent.Checkin_FillUp_Successful(body)
    },
    isDone (form) {
      form = form.map((option) => {
        return {
          question: option.key,
          value:
            option.response?.value >= 0
              ? option.response.value
              : option.response,
          type: option.response?.value >= 0 ? 'NUMBER' : 'TEXT'
        }
      })

      const body = {
        checking_id: this.counter.id,
        type: this.counter.type,
        answers: form
      }

      CheckIn.evaluateWellnessCheckIn(body)
        .then(() => {
          CheckIn.getCheckInAnswerData(body.checking_id)
            .then((response) => {
              this.$store.dispatch('auth/fetchUser')
              this.callSegmentEvent(response)
              this.$router.replace({
                name: 'Home',
                query: {
                  type: response.type,
                  showModal: 'visible',
                  data: JSON.stringify({
                    ...response,
                    month:
                      this.$moment.locale(this.$store.getters.language.lang) &&
                      this.$moment.months(response.month - 1)
                  })
                }
              })

              this.closeModal()
            })
            .catch((e) => {
              this.$toast({
                text: e.message || this.$translations.error.default,
                severity: 'error'
              })
            })
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    safeData (val) {
      localStorage.setItem(
        'selia-check-in-modal-form',
        JSON.stringify([...val])
      )
      this.nextStep = true
      setTimeout(() => {
        this.nextStep = false
      }, 300)
    },
    closeModal () {
      this.$globalEvent.$emit('modal/check-in-dialog', {
        showDialog: { checkInModal: { open: false, close: true } }
      })

      CheckInEvent.Checkin_Exit_Form({ user: this.$store.getters['auth/user'] })
    },
    handlerBack () {
      this.$globalEvent.$emit('modal/check-in-dialog', {
        showDialog: { checkInModal: { open: false, close: true } }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.check-in-dialog-form
  padding: 16px
  ::v-deep
    .form-container-content
      min-height: 500px
    .form-container-finish-content
      height: 600px

.check-in-dialog-welcome
  background: var(--purple)
  width: 100%
  height: 700px
  margin: -20px 0
  padding: 16px
  display: flex
  align-items: center
  flex-direction: column
  justify-content: space-evenly
  text-align: center
</style>
