/**
 * Styles
 */
import '@seliaco/icons/style.css'
// import '../../red-panda/src/index.scss'
// import '../../sea-otter/src/index.scss'
import '@seliaco/red-panda/src/index.scss'
import '@seliaco/sea-otter/src/index.scss'

/**
 * Red panda
 */
import {
  Auth,
  Kustomer,
  Segment,
  EventEmitterInstance as RedPandaEventEmitterInstance,
  TOPICS as RED_PANDA_TOPICS,
  Logger,
  Toast,
  PushNotifications,
  $globalEvent,
  Version
} from '@seliaco/red-panda'

// import { Auth } from '../../red-panda/src/services/auth/auth.js'

/**
 * Growth book
 */
import { loadAllClients as growthBook } from '@seliaco/growthbook'

/**
 * Capacitor
 */
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SplashScreen } from '@capacitor/splash-screen'

/**
 * Assets
 */
import translationsFile from '@/assets/translations.json'
import languageCodes from '@/assets/language-codes.json'

/**
 * App dependencies
 */
import Vue from 'vue'
import WebApp from './App.vue'
import router from './router'
import store from './store'
import { version } from '../package.json'

/**
 * Other libraries
 */
import smoothscroll from 'smoothscroll-polyfill'
import moment from 'moment'
import Hotjar from '@hotjar/browser'
import { handleRemoveLoader, handleShowLoader } from '../public/scripts/loader'
import * as luxon from 'luxon'
import * as Sentry from "@sentry/vue";

const timedPromise = (promise) => {
  const startTime = performance.now()

  return promise.call().then((result) => {
    const endTime = performance.now()
    const elapsedMilliseconds = endTime - startTime

    if (elapsedMilliseconds > 10) {
      console.log(`${promise.name}: ${elapsedMilliseconds}ms`)
    }

    return result
  })
}
const authenticatedPromises = async (app, lang) => {
  const user = app.$store.getters['auth/user']
  const platform = app.$store.getters.platform

  let response = {}

  if (!user) {
    localStorage.removeItem(
      `selia_${process.env.VUE_APP_AUTH_MODE.toLowerCase()}_access_token`
    )
    app.$kustomer.signOut(platform)
    app.$segment.reset()

    return {
      ...response,
      updatedLang: lang
    }
  }

  const promises = [
    {
      name: 'Set',
      call: async () => {
        app.$store.dispatch('settings/get')
        app.$store.dispatch('sharedAccount/getAccount')
      }
    },
    {
      name: 'Kus',
      call: async () => app.$kustomer.signIn(platform, user.kustomer_jwt)
    },
    {
      name: 'Gro',
      call: async () =>
        growthBook({ user }).then(() =>
          app.$store.dispatch('growthBook/getGeneralConfig')
        )
    },
    {
      name: 'Seg',
      call: async () => app.$segment.identifyAppUser(user)
    },
    {
      name: 'Loc',
      call: async () => app.$store.dispatch('setLocalization')
    }
  ]

  const query = router.currentRoute.query

  if (query?.sharedAccountId && query?.sharedAccountMemberId) {
    promises.push({
      name: 'ShareAccount',
      call: async () => {
        await app.$store.dispatch('sharedAccount/activation', {
          sharedAccountId: query.sharedAccountId,
          sharedAccountMemberId: query.sharedAccountMemberId
        })
      }
    })
  }

  const timedPromises = promises.map((promise) => timedPromise(promise))

  await Promise.all(timedPromises).then(() => {
    let query = router.currentRoute.query
    if (query?.sharedAccountId && query?.sharedAccountMemberId) {
      delete query.sharedAccountId
      delete query.sharedAccountMemberId
      router.replace({ query })
    }
  })

  return {
    ...response,
    updatedLang: user.language
  }
}
const promisesResolver = async (app, info, lang) => {
  let response = {
    updatedLang: lang
  }

  const promises = [
    {
      name: 'Ver',
      call: async () => Version.check('usr', info.platform, version)
    },
    {
      name: 'Pus',
      call: async () => {
        if (info.platform === 'web') {
          return
        }

        return PushNotifications.init(router)
      }
    },
    {
      name: 'Bar',
      call: async () => {
        if (info.platform === 'web') {
          return
        }

        try {
          await StatusBar.setStyle({ style: Style.Light })
          await StatusBar.setBackgroundColor({ color: '#ffffff' })
        } catch (e) {
          console.error('Unable to set status bar', e)
        }
      }
    },
    {
      name: 'Kus',
      call: async () => app.$kustomer.init(app.$platform)
    },
    {
      name: 'Seg',
      call: async () => {
        const params = {}

        if (location.search) {
          const parts = location.search.substring(1).split('&')

          for (let i = 0; i < parts.length; i++) {
            const nv = parts[i].split('=')
            if (!nv[0]) {
              continue
            }
            params[nv[0]] = nv[1] || true
          }
        }

        if (params.utm_source || params.coupon_code) {
          app.$store.commit('UPDATE_QUERY', params)
        }

        app.$segment.init({
          platform: app.$platform,
          version: app.$version,
          marketing_channel: params.utm_campaign,
          ...app.$store.getters.query
        })
      }
    },
    {
      name: 'Log',
      call: async () => app.$logger.init({ version: app.$version })
    },
    {
      name: 'Jar',
      call: async () => {
        const siteId = process.env.VUE_APP_HOTJAR_SITE_ID
        const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION

        Hotjar.init(siteId, hotjarVersion)
      }
    },
    {
      name: 'Aut',
      call: async () => {
        const admin = await app.$auth.init()

        response = {
          ...response,
          firestore: admin.firestore
        }
      }
    },
    {
      name: 'Usr',
      call: async () =>
        app.$store.dispatch('auth/fetchUser').then(async () => {
          const authenticatedPromisesRes = await authenticatedPromises(
            app,
            lang
          )

          response = {
            ...response,
            ...authenticatedPromisesRes
          }
        })
    }
  ]

  const timedPromises = promises.map((promise) => timedPromise(promise))

  await Promise.all(timedPromises)

  return response
}

const loadApp = async () => {
  console.time('App Load')

  await SplashScreen.show({
    autoHide: false
  })

  const info = await Device.getInfo()
  const language = await Device.getLanguageCode()

  let lang = language.value.split('-')[0]

  if (!['es', 'en', 'pt'].includes(lang)) {
    lang = 'es'
  }

  Vue.config.productionTip = false
  Vue.prototype.$version = version
  Vue.prototype.$platform = info.platform
  Vue.prototype.$auth = Auth
  Vue.prototype.$languageCodes = languageCodes
  Vue.prototype.$kustomer = Kustomer
  Vue.prototype.$segment = Segment
  Vue.prototype.$redPandaEventEmitter = RedPandaEventEmitterInstance
  Vue.prototype.$logger = Logger
  Vue.prototype.$globalEvent = $globalEvent
  Vue.prototype.$toast = Toast
  Vue.prototype.$moment = moment
  Vue.prototype.$luxon = luxon
  Vue.prototype.$loader = { handleShowLoader, handleRemoveLoader }

  smoothscroll.polyfill()

  const app = new Vue({
    router,
    store,
    render: (h) => h(WebApp)
  })

  app.$store.commit('UPDATE_PLAFORM', app.$platform)

  const { updatedLang, firestore } = await promisesResolver(app, info, lang)

  app.$store.commit('UPDATE_LANGUAGE_CODE', {
    full: updatedLang,
    lang: updatedLang
  })

  Vue.prototype.$firestore = firestore
  Vue.prototype.$translations = translationsFile[updatedLang]
  Vue.prototype.$moment.locale(updatedLang)
  Vue.prototype.$moment.updateLocale(updatedLang, {
    meridiem: function (hour, minute, isLowerCase) {
      if (hour < 12) {
        return isLowerCase ? 'a.m.' : 'AM'
      } else {
        return isLowerCase ? 'p.m.' : 'PM'
      }
    }
  })

  /**
   * Init Handle errors
   */
  app.$redPandaEventEmitter.on(RED_PANDA_TOPICS.HANDLE_ERRORS, (data) => {
    const next = router.currentRoute.path
    if (router.currentRoute.name !== 'SignIn') {
      switch (data.error) {
        case 'TOKEN_EXPIRED':
        case 'UNAUTHORIZED':
          app.$auth.signOut()
          router
            .replace({
              name: 'SignIn',
              query: { ...router.currentRoute.query, next }
            })
            .catch((error) => console.error('unable to redirect', error))
          break
        default:
          break
      }
    }
  })

  /**
   * listen to android tap back
   */
  App.addListener('backButton', () => {
    const path = router.currentRoute.path
    const query = router.currentRoute.query
    const backCallback = app.$store.getters.deviceBackButtonCallback

    if (backCallback) {
      return app.$store.getters.deviceBackButtonCallback()
    }

    if (query.disableBack) {
      return
    }

    if (path === '/' || path === '/welcome' || path === '/sign-in') {
      return App.minimizeApp()
    }

    router.go(-1)
  })

  /**
   * listen to deep links
   */
  App.addListener('appUrlOpen', (event) => {
    // Example url: https://users.selia.co/
    // slug = /sessions/demo
    const slug = event.url.split('.co').pop()

    // We only push to the route if there is a slug present
    if (slug) {
      router.push({
        path: slug
      })
    }
  })

  /**
   * Start sentry
   */
  if (process.env.VUE_APP_MODE === 'PROD') {
    Sentry.init({
      Vue,
      dsn: "https://53fcb9931991e1fc7135f2ab3870d093@o4507702387081216.ingest.us.sentry.io/4507731180126208",
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.3, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      // Profiling
      profilesSampleRate: 1.0 // Profile 100% of the transactions. This value is relative to tracesSampleRate
    });
  }

  /**
   * App initialization completed
   */
  app.$mount('#app')
}

loadApp().then(async () => {
  await SplashScreen.hide()

  console.timeEnd('App Load')
})
