<template>
  <DialogContainer :back="actions.back" :title="config && config.title[lang]">
    <template v-slot:content>
      <div v-if="config && !loading">
        <div
          class="body margin-bottom"
          v-text="config && config.subtitle[lang]"
        />
        <div class="fields-container">
          <DynamicForm
            @form="setValue($event)"
            :fields="config.fields"
            :lang="lang"
          />

          <InputTextField
            v-if="showInput"
            v-model="form.field_value.additional_data"
            ref-name="dont_like_specialists_survey_text"
            :label="$translations['suggested-specialists'].dialog.label"
            :lang="lang"
            class="input-field"
          />
        </div>

        <Button
          @clicked="submit"
          :text="$translations['aftercall'].survey.continue"
          :disable="submitted"
        />
      </div>
      <div v-if="loading" class="skeleton-container">
        <Skeleton
          class="margin-y-sm"
          width="56px"
          height="56px"
          border-radius="100%"
        />
        <Skeleton
          class="margin-top margin-bottom-xs"
          width="100%"
          height="62px"
          border-radius="8px"
        />
        <Skeleton
          v-for="index in 5"
          :key="index"
          width="100%"
          height="60px"
          border-radius="8px"
        />
        <Skeleton
          class="margin-top"
          width="100%"
          height="48px"
          border-radius="8px"
        />
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import {
  Button,
  DialogContainer,
  Settings,
  DynamicForm,
  Skeleton,
  Survey,
  AfterCallWarranty
} from '@seliaco/red-panda'
import { InputTextField } from '@seliaco/sea-otter'
import { WarrantyEvent } from '@/types/events'

export default {
  name: 'AftercallSurveyDialog',
  components: {
    DialogContainer,
    Button,
    DynamicForm,
    Skeleton,
    InputTextField
  },
  props: {
    appointmentId: String
  },
  data () {
    return {
      loading: false,
      config: null,
      hasWarranty: false,
      submitted: false,
      form: {
        field_key: 'AFTER_CALL_DISLIKE_FORM',
        field_value: {
          value: null,
          additional_data: null
        }
      },
      actions: {
        back: {
          callback: () => {
            this.closeDialog()
          }
        }
      },
      showInput: false,
      lang: '',
      fields: [],
      isValid: false
    }
  },
  mounted () {
    this.lang = this.$store.getters.language.lang
    this.getConfig()
  },
  methods: {
    async getConfig () {
      this.loading = true

      this.hasWarranty = await AfterCallWarranty.hasPsychologyWarranty(
        this.appointmentId
      )

      Settings.get('AFTER_CALL_DISLIKE_FORM')
        .then((setting) => {
          this.config = setting.parse_value
          this.fields = this.config.fields[0].options.map((item) => {
            item.name = item.name[this.lang]
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeDialog () {
      this.$globalEvent.$emit('modal/aftercall-survey', {
        showDialog: { aftercallSurvey: { open: false, close: true } }
      })
    },
    submit () {
      if (this.submitted) {
        return
      }

      this.submitted = true

      Survey.createMatchingAnswer(this.form)
        .then(() => {
          WarrantyEvent.Warranty_Call_Survey_Submitted(this.form)
          this.closeDialog()
          this.handlerNavigation()
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
    },
    async handlerNavigation () {
      this.$router.push({
        name: 'AftercallNextStep',
        params: {
          id: this.appointmentId,
          warranty: this.hasWarranty.warranty
        }
      })
    },
    setValue (event) {
      this.isValid = event.isValid
      if (this.isValid) {
        this.showInput = event.values.dislike.values.value === 'OTHER'
        this.form.field_value = {
          ...this.form.field_value,
          value: event.values.dislike.values.value
        }
      }
    }
  },
  destroyed () {
    this.$globalEvent.$off('aftercall-survey')
  }
}
</script>

<style lang="sass" scoped>
.skeleton-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 8px
.fields-container
  text-align: initial
  margin-bottom: 24px
  max-height: 290px
  overflow: auto
  .input-field
    margin: 8px 0 24px
  ::v-deep .select-opened
    margin-bottom: 0
::v-deep .backdrop
  opacity: 0
@media (min-height: 668px)
  .fields-container
    max-height: none
</style>
