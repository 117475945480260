import { SpecialistFilter, RoleType } from '@seliaco/red-panda'
import router from '@/router'
import { SpecialistSearch } from '@/types/events'
import { prepareQuery, pagination } from '@/store/helpers/filter'

export const FiltersV2Store = {
  namespaced: true,
  state: {
    options: null,
    results: [],
    query: {
      seed: null,
      languages: [],
      gender: [],
      service_slug: [],
      specialities: [],
      approaches: [],
      budget: [],
      type: [],
      modality: [],
      country: [],
      time: []
    },
    pagination,
    loading: {
      options: true,
      results: true,
      query: true
    },
    loaders: {
      newPage: false
    }
  },
  getters: {
    options (state) {
      return state.options
    },
    results (state) {
      return state.results
    },
    query (state) {
      return state.query
    },
    pagination (state) {
      return state.pagination
    },
    hasFiltersApplied (state) {
      Object.keys(state.query).forEach((o) => {
        if (o) {
          return true
        }
      })

      return false
    },
    loading (state) {
      return state.loading.options || state.loading.results
    },
    loaders (state) {
      return state.loaders
    }
  },
  mutations: {
    SET_OPTIONS (state, options) {
      state.options = options
    },
    SET_RESULTS (state, results) {
      state.results = results
    },
    SET_QUERY (state, query) {
      state.query = {
        ...state.query,
        ...query
      }
    },
    SET_PAGINATION (state, pagination) {
      state.pagination = {
        ...state.pagination,
        ...pagination
      }
    },
    SET_LOADING (state, { key, value }) {
      state.loading[key] = value
    },
    SET_LOADERS (state, { key, value }) {
      state.loaders[key] = value
    }
  },
  actions: {
    async init ({ dispatch }, { cleanedRequest }) {
      dispatch('initQuery', { cleanedRequest })
      dispatch('getOptions')
    },
    getOptions ({ commit, rootState }) {
      const role = rootState.auth.role

      SpecialistFilter.options().then((response) => {
        if (role === RoleType.USER_HIDE_PRICES) {
          const filtersDisable = [
            'budget',
            // 'service_slug',
            // 'modality',
            // 'type'
          ]

          response = response.filter((f) => !filtersDisable.includes(f.code))
        }

        commit('SET_OPTIONS', response)
        commit('SET_LOADING', {
          key: 'options',
          value: false
        })
      })
    },
    cleanResults ({ commit }) {
      commit('SET_RESULTS', [])
      commit('SET_PAGINATION', pagination)
    },
    getResults ({ commit, state }, { cleanedRequest }) {
      const query = prepareQuery(state.query)

      if (cleanedRequest) {
        commit('SET_PAGINATION', pagination)
      }

      SpecialistFilter.filter(state.pagination, query).then((response) => {
        let results = response.data

        if (!cleanedRequest) {
          results = [...state.results, ...results]
        }

        commit('SET_RESULTS', results)

        commit('SET_PAGINATION', {
          page: Number(response.headers.page),
          perPage: Number(response.headers.perPage),
          pages: Number(response.headers.pages),
          count: Number(response.headers.count),
          unfiltered: response.headers['X-Pagination-Unfiltered-Count'],
          filtered: response.headers['X-Pagination-Filtered-Count']
        })
        commit('SET_LOADING', {
          key: 'results',
          value: false
        })
        commit('SET_LOADERS', {
          key: 'newPage',
          value: false
        })

        const selectedFilters = Object.values(state.query).filter(o => Array.isArray(o) && o.length > 0)

        SpecialistSearch.Specialist_Filter_Successful({
          numSpecialistsFound: Number(response.headers.count),
          filters: [].concat(...selectedFilters).map(o => o.value)
        })
      })
    },
    initQuery ({ dispatch }, { cleanedRequest }) {
      let values = {}

      try {
        const raw = router.currentRoute.query.filter
        const decoded = Buffer.from(raw, 'base64')
        values = JSON.parse(decoded.toString('ascii'))
      } catch {
        values = {}
      }

      dispatch('setQuery', { values, cleanedRequest })
    },
    setQuery (
      { commit, state, dispatch },
      { values, cleanedRequest }
    ) {
      if (cleanedRequest) {
        commit('SET_LOADING', {
          key: 'results',
          value: true
        })
      }

      commit('SET_QUERY', values)

      const encoded = Buffer.from(JSON.stringify(state.query))
      const newRaw = encoded.toString('base64')

      router
        .replace({
          query: {
            ...router.currentRoute.query,
            filter: newRaw
          }
        })
        .then(() => {
          commit('SET_LOADING', {
            key: 'query',
            value: false
          })

          dispatch('getResults', { cleanedRequest })
        })
    }
  }
}
