<template>
  <DialogContainer
    :title="$translations.timezone.title"
    :confirmation="confirmation"
    :show-button-close="false"
    @action="handlerAction()"
  >
    <template v-slot:content>
      <div class="timezone-actions">
        <Button
          :text="$translations.timezone.button.update"
          :loading="loadingUpdate"
          @clicked="update"
        />
        <Button
          :text="$translations.timezone.button.keep"
          :loading="loadingkeep"
          @clicked="keep"
          type="outline"
        />
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import {
  DialogContainer,
  getClientTimezone,
  Button,
  Localization
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'TimezoneDialog',
  components: {
    DialogContainer,
    Button
  },
  data () {
    return {
      loadingUpdate: false,
      loadingkeep: false
    }
  },
  methods: {
    update () {
      this.loadingUpdate = true
      const data = {
        country: this.user.country,
        language: this.user.language,
        timezone: getClientTimezone()
      }
      Localization.update(data)
        .then(() => {
          localStorage.setItem('skip-timezone', getClientTimezone())

          this.$toast({
            text: this.$translations.timezone.success,
            severity: 'success'
          })
        })
        .catch()
        .finally(() => {
          this.close()
        })
    },
    keep () {
      this.loadingkeep = true
      localStorage.setItem('skip-timezone', getClientTimezone())
      this.close()
    },
    close () {
      this.$globalEvent.$emit('modal/timezone', {
        showDialog: { timezone: { open: false, close: true } }
      })
    }
  },
  computed: {
    confirmation () {
      return this.$translations.timezone.description.replace(
        '{timezone}',
        getClientTimezone()
      )
    },
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    })
  }
}
</script>

<style lang="sass" scoped>
.timezone-actions
  display: flex
  flex-direction: column
  gap: 8px
  margin-top: 24px
</style>
