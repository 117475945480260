import {
  Promos,
  Toast
} from '@seliaco/red-panda'

export const MyPackagesStore = {
  namespaced: true,

  state: {
    packages: null,
    loading: true
  },
  getters: {
    getPackages (state) {
      return state.packages
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_PACKAGES (state, {
      packages
    }) {
      state.packages = packages
    },
    SET_LOADING (state, {
      loading
    }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({
      commit,
      state
    }, {
      endpoint
    }) {
      if (!state.packages) {
        commit('SET_LOADING', {
          loading: true
        })
      }

      const packages = await Promos.Packages[endpoint]()
        .catch((e) => {
          Toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })

      commit('SET_PACKAGES', {
        packages
      })
      commit('SET_LOADING', {
        loading: false
      })
    }
  }
}
