export const prepareQuery = (obj) => {
  const transformed = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (value && value.length) {
      switch (key) {
        case 'name':
          transformed[key] = value
          break
        case 'budget':
          transformed[key] = [
            Math.min.apply(null, value.map(item => item.value.min)),
            Math.max.apply(null, value.map(item => item.value.max))
          ]
          break
        default:
          transformed[key] = value.map(item => item.value)
          break
      }
    }
  })

  return transformed
}

export const pagination = {
  page: 1,
  perPage: 10,
  pages: null,
  count: null,
  total: null
}
