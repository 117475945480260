export const Event = {
  APP_LAUNCH: 'App_Launch',
  ACQUISITION_TYPE_CLICK: 'Access_Type_Click',
  ACQUISITION_LOGIN: 'Acquisition_Login',
  ACQUISITION_LOGIN_FAILED: 'Acquisition_Login_Failed',
  ACQUISITION_SIGNUP_STEP_ONE: 'Acquisition_Signup_Step_One',
  ACQUISITION_SIGNUP_STEP_TWO: 'Acquisition_Signup_Step_Two',
  ACQUISITION_SIGNUP_SUCCESSFUL: 'Acquisition_Signup_Successful',
  ACQUISITION_SIGNUP_FAILED: 'Acquisition_Signup_Failed',
  ACQUISITION_LOGOUT: 'Acquisition_Logout',
  ACQUISITION_FORGET_PASSWORD: 'Acquisition_Click_Forget_Password',
  ACQUISITION_SEND_PASSWORD_EMAIL: 'Acquisition_Send_Password_Reset_Email',
  ACQUISITION_PASSWORD_EMAIL_SUCCESSFUL: 'Acquisition_Send_Email_Successful',
  ACQUISITION_PASSWORD_EMAIL_FAILED: 'Acquisition_Send_Email_Failed',
  ACQUISITION_CLICK_VALIDATE_CODE: 'Acquisition_Click_validate_code',
  ACQUISITION_VALIDATE_CODE_SUCCESSFUL: 'Acquisition_Validate_Code_Successful',
  ACQUISITION_VALIDATE_CODE_FAILED: 'Acquisition_Validate_Code_Failed',
  ACQUISITION_UPDATED_PASSWORD_CLICK: 'Acquisition_Update_Password_Click',
  ACQUISITION_PASSWORD_SUCCESSFUL: 'Acquisition_Password_Successful',
  ACQUISITION_PASSWORD_FAILED: 'Acquisition_Update_Password_Failed',
  ACQUISITION_WELCOME_CLICK: 'Acquisition_Welcome_Clicked',
  ACQUISITION_SIGN_IN: 'Acquisition_SignIn_Viewed',
  ACQUISITION_SIGN_UP: 'Acquisition_SignUp_Viewed',
  ACQUISITION_WELCOME_VIEW: 'Acquisition_Welcome_Viewed',
  ACQUISITION_AUTH_CHANGE: 'Acquisition_Auth_Change',
  ADD_COUPON_CLICK: 'Add_Coupon_Click',
  APPLY_COUPON_CLICK: 'Apply_Coupon_Click',
  APPLY_COUPON_SUCCESSFUL: 'Apply_Coupon_Successful',
  APPLY_COUPON_FAILED: 'Apply_Coupon_Failed',
  CLOSE_COUPON_CLICK: 'Close_Coupon_Click',
  PAYMENT_METHODS_TYPE: 'Select_Type_Of_Payment_Method',
  PAY_ORDER: 'Purchase',
  SUCCESSFUL_PAYMENT: 'Payment_Successful',
  SUCCESSFUL_PAYMENT_FIRST: 'First Payment Successful',
  FAILED_PAYMENT: 'Payment_Failed',
  ADD_NEW_CARD: 'Add_New_Card',
  CLICK_OPTION_MENU: 'Click_Option_Menu',
  CLICK_SUPPORT: 'Click_Support',
  HOME_CLICK_SEE_ALL: 'Home_Click_See_All',
  SPECIALIST_OPTIONS_CLICK: 'Specialist_Options_Click',
  SPECIALIST_SEARCH_SUCCESSFUL: 'SpecialistDirectory_Search_Successful',
  SPECIALIST_FILTER_SUCCESSFUL: 'SpecialistsDirectory_Filter_Successful',
  SPECIALIST_BANNER_CLICK: 'SpecialistsDirectory_Banner_Click',
  SPECIALIST_FILTER_OPEN: 'SpecialistsDirectory_Filter_Open',
  SPECIALIST_FILTER_APPLY: 'SpecialistsDirectory_Filter_Apply',
  SPECIALIST_FILTER_DISCARD: 'SpecialistsDirectory_Filter_Discard',
  SPECIALIST_FILTER_CLEAN: 'SpecialistsDirectory_Filter_Clean',
  SPECIALIST_SEARCH_OPEN: 'SpecialistsDirectory_Search_Open',
  SPECIALIST_DIRECTORY_VIEWED: 'SpecialistDirectory_Viewed',
  EVENT_OPTIONS_CLICK: 'Events_Option_Click',
  VIDEO_FOR_YOU_CLICK: 'Helpful_Tips_Option_Click',
  PACKAGES_CLICK: 'Packages_Click',
  PACKAGES_TYPE_CLICK: 'Package_Type_Click',
  PACKAGE_CARD_CLICK: 'Package_Card_Click',
  SELECT_PACKAGE_PURCHASE: 'Select_Package_Purchase',
  CLICK_START_NOW: 'Click_Start_now',
  CLICK_VIDEO_ORIENTATION: 'Click_Video_Orientation',
  SCHEDULE_APPOINTMENT: 'Schedule_Appointment',
  SCHEDULE_APPOINTMENT_SUCCESSFUL: 'Schedule_Appointment_Successful',
  SCHEDULE_APPOINTMENT_FAILED: 'Schedule_Appointment_Failed',
  SCHEDULE_AVAILABILITY_FORM: 'Appointment_AvailabilityForm_Click',
  CLICK_SCHEDULE: 'Click_Schedule',
  CLICK_TYPE_SERVICE: 'Click_Type_Service',
  CLICK_TAB_SELECT: 'Click_Tab_Select',
  CLOSE_QUESTIONNAIRE: 'Close_Questionnaire',
  MATCHING_SUMMARY_SUBMIT: 'MatchingSummary_Submit',
  VIEW_SPECIALIST: 'View_specialist',
  ONBOARDING_INTRO_VIEW: 'Onboarding_Intro_View',
  ONBOARDING_INTRO2_VIEW: 'Onboarding_Intro2_View',
  ONBOARDING_INTRO3_VIEW: 'Onboarding_Intro3_View',
  ONBOARDING_SCREEN_BACK: 'Onboarding_Screen_Back',
  ONBOARDING_SCREEN_BACK_CONTINUE: 'Onboarding_FillUp_Click',
  ONBOARDING_NAME: 'Onboarding_Name_Click',
  ONBOARDING_GENDER: 'Onboarding_Gender_Click',
  ONBOARDING_BIRTHDAY: 'Onboarding_BirthDate_Click',
  ONBOARDING_PHONE: 'Onboarding_Phone_Click',
  ONBOARDING_THERAPY_REASON: 'Onboarding_Category_Select',
  ONBOARDING_DONE: 'Onboarding_FillUp_Successfull',
  ONBOARDING_VIEW: 'Onboarding_View',
  ONBOARDING_FAILED: 'Onboarding_Failed',
  ONBOARDING_EXPERIMENT: 'Onboarding_experiment',
  ONBOARDING_WELCOME_VIEW: 'Onboarding_Welcome_Viewed',
  ONBOARDING_COMPLETE_PROFILE: 'Onboarding_CompleteProfile_Clicked',
  ONBOARDING_COMPLETE_PROFILE_NAME: 'Onboarding_CompleteProfileName_Clicked',
  SURVEY_USER_SUCCESS_CONDITION: 'Survey_User_Success_Condition',
  HOME_ICON_CLICK: 'Home_Icon_Click',
  HOME_BANNER_CLICK: 'Home_banner_Click',
  HOME_CHAT_ORIENTATION_OPEN_SUCCESSFUL:
    'Home_Chat_Orientation_Open_Successful',
  CLICK_OPEN_CALL: 'Click_Open_Call',
  CLICK_LEAVE_CALL: 'Click Leave Call',
  SPECIALIST_ADD_FAVORITE: 'Specialist_AddToFavorite',
  SPECIALIST_REMOVE_FAVORITE: 'Specialist_RemoveFavorite_Confirm',
  HOME_MEDITATIONS_DISMISS: 'Home_Meditations_Dismiss',
  HOME_MEDITATIONS_START_CLICK: 'Home_Meditations_Start_Click',
  SELECT_VIEW_SPECIALIST: 'Select_View_Specialist',
  CARD_CLICK_SCHEDULE: 'Card_Click_Schedule',
  CARD_SCHEDULE_APPOINTMENT: 'Card_Schedule_Appointment',
  CARD_SCHEDULE_APPOINTMENT_SUCCESSFUL: 'Card_Schedule_Appointment_Successful',
  APPOINTMENT_SELECT_SERVICE_TYPE: 'Appointment_Select_Service_Type',
  APPOINTMENT_SELECT_DATE: 'Appointment_Select_Date',
  APPOINTMENT_SELECT_TIME: 'Appointment_Select_Time',
  APPOINTMENT_VIEW_DATE_TIME: 'Appointment_View_Date_Time',
  APPOINTMENT_SELECT_SUMMARY_SUCCESSFUL:
    'Appointment_Select_Summary_Successful',
  APPOINTMENT_VIEW_SUMMARY: 'Appointment_View_Summary',
  APPOINTMENT_CLICK_REASON: 'Appointment_Click_Reason',
  APPOINTMENT_VIEW_REASON: 'Appointment_View_Reason',
  APPOINTMENT_SAVE_REASON: 'Appointment_Save_Reason',
  APPOINTMENT_EDIT: 'Appointment_Edit',
  APPOINTMENT_CLICK_CONFIRM: 'Appointment_Click_Confirm',
  APPOINTMENT_VIEW_CHECKOUT: 'Appointment_View_Checkout',
  APPOINTMENT_SELECT_LEAVE_FLOW: 'Appointment_Select_Leave_Flow',
  MATCHING_VIEW_WELCOME: 'Matching_View_Welcome',
  MATCHING_WELCOME_START: 'Matching_Welcome_Start',
  MATCHING_BACK_BTN_PAGE: 'Matching_Back_Btn_Page',
  MATCHING_SUBMIT_PAGE: 'Mathing_Submit_Page',
  MATCHING_EXIT_BTN_PAGE: 'Matching_Exit_Btn_Page',
  MATCHING_EXIT_CONFIRM: 'Matching_Exit_Confirm',
  MATCHING_EXIT_DISCARD: 'Matching_Exit_Discard',
  MATCHING_FILLUP_SUCCESSFUL: 'Matching_FillUp_Successful',
  MATCHING_SUMMARY_EDIT: 'Matching_Summary_Edit',
  MATCHING_RESULTS_SEE_OTHERS: 'Matching_Results_SeeOthers',
  MATCHING_RESULTS_EDIT: 'Matching_Results_Edit',
  MATCHING_SEE_OTHERS_CLICK: 'Matching_SeeOthers_Click',
  MATCHING_SURVEY_FILLUP_SUCCESSFUL:
    'Matching_SeeOthersSurvey_FillUp_Successful',
  ORIENTATION_START_BOOKING: 'Orientation_StartBooking',
  PACKAGE_TAB_CLICK: 'Package_Tab_Click',
  SLIDERBANNER_CLICK: 'SliderBanner_Click',
  CHECKIN_CLICK_START: 'Checkin_Click_Start',
  CHECKIN_CLICK_OPEN: 'Checkin_Click_Open',
  CHECKIN_EXIT_FORM: 'Checkin_Exit_Form',
  CHECKIN_FILLUP_SUCCESSFUL: 'Checkin_FillUp_Successful',
  CHECKIN_CLICK_TAB: 'Checkin_Click_Tab',
  DIARY_CLICK_OPEN: 'Diary_Click_Open',
  DIARY_RECOMMENDATION_CLICKED: 'Diary Recommendation Clicked',
  DIARY_RECOMMENDATION_CLOSED: 'Diary Recommendations Closed',
  DIARY_CLICK_CREATE: 'Diary_Click_Create',
  EXIT_CONFIRM: 'Exit_Confirm',
  EXIT_SAVE: 'Exit_Save',
  EXIT_DISCARD: 'Exit_Discard',
  DIARY_CLICK_SUBMIT: 'Diary_Submit',
  DIARY_FILLUP_SUCCESSFUL: 'Diary_FillUp_Successful',
  DIARY_CLICK_EDIT: 'Diary_Click_Edit',
  APPTDETAILS_CLICK: 'ApptDetails_Click',
  APPTCARD_CLICK: 'ApptCard_Click',
  DRAWER_CLICK_OPTION: 'Drawer_Click_Option',
  MATCHING_CHOOSE_VIEW: 'Matching_Choose_View',
  MATCHING_CHOOSE_VIEW_VIEWED: 'Matching Choose View Viewed',
  MATCHING_CHOOSE_CLICK: 'Matching_Choose_Click',
  CHAT_HEADER_CLICK: 'ChatHeader_Click',
  FOR_YOU_CLICK_CONTENT: 'ForYou_ContentCard_Open',
  FOR_YOU_CLICK_CONTENT_TYPE: 'ForYou_ContentType_Click',
  FOR_YOU_SEARCH_OPEN: 'ForYou_Search_Open',
  FOR_YOU_TOPIC_OPEN: 'ForYou_Topic_Open',
  FOR_YOU_SEARCH_SUCCESSFUL: 'ForYou_Search_Successful',
  CHAT_CARD_CLICK: 'ChatCard_Click',
  DATAZERO_CLICK: 'DataZero_Click',
  MATCHING_REASONS_CONTINUE: 'Matching_Reasons_Continue',
  MATCHING_GENDER_CONTINUE: 'Matching_Gender_Continue',
  MATCHING_APPROACH_CONTINUE: 'Matching_Approach_Continue',
  MATCHING_BUDGET_CONTINUE: 'Matching_Budget_Continue',
  MATCHING_AVAILABILITY_CONTINUE: 'Matching_Availability_Continue',
  HOME_SERVICES_CLICK: 'Home_Services_Click',
  WARRANTY_CALL_ACTIVATION_CLICKED: 'Warranty_Call_Activation_Clicked',
  WARRANTY_CALL_ACTIVE_CLICKED: 'Warranty_Call_Active_Clicked',
  WARRANTY_CALL_UNAVAILABLE_CLICKED: 'Warranty_Call_Unavailable_Clicked',
  WARRANTY_CALL_SPECIALIST_CONTINUE_CLICKED: 'Warranty_Specialist_Continue',
  WARRANTY_CALL_SPECIALIST_CONTINUE_VIEWED:
    'Warranty_Specialist_Continue_Viewed',
  WARRANTY_CALL_SURVEY_SUBMITTED: 'Warranty_Call_Survey',
  WARRANTY_CALL_ACTIVATION_VIEWED: 'Warranty_Call_Activation_Viewed',
  WARRANTY_CALL_ACTIVE_VIEWED: 'Warranty_Call_Active_Viewed',
  WARRANTY_CALL_UNAVAILABLE_VIEWED: 'Warranty_Call_Unavailable_Viewed',
  SPECIALIST_PROFILE_TAB: 'SpecialistProfile_Tab_Clicked',
  PROGRAMS_PAGE_VIEWED: 'Programs_Page_Viewed',
  PROGRAMS_BUTTON_CLICKED: 'Programs_Button_Clicked',
  CLICK_SCHEDULE_BUTTON: 'Click Schedule Button',
  CLICK_SUBMIT_REVIEW: 'Click_Submit_Review',
  CLOSE_CHECKOUT_CLICK: 'Exit_Checkout',
  MODAL_MATCHING_CLICK: 'Exit_Matching_Modal_Button_Clicked',
  MODAL_MATCHING_RESULT: 'Exit_Matching_Modal_Result',
  CHECKOUT_VIEW: 'View Checkout',
  RECOMMENDED_SPECIALIST_VIEWED: 'Recommended Specialists Viewed',
  IN_APP_MESSAGE_VIEWED: 'In App Message Viewed',
  IN_APP_MESSAGE_DISMISSED: 'In App Message Dismissed',
  IN_APP_MESSAGE_CLICKED: 'In App Message Clicked',
  PAYMENT_CONFIRMATION_VIEWED: 'Payment Confirmation Viewed',
  APPOINTMENT_AFTER_CALL_VIEWED: 'Appointment After Call Viewed',
  MATCHING_RESULTS_SPECIALIST_PROFILE_VIEWED: 'Matching Results Specialist Profile Viewed',
  MATCHING_RESULTS_ARROW_CLICKED: 'Matching Results Arrow Clicked',
  MATCHING_RESULTS_VIEWED: 'Matching Results Specialist Profile Viewed',
  SPECIALIST_PROFILE_VIEW: 'Specialist Profile Viewed',
}
