import { UsrConferences, Toast } from '@seliaco/red-panda'
import { DateTime } from 'luxon'

export const ConferencesStore = {
  namespaced: true,

  state: {
    appointmentConferences: [],
    homeConferences: [],
    loading: false
  },
  getters: {
    getAppointmentConferences (state) {
      return state.appointmentConferences
    },
    getHomeConferences (state) {
      return state.homeConferences
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_APPOINTMENT_CONFERENCES (state, { appointmentConferences }) {
      state.appointmentConferences = appointmentConferences
    },
    SET_HOME_CONFERENCES (state, { homeConferences }) {
      state.homeConferences = homeConferences
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async getAppointmentConferences ({ commit, state }, { user }) {
      if (!(state.appointmentConferences?.upcoming || state.appointmentConferences?.past)) {
        commit('SET_LOADING', { loading: true })
      }

      const config = {
        perPage: 100,
        page: 1
      }

      await UsrConferences.list(config)
        .then((response) => {
          const currentTime = DateTime.local().setLocale(user.language)
          const conferences = {
            upcoming: {},
            past: {}
          }

          if (response.data.length === 0) {
            commit('SET_LOADING', { loading: false })
            commit('SET_APPOINTMENT_CONFERENCES', { appointmentConferences: conferences })
            return
          }

          response.data.forEach(conference => {
            conference = {
              ...conference,
              img: conference.cover
            }

            const conferenceDate = DateTime.fromISO(conference.start_date.split('+')[0]).setLocale(user.language)
            const conferencesStartsAt = conferenceDate.plus({ minutes: conference.duration })

            const time = conferenceDate.toISODate()

            if (conferencesStartsAt <= currentTime) {
              if (!conferences.past[time]) {
                conferences.past[time] = []
              }

              conferences.past[time].push(conference)
            } else {
              if (!conferences.upcoming[time]) {
                conferences.upcoming[time] = []
              }

              conferences.upcoming[time].push(conference)
            }
          })

          commit('SET_APPOINTMENT_CONFERENCES', { appointmentConferences: conferences })
        })
        .catch((e) => {
          Toast({
            severity: 'error',
            text: e.message || this.$translations.error.conferences
          })
        })
      commit('SET_LOADING', { loading: false })
    },
    async getHomeConferences ({ commit, state }, { user }) {
      if (!state.homeConferences) {
        commit('SET_LOADING', { loading: true })
      }

      const config = {
        statusExclude: ['FINISHED', 'CANCELED'],
        perPage: 100,
        page: 1
      }

      let conferences = await UsrConferences.list(config, true)
        .then((res) => res.data.map(o => {
          return {
            ...o,
            img: o.cover
          }
        }))

      if (conferences && conferences.length) {
        conferences = conferences.filter((i) => {
          const secondDate = DateTime.fromISO(i.start_date.split('+')[0])
            .plus({ minutes: i.duration })
            .setZone(user.time_zone)
            .setLocale(user.language)

          return DateTime.now() <= secondDate
        })

        commit('SET_HOME_CONFERENCES', { homeConferences: conferences })
      }

      commit('SET_LOADING', { loading: false })
    }
  }
}
