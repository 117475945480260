import { Segment } from '@seliaco/red-panda'
import { Event } from '@/constants/events.constants'
import { TransformUser, MapperOnboarding } from '@/filters/user-events-filter'

export const AppointmentFlowEvent = {
  /**
   * Event sent by clicking on an appointment card
   */
  ApptCard_Click: (body) => {
    Segment.track(Event.APPTCARD_CLICK, {
      specialist_id: body.specialist_id,
      specialist_name: body.specialist_name,
      service: body.service,
      appointment_details: body.appointment_details,
      appointment_status: body.appointment_status,
      option: body.option,
      origin: body.origin
    })
  },

  /**
   * Event sent when the user select a service in the appointment flow
   */
  Appointment_Select_Service_Type: (body) => {
    Segment.track(Event.APPOINTMENT_SELECT_SERVICE_TYPE, {
      ...MapperOnboarding(body),
      serviceType: body.serviceType,
      service: body.service
    })
  },

  /**
   * Event sent when the user sees services screen
   */
  Appointment_View_Service_Type: (body) => {
    Segment.track(Event.APPOINTMENT_VIEW_SERVICE_TYPE, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when the user selects a date
   */
  Appointment_Select_Date: (body) => {
    Segment.track(Event.APPOINTMENT_SELECT_DATE, {
      ...MapperOnboarding(body),
      service_name: body.service_name,
      date: body.date
    })
  },

  /**
   * Event sent when the user selects the time
   */
  Appointment_Select_Time: (body) => {
    Segment.track(Event.APPOINTMENT_SELECT_TIME, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when the user sees date and time screen
   */
  Appointment_View_Date_Time: (body) => {
    Segment.track(Event.APPOINTMENT_VIEW_DATE_TIME, {
      ...MapperOnboarding(body),
      specialist_is_public: body.specialist_is_public,
      service_name: body.service_name
    })
  },

  /**
   * Event sent when the user confirm summary
   */
  Appointment_Select_Summary_Successful: (body) => {
    Segment.track(Event.APPOINTMENT_SELECT_SUMMARY_SUCCESSFUL, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when the user sees summary screen
   */
  Appointment_View_Summary: (body) => {
    Segment.track(Event.APPOINTMENT_VIEW_SUMMARY, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when user clicks on reason for consultation
   */
  Appointment_Click_Reason: (body) => {
    Segment.track(Event.APPOINTMENT_CLICK_REASON, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when the user sees consultation reason screen
   */
  Appointment_View_Reason: ({ body }) => {
    Segment.track(Event.APPOINTMENT_VIEW_REASON, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when the user saves the reason for consultation
   */
  Appointment_Save_Reason: ({ body }) => {
    Segment.track(Event.APPOINTMENT_SAVE_REASON, {
      ...MapperOnboarding(body),
      reason: body.reason
    })
  },

  /**
   * Event sent when the user edits appointment details
   */
  Appointment_Edit: (body, step) => {
    Segment.track(Event.APPOINTMENT_EDIT, {
      ...MapperOnboarding(body),
      edit: step
    })
  },

  /**
   * Event sent when the user sees the chekout screen
   */
  Appointment_View_Checkout: (body) => {
    Segment.track(Event.APPOINTMENT_VIEW_CHECKOUT, {
      ...MapperOnboarding(body)
    })
  },

  /**
   * Event sent when the user leaves the flow
   */
  Appointment_Select_Leave_Flow: ({ body }) => {
    Segment.track(Event.APPOINTMENT_SELECT_LEAVE_FLOW, {
      ...MapperOnboarding(body)
    })
  },
  /**
   * Event sent when the user click on te button tu complete schedule survey
   */
  Appointment_Form_Click: (body) => {
    Segment.track(Event.SCHEDULE_AVAILABILITY_FORM, { ...body })
  }
}

export const Acquisition = {
  /**
   * Event sent as soon as the app has been opened, before everything has been loaded
   */
  applaunch: (user) => {
    Segment.track(Event.APP_LAUNCH, user)
  },

  /**
   * Event sent when a user clicks the login, register or Google buttons
   */
  accessTypeClick: ({ option, origin }) => {
    Segment.track(Event.ACQUISITION_TYPE_CLICK, {
      option: option,
      origin: origin
    })
  },

  /**
   * Event sent when a user's account information is successfully authenticated
   */
  login: ({ user, provider }) => {
    Segment.track(Event.ACQUISITION_LOGIN, {
      source: provider === 'email' ? 'Login Selia' : provider,
      ...TransformUser(user)
    })
    Segment.identifyAppUser({ ...TransformUser(user) }, {})
  },

  /**
   * Event sent when account information is incorrect
   */
  loginFailed: ({ error, provider, email }) => {
    Segment.track(Event.ACQUISITION_LOGIN_FAILED, {
      error_type: error,
      email,
      source: provider === 'email' ? 'Login Selia' : provider
    })
  },

  /**
   * Event that is sent when a user logs out
   */

  logout: (user) => {
    Segment.track(Event.ACQUISITION_LOGOUT, {
      ...TransformUser(user)
    })
    Segment.reset()
  },

  /**
   * Event sent when a user clicks the Sign me up button step one
   */
  signupStepOne: ({ email }) => {
    Segment.track(Event.ACQUISITION_SIGNUP_STEP_ONE, {
      email
    })
  },

  /**
   * Event sent when a user clicks the Sign me up button step one
   */
  signupSteptwo: ({ user }) => {
    Segment.track(Event.ACQUISITION_SIGNUP_STEP_TWO, {
      ...TransformUser(user)
    })
  },

  /**
   * Event that is sent when registration is successful
   */
  signupSuccessful: (body) => {
    const payload = {
      ...body,
      ...TransformUser(body.user)
    }
    delete payload.user

    Segment.track(Event.ACQUISITION_SIGNUP_SUCCESSFUL, payload)

    const transformedUser = TransformUser(body.user)

    Segment.identifyAppUser(transformedUser, {})
  },

  /**
   * Event sent when registration failed
   */
  signupFailed: ({ user, error }) => {
    Segment.track(Event.ACQUISITION_SIGNUP_FAILED, {
      error_type: error,
      ...TransformUser(user)
    })
  },

  /**
   * Event that is sent when clicking on the recover password button
   */
  forgetClick: () => {
    Segment.track(Event.ACQUISITION_FORGET_PASSWORD)
  },

  /**
   * Event is sent to validate the password recovery email
   */
  sendEmailForget: ({ email }) => {
    Segment.track(Event.ACQUISITION_SEND_PASSWORD_EMAIL, {
      email
    })
  },

  /**
   * Event fires when mail is sent successfully
   */
  sendEmailSuccessful: ({ email }) => {
    Segment.track(Event.ACQUISITION_PASSWORD_EMAIL_SUCCESSFUL, {
      email
    })
  },

  /**
   * Event fires when mail does not exist
   */
  sendEmailFailed: ({ email }) => {
    Segment.track(Event.ACQUISITION_PASSWORD_EMAIL_FAILED, {
      email
    })
  },

  /**
   * Event is activated when clicking to validate code
   */
  clickValidateCode: ({ code }) => {
    Segment.track(Event.ACQUISITION_CLICK_VALIDATE_CODE, {
      code
    })
  },

  /**
   * Event fires when code is valid
   */
  validateCodeSuccess: ({ code }) => {
    Segment.track(Event.ACQUISITION_VALIDATE_CODE_SUCCESSFUL, {
      code
    })
  },

  /**
   * Event fires when code does not exist
   */
  validateCodeFailed: ({ code, error }) => {
    Segment.track(Event.ACQUISITION_VALIDATE_CODE_FAILED, {
      code,
      error_type: error
    })
  },

  /**
   * Event is triggered by clicking the update button
   */
  clickUpdatePass: ({ code }) => {
    Segment.track(Event.ACQUISITION_UPDATED_PASSWORD_CLICK, {
      code
    })
  },
  /**
   * Event is sent when the password was successfully updated
   */
  passwordSuccessful: ({ message }) => {
    Segment.track(Event.ACQUISITION_PASSWORD_SUCCESSFUL, {
      message
    })
  },
  /**
   * Event is sent when an error was generated when updating password
   */
  passwordFailed: ({ error }) => {
    Segment.track(Event.ACQUISITION_PASSWORD_FAILED, {
      error_type: error
    })
  },
  /**
   * Event is sent when user click button in welcome sign-in
   */
  welcomeClick: ({ option }) => {
    Segment.track(Event.ACQUISITION_WELCOME_CLICK, {
      option: option
    })
  },
  /**
   * Event is sent when user sign-in
   */
  signIn: ({ title, url }) => {
    Segment.track(Event.ACQUISITION_SIGN_IN, {
      title: title,
      url: url
    })
  },
  /**
   * Event is sent when user click button in to change auth mode
   */
  authChange: ({ option, origin }) => {
    Segment.track(Event.ACQUISITION_AUTH_CHANGE, {
      option: option,
      origin: origin
    })
  },
  /**
   * Event is sent when user sign-up
   */
  signUp: ({ title, url }) => {
    Segment.track(Event.ACQUISITION_SIGN_UP, {
      title: title,
      url: url
    })
  },
  /**
   * Event is sent when user visit welocme sign in
   */
  welcomeView: ({ title, url }) => {
    Segment.track(Event.ACQUISITION_WELCOME_VIEW, {
      title: title,
      url: url
    })
  }
}

export const Coupons = {
  /**
   * Event is activated by clicking on add coupon
   */
  addCouponClick: ({ opcion, user }) => {
    Segment.track(Event.ADD_COUPON_CLICK, {
      opcion,
      ...TransformUser(user)
    })
  },

  /**
   * Event is activated by clicking on add coupon
   */
  closeCouponClick: ({ opcion, user }) => {
    Segment.track(Event.CLOSE_COUPON_CLICK, {
      opcion,
      ...TransformUser(user)
    })
  },
  /**
   * Event is activated by clicking the apply button
   */
  applyCouponClick: ({ coupon, user }) => {
    Segment.track(Event.APPLY_COUPON_CLICK, {
      couponName: coupon,
      ...TransformUser(user)
    })
  },

  /**
   * Event is triggered when the coupon is successfully applied
   */
  applyCouponSuccessful: ({ coupon, user, data }) => {
    Segment.track(Event.APPLY_COUPON_SUCCESSFUL, {
      couponName: coupon,
      description: data.description,
      totalInvoice: data.invoice.total,
      promocodeId: data.promocode_id,
      ...TransformUser(user)
    })
  },

  /**
   * Event fires when coupon fails
   **/
  applyCouponFailed: ({ coupon, user, error }) => {
    Segment.track(Event.APPLY_COUPON_FAILED, {
      couponName: coupon,
      error_type: error,
      ...TransformUser(user)
    })
  }
}

export const CheckInEvent = {
  /**
   * Event is activated by clicking on the 'begin' button or the current month chip
   */
  Checkin_Click_Start: (body) => {
    Segment.track(Event.CHECKIN_CLICK_START, {
      ...MapperOnboarding(body),
      month: body.month,
      month_number: body.month_number,
      type: body.type,
      score: body.score,
      cta: body.cta
    })
  },
  /**
   * Event is activated by clicking on one of the old months chips
   */
  Checkin_Click_Open: (body) => {
    Segment.track(Event.CHECKIN_CLICK_OPEN, {
      ...MapperOnboarding(body),
      month: body.month,
      month_number: body.month_number,
      type: body.type,
      score: body.score
    })
  },
  /**
   * Event is activated by clicking on the exit button in form view
   */
  Checkin_Exit_Form: (body) => {
    Segment.track(Event.CHECKIN_EXIT_FORM, body)
  },
  /**
   * Event is activated when user send form
   */
  Checkin_FillUp_Successful: (body) => {
    Segment.track(Event.CHECKIN_FILLUP_SUCCESSFUL, {
      ...MapperOnboarding(body),
      month: body.month,
      month_number: body.month_number,
      type: body.type,
      score: body.score
    })
  },
  /**
   * Event is activated by change the tab value
   */
  Checkin_Click_Tab: (body) => {
    Segment.track(Event.CHECKIN_CLICK_TAB, {
      tab: body.tab
    })
  }
}

export const SpecialistSearch = {
  /**
   * Event is activated when search a specialist by name response successful
   */
  Specialist_Search_Successful: (body) => {
    Segment.track(Event.SPECIALIST_SEARCH_SUCCESSFUL, {
      searchTerm: body.searchTerm,
      numSpecialistsFound: body.numSpecialistsFound
    })
  },
  /**
   * Event is activated when filter response successful
   */
  Specialist_Filter_Successful: (body) => {
    Segment.track(Event.SPECIALIST_FILTER_SUCCESSFUL, {
      filters: body.filters,
      numSpecialistsFound: body.numSpecialistsFound
    })
  },
  /**
   * Event is activated when click banner button in specialist list
   */
  Specialists_Banner_Click: (body) => {
    Segment.track(Event.SPECIALIST_BANNER_CLICK, {
      hadFiltersSelected: body.hadFiltersSelected,
      filters: body.filters,
      numSpecialistsListed: body.numSpecialistsListed
    })
  },
  /**
   * Event is activated when click filter by button
   */
  Specialists_Filter_Open: (body) => {
    Segment.track(Event.SPECIALIST_FILTER_OPEN, {
      option: body.option,
      hadFiltersSelected: body.hadFiltersSelected,
      filters: body.filters
    })
  },
  /**
   * Event is activated when click button to apply filter
   */
  Specialists_Filter_Apply: (body) => {
    Segment.track(Event.SPECIALIST_FILTER_APPLY, {
      options: body.options,
      hadFiltersSelected: body.hadFiltersSelected,
      filters: body.filters,
      modal: body.modal
    })
  },
  /**
   * Event is activated when click 'x' button to close modal
   */
  Specialists_Filter_Discard: (body) => {
    Segment.track(Event.SPECIALIST_FILTER_DISCARD, {
      options: body.options,
      hadFiltersSelected: body.hadFiltersSelected,
      filters: body.filters,
      modal: body.modal
    })
  },
  /**
   * Event is activated when click button to clean filters
   */
  Specialists_Filter_Clean: (body) => {
    Segment.track(Event.SPECIALIST_FILTER_CLEAN, {
      options: body.options,
      hadFiltersSelected: body.hadFiltersSelected,
      filters: body.filters,
      modal: body.modal
    })
  },
  /**
   * Event is activated when click button to open search specialist view
   */
  Specialists_Search_Open: () => {
    Segment.track(Event.SPECIALIST_SEARCH_OPEN)
  },
  /**
   * Event is activated when navigate to specialists directory view
   */
  Specialist_Directory_Viewed: (body) => {
    Segment.track(Event.SPECIALIST_DIRECTORY_VIEWED, {
      origin: body.origin
    })
  }
}

export const MenuOptions = {
  /**
   * Event is sent when clicking on an option in the sidebar
   **/
  clickMenu: ({ option, user }) => {
    Segment.track(Event.CLICK_OPTION_MENU, {
      option,
      ...TransformUser(user)
    })
  }
}

export const DiaryEvents = {
  /**
   * Event is activated by clicking on add coupon
   */
  Diary_Click_Open: (body) => {
    Segment.track(Event.DIARY_CLICK_OPEN, {
      ...MapperOnboarding(body),
      activities: body.activities,
      emotions: body.emotions,
      mood: body.mood,
      date: body.date
    })
  },
  /**
   * Event is activated by clicking on add entry
   */
  Diary_Click_Create: (body) => {
    Segment.track(Event.DIARY_CLICK_CREATE, {
      ...MapperOnboarding(body),
      origin: body.origin,
      component: body.component,
      mood: body.mood
    })
  },
  /**
   * Event is activated by clicking on exit button in modal
   */
  Exit_Confirm: () => {
    Segment.track(Event.EXIT_CONFIRM)
  },
  /**
   * Event is activated by clicking on exit and save button in modal
   */
  Exit_Save: () => {
    Segment.track(Event.EXIT_SAVE)
  },
  /**
   * Event is activated by clicking on back button in modal
   */
  Exit_Discard: () => {
    Segment.track(Event.EXIT_DISCARD)
  },
  /**
   * Event is activated by clicking on submit entry
   */
  Diary_Submit: (body) => {
    Segment.track(Event.DIARY_CLICK_SUBMIT, {
      ...MapperOnboarding(body),
      origin: body.origin,
      date: body.date,
      activities: body.activities,
      emotions: body.emotions,
      mood: body.mood
    })
  },
  /**
   * Event is activated on successful request
   */
  Diary_FillUp_Successful: (body) => {
    Segment.track(Event.DIARY_FILLUP_SUCCESSFUL, {
      ...MapperOnboarding(body),
      date: body.date,
      origin: body.origin,
      activities: body.activities,
      emotions: body.emotions,
      mood: body.mood
    })
  },
  /**
   * Event is activated by clicking on edit button
   */
  Diary_Click_Edit: () => {
    Segment.track(Event.DIARY_CLICK_EDIT)
  },
  /**
   * Event is activated by clicking on recommendation content
   */
  diaryRecommendationClicked: (body) => {
    Segment.track(Event.DIARY_RECOMMENDATION_CLICKED, { ...body })
  },
  /**
   * Event is activated by clicking on close done view
   */
  diaryRecommendationClosed: (body) => {
    Segment.track(Event.DIARY_RECOMMENDATION_CLOSED, { ...body })
  }
}

export const ScheduleButton = {
  /**
   * User clicks on Schedule buttom on Specialist card
   */
  onClick: (body) => {
    Segment.track(Event.CLICK_SCHEDULE_BUTTON, {
      ...MapperOnboarding(body),
      user: TransformUser(body.user),
      origin: body.origin,
      component: body.component,
      specialist: {
        id: body.specialist_id,
        name: body.specialist_name,
        is_public: body.is_public,
        highlighted: body.highlighted
      }
    })
  }
}

export const SpecialistCard = {
  /**
   * User clicks on Schedule buttom on Specialist card
   */
  Card_Click_Schedule: (body) => {
    Segment.track(Event.CARD_CLICK_SCHEDULE, {
      ...MapperOnboarding(body),
      origin: body.origin,
      specialist_id: body.specialist_id
    })
  },
  /**
   * User clicks on Schedule Appointment after opening profile from Specialist card
   */
  Card_Schedule_Appointment: (body) => {
    Segment.track(Event.CARD_SCHEDULE_APPOINTMENT, {
      origin: 'specialist card',
      specialist_id: body.specialist_id
    })
  },
  /**
   * User Schedule an Appointment successfuly after opening profile from Specialist card
   */
  Card_Schedule_Appointment_Successful: (body) => {
    Segment.track(Event.CARD_SCHEDULE_APPOINTMENT_SUCCESSFUL, {
      origin: 'specialist card',
      specialist_id: body.specialist_id
    })
  }
}

export const BookingAndAppointmentDetailEvent = {
  /**
   * Clicking on some the appointment detail view options.
   */
  ApptDetails_Click: (body) => {
    Segment.track(Event.APPTDETAILS_CLICK, {
      specialist_id: body.specialist_id,
      specialist_name: body.specialist_name,
      service: body.service,
      appointment_details: body.appointment_details,
      appointment_status: body.appointment_status,
      option: body.option
    })
  },
  /**
   * User selects search bar
   */
  select_searchbar: 'select_searchbar',

  /**
   * User selects 'begin now'
   */
  select_beginnow: 'select_beginnow',

  /**
   * User views individual specialist profile
   */
  Select_View_Specialist: (body) => {
    Segment.track(Event.SELECT_VIEW_SPECIALIST, { ...body })
  },
  /**
   * User clicks on "choose a specialist" on specialist profile
   */
  loadViewspecialist: ({ user, origin }) => {
    Segment.track(Event.VIEW_SPECIALIST, {
      ...TransformUser(user),
      origin
    })
  },
  /**
   * User selects service type
   */
  clickSchedule: ({ service, user }) => {
    Segment.track(Event.SCHEDULE_APPOINTMENT, {
      ...service,
      ...TransformUser(user)
    })
  },
  /**
   * User books an appointment
   */
  ScheduleAppointmentSuccessful: ({ bookappointment, user }) => {
    Segment.track(Event.SCHEDULE_APPOINTMENT_SUCCESSFUL, {
      ...bookappointment,
      ...TransformUser(user)
    })
  },
  /**
   * The user failed to schedule the appointment
   */
  ScheduleAppointmentFailed: ({ error, user }) => {
    Segment.track(Event.SCHEDULE_APPOINTMENT_FAILED, {
      error_type: error,
      ...TransformUser(user)
    })
  },

  /**
   * User reschedules an appointment
   */
  select_reschduleappointment: 'select_reschduleappointment',

  /**
   * User reschedules cancels an appointment
   */
  select_cancelappointment: 'select_cancelappointment',

  /**
   * User selects make a payment and navigates to payment flow
   */
  select_makeapayment: 'select_makeapayment'
}

export const PaymentEvent = {
  /**
   * User selects payment method
   **/
  selectPaymentMethod: ({ type, user }) => {
    Segment.track(Event.PAYMENT_METHODS_TYPE, {
      type,
      ...TransformUser(user)
    })
  },
  /**
   * User clicks on Pay Now button
   */
  clickPayOrder: ({ paymentData, user }) => {
    Segment.track(Event.PAY_ORDER, {
      ...paymentData,
      ...TransformUser(user)
    })
  },
  /**
   * User successfully pays for an appointment
   */
  successfulPayment: ({ paymentData, user }) => {
    Segment.track(Event.SUCCESSFUL_PAYMENT, {
      ...paymentData,
      ...TransformUser(user)
    })
  },
  /**
   * User successfully pays for an appointment
   */
  successfulFirstPayment: ({ paymentData, user }) => {
    Segment.track(Event.SUCCESSFUL_PAYMENT_FIRST, {
      ...paymentData,
      ...TransformUser(user)
    })
  },
  /**
   * User failedPayment pays for an appointment
   */
  failedPayment: ({ paymentData, user }) => {
    Segment.track(Event.FAILED_PAYMENT, {
      ...paymentData,
      ...TransformUser(user)
    })
  },
  /**
   * User successfully adds payment method
   */
  addNewCard: ({ paymentMethod, user }) => {
    Segment.track(Event.ADD_NEW_CARD, {
      paymentMethod,
      ...TransformUser(user)
    })
  },
  /**
   * User clicked on close button
   */
  closeCheckout: ({ checkout, user }) => {
    Segment.track(Event.CLOSE_CHECKOUT_CLICK, {
      ...checkout,
      ...TransformUser(user)
    })
  },
  /**
   * Event sent when the user sees the chekout screen
   */
  viewCheckout: ({ checkout, user }) => {
    Segment.track(Event.CHECKOUT_VIEW, {
      ...checkout,
      ...TransformUser(user)
    })
  },
  /**
   * Event sent when the user sees the payment succesfull screen
   */
  paymentConfirmationViewed: ({ user }) => {
    Segment.track(Event.PAYMENT_CONFIRMATION_VIEWED, {
      ...TransformUser(user)
    })
  }
}

export const SessionEvent = {
  /**
   * click on the button enter the call
   */
  openCall: ({ service, user }) => {
    Segment.track(Event.CLICK_OPEN_CALL, {
      service,
      ...TransformUser(user)
    })
  },
  /**
   * click on the button enter the call
   */
  leaveCall: ({ service, user }) => {
    Segment.track(Event.CLICK_LEAVE_CALL, {
      service,
      ...TransformUser(user)
    })
  }
}

export const DrawerEvent = {
  /**
   * click on one of the drawer options
   */
  Drawer_Click_Option: (option, origin) => {
    Segment.track(Event.DRAWER_CLICK_OPTION, { option, origin })
  }
}

export const ReviewEvent = {
  /**
   * User clicks on "x" to close review the call
   */
  select_close_review: 'select_close_review',

  /**
   * User clicks on "x" to close booking next session after a review
   */
  select_close_aftercall: 'select_close_aftercall',

  /**
   * User clicks on "Book next session" after a review
   */
  select_booknextsession_aftercall: 'select_booknextsession_aftercall',

  /**
   * User clicks on "Send review" after the video call
   */
  clickSubmit: (body) => {
    Segment.track(Event.CLICK_SUBMIT_REVIEW, {
      body
    })
  }
}

export const PackageEvent = {
  /**
   * User clicks on View packages
   */
  packagesClick: ({ source, user, type }) => {
    Segment.track(Event.PACKAGES_CLICK, {
      source,
      type,
      ...TransformUser(user)
    })
  },
  /**
   * Users clicked in purchase package
   */
  packagesTypeClick: ({ detailsPackage, user }) => {
    Segment.track(Event.PACKAGES_TYPE_CLICK, {
      ...detailsPackage,
      ...TransformUser(user)
    })
  },
  /**
   * Users clicked in package card
   */
  packageCardClick: ({ detailsPackage, user, source }) => {
    Segment.track(Event.PACKAGE_CARD_CLICK, {
      ...detailsPackage,
      ...TransformUser(user),
      source
    })
  },
  /**
   * Users clicked in purchase package
   */
  selectPackage: ({ detailsPackage, user }) => {
    Segment.track(Event.SELECT_PACKAGE_PURCHASE, {
      ...detailsPackage,
      ...TransformUser(user)
    })
  },
  /**
   * Users clicked in a tab in available packages view
   */
  Package_Tab_Click: (body) => {
    Segment.track(Event.PACKAGE_TAB_CLICK, {
      ...MapperOnboarding(body),
      tab: body.tab,
      origin: body.origin
    })
  },
  /**
   * Users clicked in a banner in the sliderbanner
   */
  SliderBanner_Click: (body) => {
    Segment.track(Event.SLIDERBANNER_CLICK, {
      ...MapperOnboarding(body),
      banner: body.banner
    })
  }
}

export const HomeEvent = {
  /**
   * Event is sent by clicking one of the shortcut icons
   **/
  Home_Icon_Click: (body) => {
    Segment.track(Event.HOME_ICON_CLICK, {
      ...MapperOnboarding(body),
      icon: body.icon,
      user_type: 'user'
    })
  },
  /**
   * Event is sent by clicking the questionnaire banner
   **/
  Home_banner_Click: (body) => {
    Segment.track(Event.HOME_BANNER_CLICK, {
      ...MapperOnboarding(body),
      user_type: 'user'
    })
  },
  /**
   * Event is sent by clicking one of the shortcut icons
   **/
  Home_Chat_Orientation_Open_Successful: (body) => {
    Segment.track(Event.HOME_CHAT_ORIENTATION_OPEN_SUCCESSFUL, {
      ...MapperOnboarding(body),
      user_type: 'user'
    })
  },
  /**
   * Event is sent by closing one of the meditations // DEPRECATED
   **/
  Home_Meditations_Start_Click: (body) => {
    Segment.track(Event.HOME_MEDITATIONS_START_CLICK, {
      ...MapperOnboarding(body),
      origin: body.page,
      meditation: body.meditation,
      user_type: 'user'
    })
  },
  /**
   * Event is sent by closing one of the meditations
   **/
  Home_Meditations_Dismiss: (body) => {
    Segment.track(Event.HOME_MEDITATIONS_DISMISS, {
      ...MapperOnboarding(body),
      minuteAtClose: body.minuteAtClose,
      user_type: 'user'
    })
  },
  /**
   * Event is sent by clicking the button see all the home
   **/
  clickSeeAll: ({ option, user }) => {
    Segment.track(Event.HOME_CLICK_SEE_ALL, {
      option,
      ...TransformUser(user)
    })
  },
  /**
   * Event is sent by clicking on the home card
   **/
  specialistOptionClick: ({ option, optionId, user }) => {
    Segment.track(Event.SPECIALIST_OPTIONS_CLICK, {
      optionId,
      option,
      ...TransformUser(user)
    })
  },
  /**
   * Event is sent when they click on the card selia event news
   **/
  eventsOptionClick: ({ option, optionId, user }) => {
    Segment.track(Event.EVENT_OPTIONS_CLICK, {
      option,
      optionId,
      ...TransformUser(user)
    })
  },
  /**
   * User clicks on a video from Home page // DEPRECATED
   */
  clickVideoForYou: ({ video, user }) => {
    Segment.track(Event.VIDEO_FOR_YOU_CLICK, {
      ...video,
      ...TransformUser(user)
    })
  },
  /**
   * Event is sent by clicking the questionnaire banner
   **/
  Home_Services_Click: (body) => {
    Segment.track(Event.HOME_SERVICES_CLICK, {
      optionId: body.optionId,
      option: body.option
    })
  }
}

export const SupportEvent = {
  /**
   * User clicks on support button
   */
  clickSupport: ({ source, user }) => {
    const islogin = user !== null
    user = islogin && { ...TransformUser(user) }
    Segment.track(Event.CLICK_SUPPORT, {
      source,
      ...user,
      islogin
    })
  }
}

export const QuestionnaireEvent = {
  /**
   * User selects Start now in the welcome modal
   */
  clickStartNow: ({ user }) => {
    Segment.track(Event.CLICK_START_NOW, {
      ...TransformUser(user)
    })
  },
  /**
   * User selects Book orientation in the welcome modal
   */
  clickRecommended: ({ user }) => {
    Segment.track(Event.CLICK_VIDEO_ORIENTATION, {
      ...TransformUser(user)
    })
  },

  /**
   * User submits results for the questionnaire
   */
  MatchingSummary_Submit: (body) => {
    Segment.track(Event.MATCHING_SUMMARY_SUBMIT, {
      ...MapperOnboarding(body),
      form: body.form,
      questions_responded: body.questions_responded
    })
  },

  /**
   * User clicks on close in the questionnaire screen
   * deprecated
   */
  closeQuestionnaire: ({ user }) => {
    Segment.track(Event.CLOSE_QUESTIONNAIRE, {
      ...TransformUser(user)
    })
  },
  /**
   * User clicks on close button in the questionnaire result screen
   */
  closeQuestionnaireResultModal: ({ user, result }) => {
    Segment.track(Event.MODAL_MATCHING_CLICK, {
      ...TransformUser(user),
      result
    })
  },
  /**
   * User clicks on close in the questionnaire result screen
   */
  closeQuestionnaireResult: ({ user, result }) => {
    Segment.track(Event.MODAL_MATCHING_RESULT, {
      ...TransformUser(user),
      result
    })
  }
}

export const AddiEvent = {
  /**
   * User selects See Get creddit in the ADDI banner / widget
   */
  select_addibanner: 'select_addibanner',

  /**
   * User clicks on Purchase package inside the Addi credit success modal
   */
  select_purchasepackage_addi: 'select_purchasepackage_addi'
}

export const BussinessEvent = {
  /**
   * User selects See benefit in the business modal
   */
  select_seebenefit_business: 'select_seebenefit_business'
}

export const GenericPackagesEvent = {
  /**
   * User clicks on View packages in the home when she doesn't have a specialist
   */
  select_viewgenericpackages: 'select_viewgenericpackages',

  /**
   * User clicks on a service for her packages in the first screen for generic packages
   */
  select_servicepackages: 'select_servicepackages',

  /**
   * User clicks on See all available specialists in one of the tabs or inside the package details
   */
  select_viewavailablespecialists: 'select_viewavailablespecialists',

  /**
   * User clicks on any tab on the tabview of packages
   */
  use_tabview_package: 'use_tabview_package',

  /**
   * User clicks on Discover specialist in the purchase success modal for a package that didn't have a specialist
   */
  select_discoverspecialist: 'select_discoverspecialist'
}

export const AfterCallEvent = {
  /**
   * User clics on see recommended specialist, after declining to continue with the specialist
   */
  select_seeotherspecialists_aftercal: 'select_seeotherspecialists_aftercal',
  /**
   * User clics on search my specialist in the orientation aftercall screen
   */
  select_searchmyspecialist_aftercall: 'select_searchmyspecialist_aftercall',
  /**
   * User clics on respond questionnaire in the orientation aftercall screen
   */
  select_respondquestionnaire_after: 'select_respondquestionnaire_after',
  /**
   * User chooses to continue with the specialist after her first contact
   */
  select_keepspecialist_aftercall: 'select_keepspecialist_aftercall',
  /**
   * User chooses to change specialist after her first contact
   */
  select_changespecialist_aftercall: 'select_changespecialist_aftercall',
  /**
   * User clics on any package listed in the aftercall
   */
  select_package_aftercall: 'select_package_aftercall',
  /**
   * User clics on complete payment in the appointment card at the aftercall
   */
  select_completepayment_aftercall: 'select_completepayment_aftercall',
  /**
   * User clicks the close button or clicks outside the modal to the modal that is shown in the after call flow.
   */
  Survey_User_Success_Condition: (body) => {
    Segment.track(Event.SURVEY_USER_SUCCESS_CONDITION, {
      ...MapperOnboarding(body),
      answer: body.answer
    })
  },
  /**
   * Event sent when the user sees aftercall screen
   */
  viewed: (body) => {
    Segment.track(Event.APPOINTMENT_AFTER_CALL_VIEWED, body)
  }
}

export const SpecialistProfileEvent = {
  /**
   * It is activated by clicking on the Book appointment button
   */
  clickSchedule: ({ specialist, user }) => {
    Segment.track(Event.CLICK_SCHEDULE, {
      ...TransformUser(user),
      ...specialist
    })
  },
  /**
   * User clicks on Reviews tab in the specialist profile
   */
  clickTabSelect: ({ tab, user }) => {
    Segment.track(Event.CLICK_TAB_SELECT, {
      ...TransformUser(user),
      tab
    })
  },
  /**
   * User clicks on add favorite specialist
   */
  addFavorite: ({ specialistId, specialistName }) => {
    Segment.track(Event.SPECIALIST_ADD_FAVORITE, {
      specialist_id: specialistId,
      specialist_name: specialistName
    })
  },
  /**
   * User clicks on remove favorite specialist
   */
  removeFavorite: ({ specialistId, specialistName }) => {
    Segment.track(Event.SPECIALIST_ADD_FAVORITE, {
      specialist_id: specialistId,
      specialist_name: specialistName
    })
  },

  /**
   * User reproduces the Audio in the specialist profile
   */
  select_playspecialistaudio: 'select_playspecialistaudio',

  /**
   * User reproduces the Video in the specialist profile
   */
  select_playspecialistvideo: 'select_playspecialistvideo',

  /**
   * User clics on the last button on the specialist profile to see all reviews
   */
  select_viewallreviews_specialist: 'select_viewallreviews_specialist',

  /**
   * Users collapses or uncollapses the about me accordion
   */
  select_aboutme_specialist: 'select_aboutme_specialist',

  /**
   * Users click tabs on stickytab
   */
  selectTab: ({ anchor }) => {
    Segment.track(Event.SPECIALIST_PROFILE_TAB, {
      option: anchor
    })
  },
  /**
   * Event is sent when the user sees the specialist profile
   */
  specialistView: (body) => {
    Segment.track(Event.SPECIALIST_PROFILE_VIEW, body)
  }
}

export const SeliaEventsEvent = {
  /**
   * User clicks on event card on even carrousel or events view
   */
  click_view_event: 'click_view_event'
}

export const OnboardingEvent = {
  /**
   * User clicks to navigate to matching view
   */
  Matching_Choose_View_Viewed: (body) => {
    Segment.track(Event.MATCHING_CHOOSE_VIEW_VIEWED, body)
  },
  /**
   * User clicks on one of the options in multiple choice screen
   */
  Matching_Choose_View: (body) => {
    Segment.track(Event.MATCHING_CHOOSE_VIEW, {
      ...MapperOnboarding(body)
    })
  },
  /**
   * User clicks on one of the options in multiple choice screen
   */
  Matching_Choose_Click: (option) => {
    Segment.track(Event.MATCHING_CHOOSE_CLICK, {
      option
    })
  },
  /**
   * User sign up in selia and view slice 1
   */
  onboadingIntroView: (body) => {
    Segment.track(Event.ONBOARDING_INTRO_VIEW, {
      ...MapperOnboarding(body)
    })
  },
  /**
   * User sign up in selia and view slice 2
   */
  onboadingIntro2View: (body) => {
    Segment.track(Event.ONBOARDING_INTRO2_VIEW, {
      ...MapperOnboarding(body)
    })
  },
  /**
   * User sign up in selia and view slice 3
   */
  onboadingIntro3View: (body) => {
    Segment.track(Event.ONBOARDING_INTRO3_VIEW, {
      ...MapperOnboarding(body)
    })
  },
  /**
   * User go back to selia and see screen back
   */
  onboadingScreenBack: (body) => {
    Segment.track(Event.ONBOARDING_SCREEN_BACK, {
      ...MapperOnboarding(body)
    })
  },
  /**
   * User clics on continue questionnaire button
   */
  onboadingScreenBackContinue: (body) => {
    Segment.track(Event.ONBOARDING_SCREEN_BACK_CONTINUE, {
      ...MapperOnboarding(body),
      onboarding_step: body.step
    })
  },
  /**
   * User select name and clics on continue button
   */
  onboadingName: (body) => {
    Segment.track(Event.ONBOARDING_NAME, {
      ...MapperOnboarding(body),
      name: body.name
    })
  },
  /**
   * User select gender and clics on continue button
   */
  onboadingGender: (body) => {
    Segment.track(Event.ONBOARDING_GENDER, {
      ...MapperOnboarding(body),
      gender: body.gender
    })
  },
  /**
   * User select birthday and clics on continue button
   */
  onboadingBirthday: (body) => {
    Segment.track(Event.ONBOARDING_BIRTHDAY, {
      ...MapperOnboarding(body),
      birthday: body.birthday
    })
  },
  /**
   * User select phone and clics on continue button
   */
  onboadingPhone: (body) => {
    Segment.track(Event.ONBOARDING_PHONE, {
      ...MapperOnboarding(body),
      phone: body.phone
    })
  },
  /**
   * Users see additionalstep
   */
  onboadingAddionalStep: (body) => {
    Segment.track(Event.ONBOARDING_EXPERIMENT, {
      ...MapperOnboarding(body),
      key: body.key,
      answers: body.answers
    })
  },
  /**
   * User view screen done
   */
  onboadingDone: (body) => {
    Segment.track(Event.ONBOARDING_DONE, {
      ...MapperOnboarding(body)
    })
  },
  onboadingView: (body) => {
    Segment.track(Event.ONBOARDING_INTRO_VIEW, {
      ...MapperOnboarding(body),
      onboarding_step: body.step
    })
  },
  onboadingFailed: (body) => {
    Segment.track(Event.ONBOARDING_FAILED, {
      ...MapperOnboarding(body),
      onboarding_step: body.step,
      error_type: body.error
    })
  },

  onboardingWelcomeView: () => {
    Segment.track(Event.ONBOARDING_WELCOME_VIEW)
  },
  /**
   * Event is sent when user complete express onboarding with name
   */
  completeProfileNameClick: (body) => {
    Segment.track(Event.ONBOARDING_COMPLETE_PROFILE_NAME, { ...body })
  },
  /**
   * Event is sent when user complete express onboarding
   */
  completeProfileClick: (body) => {
    Segment.track(Event.ONBOARDING_COMPLETE_PROFILE, { ...body })
  }
}

export const MatchingEvent = {
  /**
   * User view welcome screen
   */
  mathingWelcomeView: (hasX) => {
    Segment.track(Event.MATCHING_VIEW_WELCOME, {
      has_x: hasX
    })
  },

  /**
   * User clic continue button in welcome screen
   */
  Matching_Welcome_Start: () => {
    Segment.track(Event.MATCHING_WELCOME_START)
  },

  /**
   * User clic back button in questionnaire page
   */
  mathingBackBtnPage: (body) => {
    Segment.track(Event.MATCHING_BACK_BTN_PAGE, {
      key: body.key,
      isExperimental: body.isExperimental,
      data: body.data
    })
  },

  /**
   * User clic submit button in questionnaire page
   */
  mathingSubmitBtnPage: (body) => {
    Segment.track(Event.MATCHING_SUBMIT_PAGE, {
      key: body.key,
      isExperimental: body.isExperimental,
      data: body.data
    })
  },

  /**
   * User clic exit button in questionnaire page
   */
  mathingExitBtnPage: () => {
    Segment.track(Event.MATCHING_EXIT_BTN_PAGE)
  },

  /**
   * User clic exit button in confirm modal
   */
  mathingExitConfirm: () => {
    Segment.track(Event.MATCHING_EXIT_CONFIRM)
  },

  /**
   * User clic discard button in confirm modal
   */
  mathingExitDiscard: () => {
    Segment.track(Event.MATCHING_EXIT_DISCARD)
  },

  /**
   * User clic submit in summary
   */
  Matching_FillUp_Successful: (body) => {
    Segment.track(Event.MATCHING_FILLUP_SUCCESSFUL, {
      form: body.form
    })
  },

  /**
   * User clic in summary item
   */
  mathingSummaryEdit: (body) => {
    Segment.track(Event.MATCHING_SUMMARY_EDIT, {
      key: body.key
    })
  },

  /**
   * User click in see others specialists
   */
  matchingResultsSeeOthers: (body) => {
    Segment.track(Event.MATCHING_RESULTS_SEE_OTHERS, {
      result_quality: body.result_quality,
      num_specialists_listed: body.num_specialists_listed,
      option: body.option
    })
  },

  /**
   * User click in see questionnaire
   */
  matchingResultsEdit: (body) => {
    Segment.track(Event.MATCHING_RESULTS_EDIT, {
      result_quality: body.result_quality,
      num_specialists_listed: body.num_specialists_listed
    })
  },

  /**
   * User click in book an orientation
   */
  orientationStartBooking: (body) => {
    Segment.track(Event.ORIENTATION_START_BOOKING, {
      origin: body.origin
    })
  },

  /**
   * User click in a modal option
   */
  matchingSeeOthersClick: (body) => {
    Segment.track(Event.MATCHING_SEE_OTHERS_CLICK, {
      type: body.type,
      option: body.option
    })
  },

  /**
   * User see other specialists options
   */
  matchingSurveyFillUpSuccessful: (body) => {
    Segment.track(Event.MATCHING_SURVEY_FILLUP_SUCCESSFUL, {
      response: body.response,
      additional_data: body.additional_data,
      result_quality: body.result_quality,
      num_specialists_listed: body.num_specialists_listed
    })
  },

  /**
   * User clicks continue button after adding their reasons
   */
  Matching_Reasons_Continue: (response) => {
    Segment.track(Event.MATCHING_REASONS_CONTINUE, response)
  },

  /**
   * User clicks continue button after adding their gender
   */
  Matching_Gender_Continue: (gender) => {
    Segment.track(Event.MATCHING_GENDER_CONTINUE, gender)
  },

  /**
   * User clicks continue button after adding the approach they're looking for
   */
  Matching_Approach_Continue: (approaches) => {
    Segment.track(Event.MATCHING_APPROACH_CONTINUE, approaches)
  },

  /**
   * User clicks continue button after adding their budget
   */
  Matching_Budget_Continue: (budget) => {
    Segment.track(Event.MATCHING_BUDGET_CONTINUE, budget)
  },

  /**
   * User clicks continue button after adding their availability
   */
  Matching_Availability_Continue: (availability) => {
    Segment.track(Event.MATCHING_AVAILABILITY_CONTINUE, availability)
  },

  /**
   * User views the specialist profile in matching results
   */
  matchingResultsSpecialistProfileViewed: (body) => {
    Segment.track(Event.MATCHING_RESULTS_SPECIALIST_PROFILE_VIEWED, body)
  },

  /**
   * User click the arrow to see another result
   */
  matchingResultsArrowClicked: (body) => {
    Segment.track(Event.MATCHING_RESULTS_ARROW_CLICKED, body)
  },

  /**
   * User matching results viewed
   */
  matchingResultsViewed: (body) => {
    Segment.track(Event.MATCHING_RESULTS_VIEWED, body)
  }
}

export const ChatEvent = {
  /**
   * Event is sent by clicking one of the shortcut icons
   **/
  ChatHeader_Click: (body) => {
    const options = {
      rightAction: 'Orientation info',
      remoteInfo: 'Specialist profile'
    }

    Segment.track(Event.CHAT_HEADER_CLICK, {
      chat_with: body.specialist_id,
      specialist_is: body.specialist_is,
      option: options[body.option]
    })
  },
  /**
   * Event is sent by clicking on the chat card
   */
  ChatCard_Click: (body) => {
    Segment.track(Event.CHAT_CARD_CLICK, {
      origin: body.origin,
      chat_with: body.chat_with,
      specialist_is: body.specialist_is,
      last_message_time: body.last_message_time,
      last_message_status: body.last_message_status
    })
  }
}

export const ForYouEvent = {
  /**
   * Event is sent by clicking one of the content for you
   **/
  contentCardOpen: (body) => {
    Segment.track(Event.FOR_YOU_CLICK_CONTENT, {
      ...body
    })
  },
  /**
   * Event is sent by clicking one of the content type filter in for you
   **/
  contentTypeFilter: (body) => {
    Segment.track(Event.FOR_YOU_CLICK_CONTENT_TYPE, {
      ...body
    })
  },
  /**
   * Event is sent by clicking the search button
   **/
  searchButtonOpen: () => {
    Segment.track(Event.FOR_YOU_SEARCH_OPEN)
  },
  /**
   * Event is sent by clicking one of the filters topic
   **/
  topicOpen: (body) => {
    Segment.track(Event.FOR_YOU_TOPIC_OPEN, {
      ...body
    })
  },
  /**
   * Event is sent by filter by name in search
   **/
  searchSuccessful: (body) => {
    Segment.track(Event.FOR_YOU_SEARCH_SUCCESSFUL, {
      ...body
    })
  }
}

export const DataZero = {
  /**
   * Event is sent by clicking data zero button
   **/
  dataZeroClick: (body) => {
    Segment.track(Event.DATAZERO_CLICK, {
      option: body.option,
      origin: body.origin
    })
  }
}

export const WarrantyEvent = {
  /**
   * Event is sent by enteering the aftercall view
   **/
  Warranty_Call_Specialist_Continue_Viewed: () => {
    Segment.track(Event.WARRANTY_SPECIALIST_CONTINUE_VIEWED)
  },
  /**
   * Event is sent by enteering the activation view
   **/
  Warranty_Call_Activation_Viewed: () => {
    Segment.track(Event.WARRANTY_CALL_ACTIVATION_VIEWED)
  },
  /**
   * Event is sent by enteering the active view
   **/
  Warranty_Call_Active_Viewed: () => {
    Segment.track(Event.WARRANTY_CALL_ACTIVE_VIEWED)
  },
  /**
   * Event is sent by enteering the Unavailable view
   **/
  Warranty_Call_Unavailable_Viewed: () => {
    Segment.track(Event.WARRANTY_CALL_UNAVAILABLE_VIEWED)
  },
  /**
   * Event is sent by choosing an option in the afrercall view
   **/
  Warranty_Call_Specialist_Continue_Clicked: (option) => {
    Segment.track(Event.WARRANTY_CALL_SPECIALIST_CONTINUE_CLICKED, {
      option
    })
  },
  /**
   * Event is sent by submitting the aftecall survey
   **/
  Warranty_Call_Survey_Submitted: (body) => {
    Segment.track(Event.WARRANTY_CALL_SURVEY_SUBMITTED, {
      ...body
    })
  },
  /**
   * Event is sent by clicking a button in the activation view
   **/
  Warranty_Call_Activation_Clicked: (option) => {
    Segment.track(Event.WARRANTY_CALL_ACTIVATION_CLICKED, {
      option
    })
  },
  /**
   * Event is sent by clicking a button in the active view
   **/
  Warranty_Call_Active_Clicked: (option) => {
    Segment.track(Event.WARRANTY_CALL_ACTIVE_CLICKED, {
      option
    })
  },
  /**
   * Event is sent by clicking a button in the unavailable view
   **/
  Warranty_Call_Unavailable_Clicked: (option) => {
    Segment.track(Event.WARRANTY_CALL_UNAVAILABLE_CLICKED, {
      option
    })
  }
}

export const ProgramsEvent = {
  /**
   * Event is sent by clicking a button in programs section
   **/
  Programs_Button_Clicked: (body) => {
    Segment.track(Event.PROGRAMS_BUTTON_CLICKED, {
      ...body
    })
  },
  /**
   * Event is sent by enteering the programs view
   **/
  Programs_Page_Viewed: (body) => {
    Segment.track(Event.PROGRAMS_PAGE_VIEWED, {
      ...body
    })
  }
}

export const RecommendedEvent = {
  /** Event is sent when users view the recommended view */
  RecommendedViewed: (body) => {
    Segment.track(Event.RECOMMENDED_SPECIALIST_VIEWED, body)
  }
}

export const NotificationEvent = {
  InAppMessageViewed: (body) => {
    Segment.track(Event.IN_APP_MESSAGE_VIEWED, body)
  },
  InAppMessageDismissed: (body) => {
    Segment.track(Event.IN_APP_MESSAGE_DISMISSED, body)
  },
  InAppMessageClicked: (body) => {
    Segment.track(Event.IN_APP_MESSAGE_CLICKED, body)
  }
}
