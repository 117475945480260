export const MediaTypes = {
  BLOGS: 'blogs',
  FEATURED: 'featured',
  MEDITATIONS: 'meditations',
  VIDEO_GUIDE: 'video_guides'
}

export const CapitalMediaTypes = {
  BLOGS: 'BLOGS',
  FEATURED: 'FEATURED',
  MEDITATIONS: 'MEDITATIONS',
  VIDEO_GUIDE: 'VIDEO_GUIDE'
}

export const CapitalizeMediaTypes = {
  BLOGS: 'Blogs',
  FEATURED: 'Featured',
  MEDITATIONS: 'Meditations',
  VIDEO_GUIDE: 'VideoGuides'
}
