import { Programs, Promos, Toast } from '@seliaco/red-panda'
import { mapResponseData } from '@/store/helpers/mapProgramsResponse'

export const ProgramsStore = {
  namespaced: true,

  state: {
    program: [],
    programSpecialists: [],
    programs: [],
    loading: false
  },
  getters: {
    getProgram (state) {
      return state.program
    },
    getProgramSpecialists (state) {
      return state.programSpecialists
    },
    getPrograms (state) {
      return state.programs
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_PROGRAMS_LIST (state, { programs }) {
      state.programs = programs
    },
    SET_PROGRAM (state, { program }) {
      state.program = program
    },
    SET_PROGRAM_SPECIALISTS (state, { programSpecialists }) {
      state.programSpecialists = programSpecialists
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async getPrograms ({ commit, rootState }) {
      // reset list
      commit('SET_PROGRAMS_LIST', { programs: [] })
      commit('SET_LOADING', { loading: true })

      const lang = rootState.language.lang

      Programs.list()
        .then(async (response) => {
          const results = response.results.reverse()
          const programs = await mapResponseData(results, lang)

          commit('SET_PROGRAMS_LIST', { programs: programs })
        })
        .catch((e) => {
          Toast({
            severity: 'error',
            text: e.message
          })
        })
        .finally(() => commit('SET_LOADING', { loading: false }))
    },
    async getSpecialistPrograms ({ commit, rootState }, payload) {
      // reset list
      commit('SET_PROGRAMS_LIST', { programs: [] })
      commit('SET_LOADING', { loading: true })

      const lang = rootState.language.lang

      Programs.listBySpecialist(payload)
        .then(async (response) => {
          const programs = await mapResponseData(response, lang)

          commit('SET_PROGRAMS_LIST', { programs: programs })
        })
        .catch((e) => {
          Toast({
            severity: 'error',
            text: e.message
          })
        })
        .finally(() => commit('SET_LOADING', { loading: false }))
    },
    async getProgramId ({ commit, rootState }, { id }) {
      commit('SET_LOADING', { loading: true })

      const lang = rootState.language.lang

      Programs.getById(id)
        .then((element) => {
          let name = element.name
          let subtitle = element.meta.subtitle
          let modality = element.meta.modality
          let duration = element.meta.duration
          let content = element.content
          let programContent = element.meta.program_content
          let description = element.description

          if (lang !== 'es') {
            name = element[`name_${lang}`]
            subtitle = element.meta[`subtitle_${lang}`]
            modality = element.meta[`modality_${lang}`]
            duration = element.meta[`duration_${lang}`]
            content = element[`content_${lang}`]
            programContent = element.meta[`program_content_${lang}`]
            description = element[`description_${lang}`]
          }

          const program = {
            id: element.id,
            price: element.price,
            currency: element.product_packages[0].price.currency,
            name,
            subtitle,
            description,
            content,
            discount: element.discount,
            duration: duration,
            modality: modality,
            programContent,
            image: element.meta.image,
            session: element.product_packages[0].max_uses.toString()
          }

          const specialists = element.specialists.map((specialist) => {
            return {
              ...specialist,
              specialities: specialist.specialities.map((speciality) => {
                return {
                  speciality: {
                    name: speciality[`name_${lang}`]
                  }
                }
              })
            }
          })

          commit('SET_PROGRAM', { program: program })
          commit('SET_PROGRAM_SPECIALISTS', { programSpecialists: specialists })
        })
        .finally(() => commit('SET_LOADING', { loading: false }))
    },
    async getProgramSpecialists ({ commit }, { id }) {
      commit('SET_LOADING', { loading: true })

      const programSpecialists = await Promos.Packages.listSpecialists(
        id,
        'programs'
      )
      commit('SET_PROGRAM_SPECIALISTS', { programSpecialists })
      commit('SET_LOADING', { loading: false })
    }
  }
}
