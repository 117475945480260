import { ServicesTypes, Toast } from '@seliaco/red-panda'

export const ServicesStore = {
  namespaced: true,
  state: {
    serviceTypes: [],
    loading: false
  },
  getters: {
    getServicesTypes (state) {
      return state.serviceTypes
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_SERVICE_TYPES (state, { serviceTypes }) {
      state.serviceTypes = serviceTypes
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit, state }) {
      if (state.serviceTypes.length <= 0) {
        commit('SET_LOADING', { loading: true })
      }

      const serviceTypesRequest = await ServicesTypes.read({
        eq: {
          hidden: false
        },
        orderBy: {
          column: 'weight',
          params: {
            ascending: false
          }
        }
      })
        .catch((error) => {
          Toast({
            severity: 'error',
            text: error.message
          })
        })

      const serviceTypesResult = [...serviceTypesRequest.data]
      commit('SET_SERVICE_TYPES', { serviceTypes: serviceTypesResult })

      commit('SET_LOADING', { loading: false })
    }
  }
}
