<template>
  <DialogContainer
    :icon="configModal.icon"
    :contentIconBackground="configModal.background"
    @closeDialog="handlerClose"
  >
    <template v-slot:content>
      <HtmlViewer class="heading" :html-content="translations.title" />

      <div
        class="body"
        :class="responsiveClass('margin-top', 'margin-top-m-2')"
        v-text="translations.content"
        v-if="type !== 'remove'"
      />

      <PersonCard
        :class="responsiveClass('margin-top', 'margin-top-m-2')"
        :image="member.meta.profile_picture"
        :title="titleText(member.meta)"
        :firstLine="role(member.role)"
        v-if="type === 'remove'"
      />

      <Button
        :class="responsiveClass('margin-top-lg-2', 'margin-top-lg-3')"
        class="margin-bottom-xs"
        :color="configModal.button"
        :text="translations.action"
        @clicked="handlerSubmit"
      />
      <Button
        type="outline"
        :color="configModal.button"
        :text="translations.close"
        @clicked="handlerClose"
      />
    </template>
  </DialogContainer>
</template>

<script>
import {
  DialogContainer,
  PersonCard,
  Button,
  ResponsiveMixin,
  HtmlViewer
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'SharedAccountRemoveModal',
  components: { DialogContainer, PersonCard, Button, HtmlViewer },
  mixins: [ResponsiveMixin],
  props: {
    type: {
      type: String,
      default: 'remove'
    },
    member: Object
  },
  data () {
    const translations = {
      title: this.$translations['shared-account'].modal[this.type].title,
      content: this.$translations['shared-account'].modal[this.type].content,
      action:
        this.$translations['shared-account'].modal[this.type].buttons.action,
      close: this.$translations['shared-account'].modal[this.type].buttons.close
    }

    return {
      translations
    }
  },
  methods: {
    handlerSubmit () {
      let dispatch = 'sharedAccount/remove'
      let props = {
        sharedAccountMemberId: this.member?.id
      }

      if (this.type === 'delete') {
        dispatch = 'sharedAccount/deleteAccount'
        props = null
      }

      this.$store.dispatch(dispatch, props).then(() => {
        this.handlerClose()

        let toastType = 'remove-success'

        if (this.type === 'delete') {
          toastType = 'remove-group-success'
          this.$router.replace({ name: 'Home' })
        }

        if (this.type === 'leave') {
          toastType = 'leave-group-success'
          this.$router.replace({ name: 'Home' })
        }

        this.$toast({
          severity: 'success',
          text: this.$translations['shared-account'].toast[toastType]
        })
      })
    },
    handlerClose () {
      this.$globalEvent.$emit('modal/shared-account-remove', {
        showDialog: { sharedAccountRemoveModal: { open: false, close: true } }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language',
      settings: 'settings/getSettings'
    }),

    role () {
      return (role) => {
        const roleFind = this.settings.sharedAccountOptions.find(
          (option) => option.value === role
        )

        return roleFind.label[this.language.lang] || ''
      }
    },
    titleText () {
      return (meta) => {
        return meta.first_name || meta.last_name
      }
    },

    configModal () {
      if (this.type === 'delete') {
        return {
          icon: 'icon-trash-on text-red',
          background: 'bg-red-10',
          button: 'red'
        }
      }

      if (this.type === 'leave') {
        return {
          icon: 'icon-group text-red',
          background: 'bg-red-10',
          button: 'red'
        }
      }

      return {
        icon: null,
        background: null,
        button: 'purple'
      }
    }
  }
}
</script>
