<template>
  <DialogContainer
    :icon="config && config.icon.name"
    icon-color="purple"
    :custom-icon="true"
    :content-icon-background="config && config.icon.background"
    @closeDialog="closeDialog('close')"
  >
    <template v-slot:content>
      <div v-if="config && !loading">
        <div class="heading margin-y" v-text="config && config.title[lang]" />
        <div class="fields-container">
          <DynamicForm
            @form="setValue($event)"
            :fields="config.fields"
            :lang="lang"
          />

          <InputTextField
            v-if="showInput"
            v-model="form.field_value.additional_data"
            ref-name="dont_like_specialists_survey_text"
            :label="$translations['suggested-specialists'].dialog.label"
            :lang="lang"
            class="input-field"
          />
        </div>

        <Button
          @clicked="done"
          :text="$translations['suggested-specialists'].dialog.button"
          :disable="!isValid"
        />
      </div>
      <div v-if="loading" class="skeleton-container">
        <Skeleton
          class="margin-y-sm"
          width="56px"
          height="56px"
          border-radius="100%"
        />
        <Skeleton
          class="margin-top margin-bottom-xs"
          width="100%"
          height="62px"
          border-radius="8px"
        />
        <Skeleton
          v-for="index in 5"
          :key="index"
          width="100%"
          height="60px"
          border-radius="8px"
        />
        <Skeleton
          class="margin-top"
          width="100%"
          height="48px"
          border-radius="8px"
        />
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import {
  Button,
  DialogContainer,
  Settings,
  DynamicForm,
  Skeleton,
  Survey
} from '@seliaco/red-panda'
import { InputTextField } from '@seliaco/sea-otter'
import { MatchingEvent } from '@/types/events'

export default {
  name: 'DontLikeSpecialistsDialog',
  components: {
    DialogContainer,
    Button,
    DynamicForm,
    Skeleton,
    InputTextField
  },
  data () {
    return {
      loading: false,
      config: null,
      form: {
        field_key: 'FORM_DONT_LIKE_ABOUT_SPECIALISTS',
        field_value: {
          value: null,
          additional_data: null
        }
      },
      showInput: false,
      lang: '',
      fields: [],
      isValid: false
    }
  },
  mounted () {
    this.lang = this.$store.getters.language.lang
    this.getConfig()
  },
  destroyed () {
    this.$globalEvent.$off('change-specialists')
  },
  methods: {
    getConfig () {
      this.loading = true

      Settings.get('FORM_DONT_LIKE_ABOUT_SPECIALISTS')
        .then((setting) => {
          this.config = setting.parse_value
          this.fields = this.config.fields[0].options.map((item) => {
            item.name = item.name[this.lang]
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeDialog (event) {
      if (event) {
        this.callSegmentEvents(event)
      }
      this.$globalEvent.$emit('modal/dont-like-specialists', {
        showDialog: { dontLikeSpecialists: { open: false, close: true } }
      })
    },
    done () {
      Survey.createMatchingAnswer(this.form)
        .then(() => {
          this.closeDialog()
          const payload = {
            response: {
              field_key: this.form.field_key,
              field_value: this.form.field_value.value
            },
            additional_data: this.form.field_value.additional_data
          }
          this.$globalEvent.$emit('change-specialists', payload)
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
    },
    setValue (event) {
      this.isValid = event.isValid
      if (this.isValid) {
        this.showInput = event.values.dislike.values.value === 'OTHER'
        this.form.field_value = {
          ...this.form.field_value,
          value: event.values.dislike.values.value
        }
        this.callSegmentEvents(this.form.field_value.value)
      }
    },
    callSegmentEvents (option) {
      const body = {
        type: 'Survey',
        option: option
      }
      MatchingEvent.matchingSeeOthersClick(body)
    }
  }
}
</script>

<style lang="sass" scoped>
.skeleton-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 8px
.fields-container
  text-align: initial
  margin-bottom: 24px
  .input-field
    margin: 8px 0 24px
  ::v-deep .select-opened
    margin-bottom: 0
::v-deep .icon-display
  font-size: 34px
</style>
