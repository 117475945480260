export const userStatus = {
  ON_BOARDING_IN_PROGRESS: 'ON_BOARDING_IN_PROGRESS',
  ON_BOARDING_FULL_NAME_COMPLETED: 'ON_BOARDING_FULL_NAME_COMPLETED',
  ON_BOARDING_GENDER_COMPLETED: 'ON_BOARDING_GENDER_COMPLETED',
  ON_BOARDING_BIRTHDAY_COMPLETED: 'ON_BOARDING_BIRTHDAY_COMPLETED',
  ON_BOARDING_PHONE_COMPLETED: 'ON_BOARDING_PHONE_COMPLETED',
  ON_BOARDING_THERAPY_REASON_INIT: 'ON_BOARDING_THERAPY_REASON_INIT',
  account_created: 'ACCOUNT_CREATED',
  waiting_for_orientation: 'WAITING_FOR_ORIENTATION',
  waiting_for_first_contact: 'WAITING_FOR_FIRST_CONTACT',
  active: 'ACTIVE'
}

export const onboardingStatus = [
  userStatus.ON_BOARDING_IN_PROGRESS,
  userStatus.ON_BOARDING_FULL_NAME_COMPLETED,
  userStatus.ON_BOARDING_GENDER_COMPLETED,
  userStatus.ON_BOARDING_BIRTHDAY_COMPLETED,
  userStatus.ON_BOARDING_PHONE_COMPLETED,
  userStatus.ON_BOARDING_THERAPY_REASON_INIT
]

export const activeStatus = [
  userStatus.account_created,
  userStatus.waiting_for_orientation,
  userStatus.waiting_for_first_contact,
  userStatus.active
]
