import { SpecialistFilter } from '@seliaco/red-panda'
import { SpecialistSearch } from '@/types/events'
import { prepareQuery, pagination } from '@/store/helpers/filter'

export const SpecialistByNameStore = {
  namespaced: true,
  state: {
    results: [],
    query: {
      name: null
    },
    pagination,
    loading: {
      results: false
    },
    loaders: {
      newPage: false
    }
  },
  getters: {
    results (state) {
      return state.results
    },
    query (state) {
      return state.query
    },
    pagination (state) {
      return state.pagination
    },
    loading (state) {
      return state.loading.results
    },
    loaders (state) {
      return state.loaders
    }
  },
  mutations: {
    SET_RESULTS (state, results) {
      state.results = results
    },
    SET_QUERY (state, query) {
      state.query = {
        ...state.query,
        ...query
      }
    },
    SET_PAGINATION (state, pagination) {
      state.pagination = {
        ...state.pagination,
        ...pagination
      }
    },
    SET_LOADING (state, { key, value }) {
      state.loading[key] = value
    },
    SET_LOADERS (state, { key, value }) {
      state.loaders[key] = value
    }
  },
  actions: {
    cleanResults ({ commit }) {
      commit('SET_RESULTS', [])
      commit('SET_PAGINATION', pagination)
    },
    getResults ({ commit, state }, { cleanedRequest }) {
      commit('SET_LOADERS', {
        key: 'newPage',
        value: true
      })

      const query = prepareQuery(state.query)

      if (cleanedRequest) {
        commit('SET_PAGINATION', pagination)
      }

      SpecialistFilter.filter(state.pagination, query).then(response => {
        let results = response.data

        if (!cleanedRequest) {
          results = [
            ...state.results,
            ...results
          ]
        }

        commit('SET_RESULTS', results)

        commit('SET_PAGINATION', {
          page: Number(response.headers.page),
          perPage: Number(response.headers.perPage),
          pages: Number(response.headers.pages),
          count: Number(response.headers.count),
          unfiltered: response.headers['X-Pagination-Unfiltered-Count'],
          filtered: response.headers['X-Pagination-Filtered-Count']
        })
        commit('SET_LOADING', {
          key: 'results',
          value: false
        })
        commit('SET_LOADERS', {
          key: 'newPage',
          value: false
        })

        SpecialistSearch.Specialist_Filter_Successful({
          numSpecialistsFound: Number(response.headers.count),
          searchTerm: state.query.name
        })
      })
    },
    setQuery ({ commit, dispatch }, { values, cleanedRequest }) {
      commit('SET_QUERY', values)

      commit('SET_LOADING', {
        key: 'query',
        value: false
      })

      dispatch('getResults', { cleanedRequest })
    }
  }
}
