<template>
  <DialogContainer :title="config.modaltitle" @closeDialog="closeModal">
    <template v-slot:content>
      <MediumCard
        :image="option.img"
        :title="option.title"
        :bottom-line="option.content"
        :showAction="true"
        @clicked="onSelectOption(index)"
        v-for="(option, index) of options"
        :key="index"
      />
    </template>
  </DialogContainer>
</template>

<script>
import {
  DialogContainer,
  MatchingAgent,
  MatchingAgentIllustration,
  UpcomingAppointments,
  MediumCard,
  SearchAll,
  RoleMixin
} from '@seliaco/red-panda'
import { userStatus } from '@/types/state'
import { mapGetters } from 'vuex'

export default {
  name: 'StartModal',
  components: {
    DialogContainer,
    MediumCard
  },
  mixins: [RoleMixin],
  data () {
    const config = {
      modaltitle: this.$translations.home['start-modal']['modal-title']
    }

    return {
      config,
      options: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let options = {
        sugested: {
          content: this.$translations.home['start-modal'].sugest.content,
          title: this.$translations.home['start-modal'].sugest.title,
          img: MatchingAgent
        },
        orientation: {
          content: this.$translations.home['start-modal'].orientation.content,
          title: this.$translations.home['start-modal'].orientation.title,
          img: MatchingAgentIllustration
        }
      }

      if (this.role === this.roleType.USER_HIDE_PRICES) {
        delete options.orientation
      }

      const stateAllow = [
        userStatus.waiting_for_orientation,
        userStatus.account_created
      ]
      if (stateAllow.includes(this.user.status)) {
        options = {
          ...options,
          specialists: {
            content: this.$translations.home['start-modal'].specialist.content,
            title: this.$translations.home['start-modal'].specialist.title,
            img: SearchAll
          }
        }
      }

      this.options = options
    },
    async onSelectOption (index) {
      this.closeModal()

      if (index === 'sugested') {
        this.$store.commit('flow/RESET_MATCHING')
        let blockInit = 'individual-therapy'

        if (this.role === this.roleType.USER_HIDE_PRICES) {
          blockInit = 'individual-therapy-hide-prices'
        }

        this.$router.push({
          name: 'QuestionnaireContainer',
          query: {
            blockInit
          }
        })
      } else if (index === 'orientation') {
        const nextAppointment = await UpcomingAppointments.getMyAppointments(
          'USER',
          this.$store.getters['auth/user'].id
        )
        if (
          nextAppointment?.appointment?.specialist_service?.type ===
          'ORIENTATION'
        ) {
          this.$router.push({
            name: 'AppointmentDetails',
            params: {
              id: nextAppointment.appointment.id
            }
          })
        } else {
          this.$router.push({
            name: 'RecommendedCounselling',
            query: {
              back: this.$route.fullPath,
              init: true
            }
          })
        }
      } else if (index === 'specialists') {
        this.$router.push({ name: 'Search' })
      }
    },
    closeModal () {
      this.$globalEvent.$emit('modal/start', {
        showDialog: { start: { open: false, close: true } }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      role: 'auth/role'
    })
  }
}
</script>
