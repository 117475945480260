import { messagesByKey } from './messages'
let language = getClientLang()
const imageUrl = '../placeholderimages/logo.svg'
let screenWidth = window.innerWidth

const LOADER_WIDTH_DEFAULT = '100vw'
const LOADER_WIDTH_MAIN = '115vw'
const MAIN_TRANSPARENT = 'main-transparent'
const DEGRADE = 'degrade'
const TRANSPARENT = 'transparent'

function getLabel (lang) {
  const config = {
    es: {
      label: 'CARGANDO ...'
    },
    en: {
      label: 'LOADING ...'
    },
    pt: {
      label: 'CARREGANDO ...'
    }
  }
  return config[lang].label
}

function getClientLang () {
  const userLanguage = navigator.language || navigator.userLanguage
  return ['es', 'en', 'pt'].includes(userLanguage) ? userLanguage : 'en'
}

function generateRandomNumber (max) {
  // Scale the random number to the desired range (0 to 5) and round it to the nearest integer
  return Math.floor(Math.random() * max)
}

function setInnerHtml (loaderType, key) {
  let width
  let background
  switch (loaderType) {
    case MAIN_TRANSPARENT: {
      width =
        screenWidth > 1040 && screenWidth < 1450
          ? LOADER_WIDTH_MAIN
          : LOADER_WIDTH_DEFAULT
      background = 'transparent'
      break
    }

    case DEGRADE: {
      width = LOADER_WIDTH_DEFAULT
      background = 'linear-gradient(to top right, #E6DCFA, #E5F3FC)'
      break
    }

    case TRANSPARENT: {
      width = LOADER_WIDTH_DEFAULT
      background = 'transparent'
      break
    }
  }

  const messages = messagesByKey(language, key)
  const index = generateRandomNumber(messages.length)
  const label = getLabel(language)

  const spinnner = `
  <div
    id="selia-spinner"
    class="selia-spinner"
    style="width: ${width}; background: ${background}"
    >
        <div class="selia-spinner-circle"></div>
        <img
          class="selia-spinner-logo"
          width="50"
          src="${imageUrl}"
          alt="selia"
        />
         <div class="selia-spinner-text">
            <div class="content-title text-gray-60">
                ${label}
            </div>
            <div class="body text-gray-60">
                ${messages.length ? messages[index] : ''}
            </div>
        </div>
        <div class="selia-spinner-radar-group">
          <div class="selia-spinner-radar"></div>
          <div class="selia-spinner-radar"></div>
          <div class="selia-spinner-radar"></div>
        </div>
      </div>
  `
  return `
    <div class="loader">
      ${spinnner}
    </div>
  `
}

function handleScreenSize () {
  screenWidth = window.innerWidth
}

function handleShowLoader ({ lang, type, key }) {
  if (lang) {
    language = lang
  }
  const container = document.getElementById('loader-container')
  container.innerHTML = setInnerHtml(type, key)
  container.style.display = 'block'
  handleScreenSize()
}

function handleRemoveLoader () {
  document.getElementById('loader-container').style.display = 'none'
}

function createComponent () {
  const loaderContainer = document.createElement('div')
  loaderContainer.id = 'loader-container'
  loaderContainer.style.display = 'none'
  loaderContainer.innerHTML = setInnerHtml()
  document.body.appendChild(loaderContainer)
}

document.addEventListener('DOMContentLoaded', createComponent)

export { handleShowLoader, handleRemoveLoader }
