<template>
  <DialogContainer :title="config.modaltitle" @closeDialog="closeModal">
    <template v-slot:content>
      <MediumCard
        :image="option.img"
        :title="option.title"
        :bottom-line="option.content"
        :showAction="true"
        @clicked="onSelectOption(index)"
        v-for="(option, index) of options"
        :key="index"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, MediumCard } from '@seliaco/red-panda'
import NavigateMatchingResetMixin from '@/mixins/navigate-matching-reset-mixin'

export default {
  name: 'ScheduleModal',
  components: {
    DialogContainer,
    MediumCard
  },
  mixins: [NavigateMatchingResetMixin],
  data () {
    const config = {
      modaltitle: this.$translations.home['schedule-modal']['modal-title']
    }
    const myspecialistImg = 'https://eoztuwqsaeoenxmxbvia.supabase.co/storage/v1/object/public/assets/specialists-list.png'
    const sugestedImg = 'https://eoztuwqsaeoenxmxbvia.supabase.co/storage/v1/object/public/assets/search-specialists.png'

    const options = {
      mySpecialists: {
        content:
          this.$translations.home['schedule-modal']['my-specialists'].content,
        title:
          this.$translations.home['schedule-modal']['my-specialists'].title,
        img: myspecialistImg
      },
      sugested: {
        content: this.$translations.home['schedule-modal'].sugest.content,
        title: this.$translations.home['schedule-modal'].sugest.title,
        img: sugestedImg
      }
    }
    return {
      config,
      options
    }
  },
  methods: {
    async onSelectOption (index) {
      this.closeModal()
      if (index === 'sugested') {
        this.handleMatchingNavigation()
      } else if (index === 'mySpecialists') {
        this.$router.push({
          name: 'MySpecialists',
          query: {
            back: 'Home',
            tab: 'history'
          }
        })
      }
    },
    closeModal () {
      this.$globalEvent.$emit('modal/schedule', {
        showDialog: { schedule: { open: false, close: true } }
      })
    }
  }
}
</script>
