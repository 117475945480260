import { Appointments } from '@seliaco/red-panda'

export const TimerStore = {
  namespaced: true,
  state: {
    timer: null,
    interval: null
  },
  getters: {
    interval (state) {
      return state.interval
    },
    get (state) {
      return state.timer
    }
  },
  mutations: {
    SET_TIMER (state, { timer }) {
      state.timer = timer
    },
    SET_INTERVAL (state, { interval }) {
      state.interval = interval
    }
  },
  actions: {
    async set ({ commit, dispatch }, { moment, min }) {
      dispatch('clearTimer')
      const time = min || '00:10:00'
      const timer = moment(`2013-05-09T${time}`)
      const interval = setInterval(() => {
        timer.add(-1, 'seconds')
        if (timer.format('mm:ss') === '00:00') {
          clearInterval(interval)
          commit('SET_INTERVAL', { interval: null })
        }

        commit('SET_TIMER', { timer: timer.format('mm:ss') })
      }, 1000)

      commit('SET_INTERVAL', { interval })
    },
    async setByAppointment ({ dispatch }, { moment, appointmentId }) {
      const appointments = await Appointments.read({
        columns: `
          created_at
        `,
        eq: {
          id: appointmentId
        },
        orderBy: {
          column: 'starts_at',
          params: {
            asspecialistIdcending: false
          }
        }
      })

      const time = moment().diff(moment(appointments?.data[0]?.created_at))
      let timeString = '00:00:01'
      if (time < 600000) {
        timeString = moment('2013-05-09T00:10:00')
          .add(-time, 'milliseconds')
          .format('HH:mm:ss')
      }

      dispatch('set', { moment, min: timeString })
    },
    clearTimer ({ commit, state }) {
      clearInterval(state.interval)
      commit('SET_INTERVAL', { interval: null })
      commit('SET_TIMER', { timer: null })
    }
  }
}
