import { ForYou, Toast } from '@seliaco/red-panda'

export const ForYouStore = {
  namespaced: true,

  state: {
    categories: [],
    contentTypes: [],
    group: [],
    entry: null,
    grouped: null,
    general: [],
    recommend: [],
    search: [],
    category: [],
    featured: [],
    loading: false,
    count: 0
  },
  getters: {
    entriesByTypeAndValue: (state) => {
      return (filters) => {
        return state.general.filter((entry) => {
          return Object.keys(filters).every((type) => {
            return entry[type] && entry[type].code === filters[type]
          })
        })
      }
    },
    categories (state) {
      return state.categories
    },
    contentTypes (state) {
      return state.contentTypes
    },
    group (state) {
      return state.group
    },
    recommend (state) {
      return state.recommend
    },
    search (state) {
      return state.search
    },
    grouped (state) {
      return state.grouped
    },
    category (state) {
      return state.category
    },
    featured (state) {
      return state.featured
    },
    loading (state) {
      return state.loading
    },
    count (state) {
      return state.count
    }
  },
  mutations: {
    SET_CATEGORIES (state, { categories }) {
      state.categories = [...categories]
    },
    SET_CONTENT_TYPES (state, { contentTypes }) {
      state.contentTypes = [...contentTypes]
    },
    SET_GROUP (state, { group }) {
      state.group = [...group]
    },
    SET_ENTRY (state, { entry }) {
      state.entry = entry
    },
    SET_GENERAL (state, { general }) {
      if (state.general.length > 0) {
        state.general = [...state.general, ...general]
      } else {
        state.general = [...general]
      }
    },
    SET_FEATURED (state, { featured }) {
      state.featured = [...featured]
    },
    SET_GROUPED (state, { grouped }) {
      state.grouped = grouped
    },
    SET_RECOMMEND (state, { recommend }) {
      state.recommend = [...recommend]
    },
    SET_SEARCH (state, { search }) {
      state.search = [...search]
    },
    SET_CATEGORY (state, { category }) {
      state.category = [...category]
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    },
    SET_COUNT (state, { count }) {
      state.count = count
    }
  },
  actions: {
    async list ({ commit }, { params, page, skipLoading }) {
      if (!skipLoading) {
        commit('SET_LOADING', { loading: true })
      }

      const stopLoading = () => {
        if (!skipLoading) {
          commit('SET_LOADING', { loading: false })
        }
      }

      const grouped = {}
      const response = await ForYou.listEntries(params).catch((error) => {
        Toast({
          severity: 'error',
          text: error.message
        })
      })

      if (params.recommended) {
        commit('SET_RECOMMEND', { recommend: response.entries })
        if (!skipLoading) {
          commit('SET_LOADING', { loading: false })
        }
        return
      }

      if (params.title) {
        commit('SET_SEARCH', { search: response.entries })
        commit('SET_COUNT', { count: response.totalCount })
        return stopLoading()
      }

      if (params.category && page) {
        commit('SET_CATEGORY', { category: response.entries })
        return stopLoading()
      }

      if (params.is_featured) {
        commit('SET_FEATURED', { featured: response.entries })
        return stopLoading()
      }

      if (params.group) {
        const entries = response.entries

        entries.map((entry) => {
          if (!grouped[entry.category.code]) {
            grouped[entry.category.code] = [entry]
          } else {
            grouped[entry.category.code] = [
              ...grouped[entry.category.code],
              entry
            ]
          }
        })

        commit('SET_GROUPED', { grouped })
        return stopLoading()
      }

      commit('SET_GENERAL', { general: response.entries })

      return stopLoading()
    },
    async entry ({ commit }, { slug }) {
      commit('SET_LOADING', { loading: true })

      const response = await ForYou.entryBySlug(slug).catch((error) => {
        Toast({
          severity: 'error',
          text: error.message
        })
      })

      commit('SET_ENTRY', { entry: response.value })
      commit('SET_LOADING', { loading: false })
    },
    async setup ({ commit, dispatch }) {
      commit('SET_LOADING', { loading: true })

      const forYouGroups = ForYou.getGroups().catch((error) => {
        Toast({
          severity: 'error',
          text: error.message
        })
      })

      const forYouCategories = ForYou.getCategories()
        .catch((error) => {
          Toast({
            severity: 'error',
            text: error.message
          })
        })
        .then(async (response) => {
          const promises = response.categories.map((category) => {
            return dispatch('list', {
              params: {
                category: category.code,
                page: 1,
                per_page: 12
              },
              skipLoading: true
            })
          })

          promises.push(
            dispatch('list', {
              params: {
                is_featured: true
              },
              skipLoading: true
            })
          )

          await Promise.all(promises)

          return response
        })

      const forYouContentTypes = ForYou.getContentTypes().catch((error) => {
        Toast({
          severity: 'error',
          text: error.message
        })
      })

      const response = await Promise.all([
        forYouGroups,
        forYouCategories,
        forYouContentTypes
      ])

      commit('SET_GROUP', { group: response[0].groups })
      commit('SET_CATEGORIES', { categories: response[1].categories })
      commit('SET_CONTENT_TYPES', { contentTypes: response[2].contentTypes })

      commit('SET_LOADING', { loading: false })
    }
  }
}
