import { Blogs, Toast } from '@seliaco/red-panda'
import { CapitalMediaTypes, MediaTypes } from '@/constants/mediaTypes'

export const MediaContentStore = {
  namespaced: true,
  state: {
    featured: null,
    meditations: null,
    video_guides: null,
    blogs: null,
    loading: true,
    recommended: null
  },
  getters: {
    getBlogs (state) {
      return state.blogs
    },
    getFeatured (state) {
      return state.featured
    },
    getMeditations (state) {
      return state.meditations
    },
    getVideoGuides (state) {
      return state.video_guides
    },
    params (state) {
      return state.params
    },
    getLoading (state) {
      return state.loading
    },
    getRecommended (state) {
      return state.recommended
    }
  },
  mutations: {
    SET_BLOGS (state, payload) {
      let values = []

      if (state[payload.type]?.values) {
        const valid = state[payload.type].values.filter(value => {
          return payload.value.find(option => value.id === option.id)
        })
        if (valid.length < 1) {
          values = [...state[payload.type].values, ...payload.value]
        } else {
          values = [...state[payload.type].values]
        }
      } else {
        values = [...payload.value]
      }
      state[payload.type] = {
        values,
        count: payload.count
      }
    },
    SET_RECOMMENDED (state, { recommended }) {
      state.recommended = recommended
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit, state }, { category, params }) {
      let type = MediaTypes.BLOGS
      switch (category) {
        case CapitalMediaTypes.FEATURED:
          type = MediaTypes.FEATURED
          break
        case CapitalMediaTypes.MEDITATIONS:
          type = MediaTypes.MEDITATIONS
          break
        case CapitalMediaTypes.VIDEO_GUIDE:
          type = MediaTypes.VIDEO_GUIDE
          break
      }

      const blogs = await Blogs.getAll(params)
        .catch((error) => {
          Toast({
            severity: 'error',
            text: error.message
          })
        })

      commit('SET_BLOGS', { type, value: blogs.data, count: blogs.count })

      if (state.loading) {
        commit('SET_LOADING', { loading: false })
      }
    },
    async getRecommended ({ commit, state }) {
      if (!state.recommended) {
        commit('SET_LOADING', { loading: true })
      }

      const blogs = await Blogs.getRecommended()
        .catch((error) => {
          Toast({
            severity: 'error',
            text: error.message
          })
        })

      const recommendedBlogs = blogs.data

      commit('SET_RECOMMENDED', { recommended: recommendedBlogs })
      commit('SET_LOADING', { loading: false })
    }
  }
}
