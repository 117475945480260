<template>
  <DialogContainer
    :illustration="ilustrationName.matching_agent"
    @closeDialog="closeDialog()"
  >
    <template v-slot:content>
      <lottie-player
        class="questionnaire-lottie"
        src="https://igpedxmysqtwteudvnol.supabase.co/storage/v1/object/public/lottie/lotti_confetti.json"
        background="transparent"
        speed="1"
        autoplay
        loop
      ></lottie-player>
      <div class="heading margin-y">
        {{ $translations['suggested-specialists'].dialog.title }}
      </div>
      <div class="body margin-bottom-xl">
        {{ $translations['suggested-specialists'].dialog.subtitle }}
      </div>
      <Button
        @clicked="closeDialog"
        :text="$translations['suggested-specialists'].dialog.button"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { Button, DialogContainer, IlustrationName } from '@seliaco/red-panda'

export default {
  name: 'QuestionnaireDialog',
  components: { DialogContainer, Button },
  data () {
    return {
      ilustrationName: IlustrationName
    }
  },
  methods: {
    closeDialog () {
      this.$globalEvent.$emit('modal/questionnaire-result', {
        showDialog: { questionnaireResult: { open: false, close: true } }
      })
    }
  }
}
</script>
<style lang="sass">
.questionnaire-lottie
  transform: scale(3)
  position: absolute
  z-index: -1
</style>
