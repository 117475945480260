export const FlowStore = {
  namespaced: true,
  state: {
    matching: ''
  },
  getters: {
    matching (state) {
      if (!state.matching) {
        return ''
      }

      return JSON.parse(state.matching)
    }
  },
  mutations: {
    SET_MATCHING (state, payload) {
      state.matching = JSON.stringify(payload)
    },
    RESET_MATCHING (state) {
      state.matching = ''
    }
  }
}
