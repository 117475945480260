export const Origins = (translations, model = 'appointment', option = 'create') => {
  return {
    ADMIN: translations.origins.admin[model][option],
    USER: translations.origins.user[model][option],
    SPECIALIST: translations.origins.specialist[model][option]
  }
}

export const OriginEnums = {
  user: 'USER',
  admin: 'ADMIN',
  specialist: 'SPECIALIST'
}
