<template>
  <div class="shared-account-form">
    <InputTextField
      v-model="form$.name"
      :placeholder="placeholder.name"
      :label="placeholder.name"
    />
    <DropdownField
      v-model="form$.role"
      :options="options"
      :placeholder="placeholder.type"
      :label="placeholder.type"
    />
    <InputEmailField
      v-model="form$.email"
      :placeholder="placeholder.email"
      :label="placeholder.email"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  InputTextField,
  InputEmailField,
  DropdownField
} from '@seliaco/sea-otter'
export default {
  name: 'SharedAccountForm',
  components: { InputTextField, InputEmailField, DropdownField },
  props: {
    form: Object
  },
  data () {
    return {
      form$: {
        name: '',
        email: '',
        role: ''
      },
      placeholder: {
        ...this.$translations['shared-account'].form.placeholder
      }
    }
  },
  mounted () {
    if (this.form) {
      this.form$ = this.form
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      language: 'language'
    }),
    options () {
      return this.settings.sharedAccountOptions.map((option) => ({
        label: option.label[this.language.lang],
        value: option.value
      }))
    }
  },
  watch: {
    form$: {
      handler () {
        if (this.form$.role && this.form$.email && this.form$.name) {
          this.$emit('form', this.form$)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="sass" scoped>
.shared-account-form
  display: flex
  flex-direction: column
  gap: 8px
  text-align: left
</style>
