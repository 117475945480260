import {
  Settings,
  ServicesTypes,
  Specialities,
  CurrencyFilter,
  Approaches,
  SpecialistTypes
} from '@seliaco/red-panda'
import { ServiceTypes } from '@/constants/service-types'

const filterInit = {
  genders: null,
  service_types: null,
  price_range: null,
  speciality_list: null,
  modality: null,
  languages: null,
  countries: null,
  approaches: null,
  specialist_types: null
}

export const FiltersV1Store = {
  namespaced: true,

  state: {
    filters: { ...filterInit },
    type: '',
    options: { ...filterInit },
    loading: false
  },
  getters: {
    get (state) {
      return state.filters
    },
    length (state) {
      let length = 0

      for (const key in state.filters) {
        if (state.filters[key]) {
          length += state.filters[key].length
        }
      }

      return length || 0
    },
    getType (state) {
      return state.type
    },
    options (state) {
      return state.options
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_FILTERS (state, { type, value }) {
      state.filters = { ...state.filters, [type]: value }
    },
    SET_ALL_FILTERS (state, { filters }) {
      state.filters = { ...filters }
    },
    SET_FILTER_TYPE (state, { type }) {
      state.type = type
    },
    SET_FILTER_OPTIONS (state, { options }) {
      state.options = { ...options }
    },
    RESET_FILTER_TYPE (state) {
      state.type = ''
    },
    RESET_FILTERS (state) {
      state.filters = { ...filterInit }
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit, state }, payload) {
      if (!state.options?.genders || !state.options?.modality || !state.options?.languages || !state.options?.countries) {
        commit('SET_LOADING', { loading: true })
      }

      const optionsTemp = { ...filterInit }
      const { translations, country, currency, lang } = payload

      // gender
      optionsTemp.genders = [
        {
          name: translations.gender.male,
          value: 'MALE'
        },
        {
          name: translations.gender.female,
          value: 'FEMALE'
        }
      ]

      // modality
      optionsTemp.modality = [
        {
          name: translations.modality.online,
          value: 'VIRTUAL'
        },
        {
          name: translations.modality['face-to-face'],
          value: 'IN_PERSON'
        }
      ]

      // language
      await Settings.get('FILTER_LANGUAGE_CONFIG').then((languages) => {
        optionsTemp.languages =
          languages.parse_value.map((items) => ({
            name: items[`name_${lang}`],
            value: items.key
          })) || []
      })

      // country
      await Settings.get('FILTER_COUNTRY_CONFIG').then((countries) => {
        optionsTemp.countries =
          countries.parse_value.map((items) => ({
            name: items[`name_${lang}`],
            value: items.key
          })) || []
      })

      // price
      await Settings.get('PRICE_LEVEL_CONFIG').then((price) => {
        optionsTemp.price_range =
          price.parse_value[country].map(
            (item) => {
              const from = CurrencyFilter(
                item.from,
                currency
              )
              const to = CurrencyFilter(
                item.to,
                currency
              )

              const msg = item.to === '+' ? `${from} +` : `${from} - ${to}`
              return {
                name: msg,
                value: item
              }
            }
          ) || []
      })

      // services
      await ServicesTypes.read({
        columns: `value:id, name:name_${lang}, type`,
        neq: {
          type: 'ORIENTATION'
        }
      }).then((services) => {
        const servicesMapped = {}
        Object.keys(ServiceTypes).map(key => {
          const data = services.data.filter(val => val.type === key)
          if (data.length > 0) {
            servicesMapped[key] = services.data.filter(val => val.type === key)
          }
        })
        optionsTemp.service_types = servicesMapped || {}
      })

      // specialities
      await Specialities.read({
        columns: `value:id, name:name_${lang}`
      }).then((specialities) => {
        optionsTemp.speciality_list = specialities.data || []
      })

      // approaches
      await Approaches.get('key').then((approaches) => {
        optionsTemp.approaches = approaches.map((item) => ({
          name: item.name,
          value: item.key
        })) || []
      })

      // specialist_type
      await SpecialistTypes.list().then((specialistTypes) => {
        optionsTemp.specialist_types = specialistTypes.map((item) => ({
          name: item.name,
          value: item.code
        })) || []
      })

      commit('SET_FILTER_OPTIONS', { options: optionsTemp })
      commit('SET_LOADING', { loading: false })
    }
  }
}
