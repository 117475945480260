<template>
  <DialogContainer @closeDialog="handlerClose()">
    <template v-slot:content>
      <div class="low-cost-logo margin-top-sm">
        <img class="low-cost-logo-img" src="@/assets/logo.svg" alt="Selia" />
      </div>
      <div
        class="low-cost-title heading-small margin-y"
        v-text="$translations.dialogs['low-cost'].title"
      />

      <div
        class="low-cost-subtitle body margin-bottom-lg"
        v-text="$translations.dialogs['low-cost'].text"
      />

      <div class="low-cost-buttons">
        <Button
          type="outline"
          @clicked="handlerClose"
          :text="$translations.dialogs['low-cost'].exit"
        />
        <Button
          @clicked="goLowCostFlow"
          :text="$translations.dialogs['low-cost'].confirmation"
        />
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Button, Settings } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import { Browser } from '@capacitor/browser'

export default {
  name: 'LowCostModal',
  components: {
    DialogContainer,
    Button
  },
  methods: {
    handlerClose () {
      this.$globalEvent.$emit('modal/low-cost', {
        showDialog: { lowCostModal: { open: false, close: true } }
      })
    },
    goLowCostFlow () {
      Settings.get('SELIA_USERS_LOW_COST_FORM_URL')
        .then((value) => {
          const url = value.parse_value.replace('{USER_ID}', this.user.id)

          Browser.open({ url }).catch((e) => {
            this.$toast({
              text: e.message || this.$translations.error.default,
              severity: 'error'
            })
          })
        })
        .finally(() => {
          this.handlerClose()
        })
        .catch((e) => {
          this.$toast({
            text: e.message,
            severity: 'error'
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
<style lang="sass" scoped>
.low-cost
  &-logo
    align-self: center
    height: 72px
    width: 72px
    background: var(--purple-10)
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    &-img
      height: 40px
      width: 40px
  &-buttons
    display: flex
    gap: 16px
</style>
