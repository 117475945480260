import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace

Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch((error) => error)
}
Router.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch((error) => error)
}

Vue.use(Router)

const routes = [
  {
    path: '/welcome',
    name: 'WrapperAuth',
    meta: {
      title: 'Selia | Wrapper Auth',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "wrapper-auth" */ '../views/auth/WrapperAuth.vue'
      ),
    children: [
      {
        path: '/welcome',
        name: 'WelcomeSignIn',
        meta: {
          title: 'Selia | Welcome Sign In',
          requiresAuth: false
        },
        component: () =>
          import(
            /* webpackChunkName: "welcome-sign-in" */ '../views/auth/WelcomeSignIn.vue'
          ).then((c) => c.default)
      },
      {
        path: '/sign-in',
        name: 'SignIn',
        meta: {
          title: 'Selia | Sign In',
          requiresAuth: false
        },
        component: () =>
          import(
            /* webpackChunkName: "sign-in" */ '../views/auth/SignIn.vue'
          ).then((c) => c.default)
      },
      {
        path: '/sign-up',
        name: 'SignUp',
        meta: {
          title: 'Selia | Sign Up',
          requiresAuth: false
        },
        component: () =>
          import(
            /* webpackChunkName: "sign-up" */ '../views/auth/SignUp.vue'
          ).then((c) => c.default),
        props: (route) => ({
          email: route.params?.email || null
        })
      }
    ]
  },
  {
    path: '/onboarding/employee',
    name: 'EmployeeOnboarding',
    meta: {
      title: 'Selia | Employee Onboarding',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "employee-onboarding" */ '../views/onboarding/onboarding-employee.vue'
      ).then((c) => c.default)
  },
  {
    path: '/onboarding',
    name: 'SlideOnboarding',
    meta: {
      title: 'Selia | Onboarding',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "slider" */ '../views/onboarding/onboarding-slider.vue'
      ).then((c) => c.default)
  },
  {
    path: '/aftercall/:id',
    name: 'Aftercall',
    meta: {
      title: 'Selia | Aftercall',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "aftercall" */ '../views/aftercall/aftercall.vue'
      ).then((c) => c.default),
    props: (route) => ({
      appointmentId: route.params.id
    })
  },
  {
    path: '/aftercall/next-step/:id',
    name: 'AftercallNextStep',
    meta: {
      title: 'Selia | Aftercall',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "aftercall-next-step" */ '../views/aftercall/warranty-call/activation.vue'
      ).then((c) => c.default),
    props: (route) => ({
      appointmentId: route.params.id
    })
  },
  {
    path: '/warranty-call/',
    name: 'WarrantyCall',
    meta: {
      title: 'Selia | Warranty call',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "warranty-call" */ '../views/aftercall/warranty-call/active.vue'
      ).then((c) => c.default)
  },
  {
    path: '/onboarding/questionnaire',
    name: 'QuestionnaireOnboarding',
    meta: {
      title: 'Selia | Welcome',
      requiresAuth: true
    },
    props: (route) => ({
      advanced: route.params.advanced
    }),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-onboarding" */ '../views/onboarding/onboarding-container'
      ).then((c) => c.default)
  },
  {
    path: '/',
    meta: {
      title: 'Selia',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'Selia | Home',
          requiresAuth: true,
          OnboardingGuard: true
        },
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/home/Home.vue').then(
            (c) => c.default
          )
      },
      {
        path: '/blog',
        name: 'Blog',
        meta: {
          title: 'Selia | Blog',
          requiresAuth: true,
          OnboardingGuard: true
        },
        component: () =>
          import(
            /* webpackChunkName: "blog" */ '../views/blog/Videos.vue'
          ).then((c) => c.default)
      },
      {
        path: '/agenda',
        name: 'Agenda',
        meta: {
          title: 'Selia | Agenda',
          requiresAuth: true,
          OnboardingGuard: true
        },
        component: () =>
          import(
            /* webpackChunkName: "agenda" */ '../views/agenda/Agenda.vue'
          ).then((c) => c.default)
      },
      {
        path: '/search',
        name: 'Search',
        meta: {
          title: 'Selia | Search',
          requiresAuth: true,
          OnboardingGuard: true
        },
        component: () =>
          import(
            /* webpackChunkName: "search" */ '../views/specialists/Specialists.vue'
          ).then((c) => c.default)
      },
      {
        path: '/messages',
        name: 'Messages',
        meta: {
          title: 'Selia | Messages',
          requiresAuth: true,
          OnboardingGuard: true
        },
        component: () =>
          import(
            /* webpackChunkName: "messages" */ '../views/chat/Chat.vue'
          ).then((c) => c.default),
        props: (route) => ({
          members: route.query?.members ? JSON.parse(route.query.members) : null
        })
      },
      {
        path: '/for-you',
        name: 'ForYou',
        meta: {
          title: 'Selia | For You',
          requiresAuth: true,
          OnboardingGuard: true
        },
        component: () =>
          import(
            /* webpackChunkName: "for-you" */ '../views/for-you/ForYou.vue'
          ).then((c) => c.default)
      }
    ]
  },
  {
    path: '/specialists-search',
    name: 'SpecialistsSearch',
    meta: {
      title: 'Selia | Specialists Search',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialists-search" */ '../views/specialists/SpecialistsSearch.vue'
      ).then((c) => c.default)
  },
  {
    path: '/check-in',
    name: 'CheckIn',
    meta: {
      title: 'Selia | Check In',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "check-in" */ '../views/check-in/CheckIn.vue'
      ).then((c) => c.default)
  },
  {
    path: '/check-in/form',
    name: 'CheckInForm',
    meta: {
      title: 'Selia | Check In Form',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "check-in-form" */ '../views/check-in/CheckInForm.vue'
      ).then((c) => c.default),
    props: (route) => ({
      counterData: route.query.counterData
    })
  },
  {
    path: '/check-in/slider',
    name: 'SlideCheckIn',
    meta: {
      title: 'Selia | Check In',
      requiresAuth: true
    },
    props: (route) => ({
      origin: route.query.origin
    }),
    component: () =>
      import(
        /* webpackChunkName: "check-in-slide" */ '../views/check-in/CheckInSlider'
      ).then((c) => c.default)
  },
  {
    path: '/referrals',
    name: 'Referrals',
    meta: {
      title: 'Selia | Referrals',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "referrals" */ '../views/referrals/Referrals.vue'
      ).then((c) => c.default)
  },
  {
    path: '/diary',
    name: 'Diary',
    meta: {
      title: 'Selia | Diary',
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "diary" */ '../views/diary/Diary').then(
        (c) => c.default
      )
  },
  {
    path: '/diary-entry',
    name: 'DiaryEntry',
    meta: {
      title: 'Selia | Diary Entry',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "diary-entry" */ '../views/diary/DiaryEntry'
      ).then((c) => c.default)
  },
  {
    path: '/emotion/:id',
    name: 'EmotionDetail',
    meta: {
      title: 'Selia | Emotion Detail',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "emotion-detail" */ '../views/diary/EmotionDetail'
      ).then((c) => c.default)
  },
  {
    path: '/recommended',
    name: 'RecommendedCounselling',
    meta: {
      title: 'Selia | Recommended',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "recommended-counselling" */ '../views/recommended-specialist/RecommendedCounselling.vue'
      )
  },
  {
    path: '/recommended-specialist',
    name: 'RecommendedSpecialist',
    meta: {
      title: 'Selia | Recommended Specialist',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "recommended-specialist" */ '../views/recommended-specialist/Recommended-specialist.vue'
      )
  },
  {
    path: '/questionnaire/results',
    name: 'QuestionnaireResults',
    meta: {
      title: 'Selia | Questionnaire result',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "suggested-specialists" */ '../views/questionnaire/QuestionnaireResultsContainer.vue'
      ),
    props: (route) => ({
      form: route.query.form ? JSON.parse(route.query.form) : null,
      specialistsResult: route.query.specialistsResult
        ? JSON.parse(route.query.specialistsResult)
        : null,
      service: route.query.service || null,
      debugResult: route.query.debugResult ? JSON.parse(route.query.debugResult) : null
    })
  },
  {
    path: '/matching',
    name: 'QuestionnaireContainer',
    meta: {
      title: 'Selia | Questionnaire Welcome',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-container" */ '../views/questionnaire/QuestionnaireContainer.vue'
      )
  },
  {
    path: '/sessions/:id',
    name: 'Lobby',
    meta: {
      title: 'Selia | Session',
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "session" */ '../views/sessions/Session.vue')
  },
  {
    path: '/sessions/completed/:id',
    name: 'SessionCompleted',
    meta: {
      title: 'Selia | Session completed',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "session-completed" */ '../views/session-completed/SessionCompleted.vue'
      ),
    props: (route) => ({
      appointmentId: route.params.id
    })
  },
  {
    path: '/payments',
    name: 'PaymentsList',
    meta: {
      title: 'Selia | Payments',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-list" */ '../views/payments/PaymentList.vue'
      )
  },
  {
    path: '/appointments/:id',
    name: 'AppointmentDetails',
    meta: {
      title: 'Selia | Appointment Details',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "appointment-detail" */ '../views/appointments/Appointment.vue'
      ).then((c) => c.default)
  },
  {
    path: '/appointment/:id/review',
    name: 'AppointmentReview',
    meta: {
      title: 'Selia | Appointment Review',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "appointment-review" */ '../views/appointments/AppointmentReview.vue'
        ).then((c) => c.default)
  },
  {
    path: '/schedule',
    name: 'Schedule',
    meta: {
      title: 'Selia | Schedule Appointment',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "schedule" */ '../views/appointments/ScheduleWrapper.vue'
      ).then((c) => c.default)
  },
  {
    path: '/check-out/:type/:id',
    name: 'CheckOut',
    meta: {
      title: 'Selia | Check Out',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "check-out" */ '../views/check-out/CheckOut.vue'
      ).then((c) => c.default),
    props: (route) => {
      const queryParams = store.getters.query
      let couponCode = route.query.coupon || null

      if (!couponCode && queryParams?.coupon_code) {
        couponCode = queryParams.coupon_code
      }

      return {
        id: route.params.id,
        type: route.params.type,
        serviceType: route.query.serviceType,
        coupon: couponCode,
        specialistId: route.query.specialistId || null,
        quotaId: route.query.quotaId || null,
        showSuccess: JSON.parse(route.query.showSuccess || false),
        card: route.query.card || null
      }
    }
  },
  {
    path: '/add-card',
    name: 'AddCreditCard',
    meta: {
      title: 'Selia | Add Card',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "add-card" */ '../views/check-out/AddCreditCard.vue'
      ).then((c) => c.default),
    props: (route) => ({
      next: JSON.parse(route.query.next || null)
    })
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'Selia | Profile',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '../views/profile/Profile.vue'
      ).then((c) => c.default)
  },
  {
    path: '/specialists/:id',
    name: 'SpecialistDetail',
    meta: {
      title: 'Selia | Specialist Detail',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialist" */ '../views/specialist/SpecialistWrapper.vue'
      ).then((c) => c.default)
  },
  {
    path: '/specialist/:id/reviews',
    name: 'SpecialistReviews',
    meta: {
      title: 'Selia | Specialist Reviews',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialist-reviews" */ '../views/specialist/page/v2/Reviews'
      ).then((c) => c.default)
  },
  {
    path: '/specialist/:id/packages',
    name: 'SpecialistPackages',
    meta: {
      title: 'Selia | Specialist Packages',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialist-packages" */ '../views/specialist/page/v2/Packages'
      ).then((c) => c.default)
  },
  {
    path: '/my-specialists',
    name: 'MySpecialists',
    meta: {
      title: 'Selia | My Specialists',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "my-specialists" */ '../views/my-specialists/MySpecialists'
      ).then((c) => c.default)
  },
  {
    path: '/packages/:packageId/specialists',
    name: 'PackageSpecialists',
    meta: {
      title: 'Selia | Package Specialists',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "package-specialists" */ '../views/specialists/AvailableSpecialists'
      ).then((c) => c.default),
    props: (route) => ({
      packageId: route.params.packageId,
      preserveBackRoute: route.query.preserveBackRoute || false
    })
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      title: 'Selia | Forgot Password',
      requiresAuth: false,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ForgotPassword.vue'
      ).then((c) => c.default)
  },
  {
    path: '/forgot-password/verification',
    name: 'VerificationCode',
    meta: {
      title: 'Selia | Verification Code',
      requiresAuth: false,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/VerificationCode.vue'
      ).then((c) => c.default)
  },
  {
    path: '/forgot-password/change',
    name: 'ChangePassword',
    meta: {
      title: 'Selia | Change Password',
      requiresAuth: false,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ChangePassword.vue'
      ).then((c) => c.default)
  },
  {
    path: '/modify-password',
    name: 'ModifyPassword',
    meta: {
      title: 'Selia | Modify Password',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/auth/ModifyPassword.vue'
      ).then((c) => c.default)
  },
  {
    path: '/my-packages',
    name: 'MyPackages',
    meta: {
      title: 'Selia | My Packages',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "my-packages" */ '../views/promos/packages/MyPackages'
      ).then((c) => c.default)
  },
  {
    path: '/packages',
    name: 'Packages',
    meta: {
      title: 'Selia | Packages',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "packages" */ '../views/promos/packages/v2/Packages'
      ).then((c) => c.default),
    props: (route) => ({
      specialists: route.query.specialists
        ? JSON.parse(route.query.specialists)
        : null,
      origin: route.query.origin
    })
  },
  {
    path: '/service/packages',
    name: 'ServicePackages',
    meta: {
      title: 'Selia | Service Packages',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "service-packages" */ '../views/promos/packages/ServicePackages'
      ).then((c) => c.default)
  },
  {
    path: '/service/:id/packages',
    name: 'GenericPackages',
    meta: {
      title: 'Selia | Generic Packages',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "service-packages" */ '../views/promos/packages/GenericPackages'
      ).then((c) => c.default)
  },
  {
    path: '/generic/package/detail',
    name: 'GenericPackageDetail',
    meta: {
      title: 'Selia | Generic Package Detail',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "generic-package-detail" */ '../views/promos/packages/GenericPackageDetail'
      ).then((c) => c.default),
    props: (route) => ({
      packageData: route.query.packageData
        ? JSON.parse(route.query.packageData)
        : null,
      serviceName: route.query.serviceName || null
    })
  },
  {
    path: '/business-benefit',
    name: 'Business',
    meta: {
      title: 'Selia | Business selia',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "business-benefit" */ '../views/business/Business.vue'
      ).then((c) => c.default)
  },
  {
    path: '/settings/notifications',
    name: 'NotificationSettings',
    meta: {
      title: 'Selia | Notifications settings',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications-settings" */ '../views/settings/NotificationSettingsContainer.vue'
      ).then((c) => c.default)
  },
  {
    path: '/settings/developer',
    name: 'DeveloperSettings',
    meta: {
      title: 'Selia | Developer settings',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "developer-settings" */ '../views/settings/DeveloperSettingsContainer.vue'
      ).then((c) => c.default)
  },
  {
    path: '/settings/localization',
    name: 'LocalizationSettings',
    meta: {
      title: 'Selia | Localization Settings',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "localization-settings" */ '../views/settings/LocalizationSettingsContainer.vue'
      ).then((c) => c.default)
  },
  {
    path: '/meditations',
    name: 'Meditations',
    meta: {
      title: 'Selia | Meditations',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "meditations" */ '../views/meditations/Meditations.vue'
      ).then((c) => c.default)
  },
  {
    path: '/conferences',
    name: 'Conferences',
    meta: {
      title: 'Selia | Conferences',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "conferences" */ '../views/conferences/Conferences.vue'
      ).then((c) => c.default)
  },
  {
    path: '/conference/:id',
    name: 'ConferenceDetail',
    meta: {
      title: 'Selia | Conference detail',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "conference-detail" */ '../views/conferences/ConferenceDetail.vue'
      ).then((c) => c.default)
  },
  {
    path: '/programs',
    name: 'Programs',
    meta: {
      title: 'Selia | Programs',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "programs" */ '../views/programs/Programs.vue'
      ).then((c) => c.default)
  },
  {
    path: '/program/:id',
    name: 'ProgramDetail',
    meta: {
      title: 'Selia | Program detail',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "program-detail" */ '../views/programs/ProgramDetail.vue'
      ).then((c) => c.default)
  },
  {
    path: '/specialist/list',
    name: 'SpecialistsList',
    meta: {
      title: 'Selia | Specialist List',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "specialists-list" */ '../views/specialists/SpecialistsList.vue'
      ).then((c) => c.default),
    props: (route) => ({
      specialists: route.query.specialists
        ? JSON.parse(route.query.specialists)
        : null,
      title: route.query.title || null,
      source: route.query.specialistSource
        ? { ...JSON.parse(route.query.specialistSource) }
        : null
    })
  },
  {
    path: '/for-you/search',
    name: 'ForYouSearch',
    meta: {
      title: 'Selia | For You Search',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "for-you-search" */ '../views/for-you/ForYouSearch.vue'
      ).then((c) => c.default)
  },
  {
    path: '/for-you/:slug',
    name: 'ForYouEntryDetail',
    meta: {
      title: 'Selia | For You Entry Detail',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "for-you-entry-detail" */ '../views/for-you/ForYouEntryDetail.vue'
      ).then((c) => c.default)
  },
  {
    path: '/for-you/group/:group',
    name: 'ForYouGroup',
    meta: {
      title: 'Selia | For You Group',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "for-you-group" */ '../views/for-you/ForYouGroup.vue'
      ).then((c) => c.default)
  },
  {
    path: '/for-you/category/:category',
    name: 'ForYouCategory',
    meta: {
      title: 'Selia | For You Category',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "for-you-category" */ '../views/for-you/ForYouCategory.vue'
      ).then((c) => c.default)
  },
  {
    path: '/for-you/category/:category/:group',
    name: 'ForYouCategoryGroup',
    meta: {
      title: 'Selia | For You Category',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "for-you-category" */ '../views/for-you/ForYouCategory.vue'
      ).then((c) => c.default)
  },
  {
    path: '/entry/:id',
    name: 'Entry',
    meta: {
      title: 'Selia | Entry',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(/* webpackChunkName: "entry" */ '../views/blog/Entry.vue').then(
        (c) => c.default
      )
  },
  {
    path: '/account/delete',
    name: 'DeleteAccount',
    meta: {
      title: 'Selia | Delete account',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "delete-account" */ '../views/account/DeleteAccount'
      ).then((c) => c.default)
  },
  {
    path: '/flow/:flowKey?',
    name: 'FlowReader',
    meta: {
      title: 'Selia | Flow',
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "flow" */ '../views/flow/FlowReader.vue'
      ).then((c) => c.default)
  },
  {
    path: '/contract/specialist/:specialistId',
    name: 'InformedConsent',
    meta: {
      title: 'Selia | Informed Consent',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "informed-consent" */ '../views/contracts/InformedConsent.vue'
      ).then((c) => c.default)
  },
  {
    path: '/document/:key',
    name: 'Document',
    meta: {
      title: 'Selia | Document',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "document" */ '../views/appointments/pages/Document.vue'
      ).then((c) => c.default)
  },
  {
    path: '/shared-accounts',
    name: 'SharedAccounts',
    meta: {
      title: 'Selia | SharedAccounts',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "document" */ '../views/shared-accounts/SharedAccounts.vue'
      ).then((c) => c.default)
  },
  {
    path: '/shared-account/:id',
    name: 'SharedAccountDetail',
    meta: {
      title: 'Selia | SharedAccountDetail',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "document" */ '../views/shared-accounts/SharedAccountDetail.vue'
      ).then((c) => c.default)
  },
  {
    path: '/shared-account/invitation',
    name: 'SharedAccountInvitation',
    meta: {
      title: 'Selia | SharedAccountInvitation',
      requiresAuth: true,
      OnboardingGuard: true
    },
    component: () =>
      import(
        /* webpackChunkName: "document" */ '../views/shared-accounts/SharedAccountInvitation.vue'
      ).then((c) => c.default)
  },
  {
    path: '/form',
    name: 'Typeform',
    meta: {
      title: 'Selia | Forms',
      requiresAuth: false
    },
    component: () =>
      import(
        /* webpackChunkName: "sign-up" */ '../views/forms/Typeform.vue'
      ).then((c) => c.default)
  },
  // Error 404, must be at the end!
  {
    path: '/:patgMatch(.*)*',
    name: '404',
    meta: {
      title: 'Selia | 404',
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "404" */ '../views/auth/404.vue').then(
        (c) => c.default
      )
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    if (this.currentRoute.name.includes('ForYou')) {
      return
    }
    if (
      this.currentRoute.query.disableScroll ||
      (this.currentRoute.name.includes('Home') && this.currentRoute.query.slug)
    ) {
      return
    }

    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

// This callback runs before every route change, including on page load.
const updateAppMeta = (to, from) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))
}

const handleAuthentication = (to, from, next) => {
  const isAuthenticated = Boolean(
    localStorage.getItem(
      `selia_${process.env.VUE_APP_AUTH_MODE.toLowerCase()}_access_token`
    )
  )

  let query = null

  if (to.query) {
    query = to.query
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    if (to.path.length > 1) {
      query = {
        ...query,
        next: to.path
      }
    }

    if (localStorage.getItem('FirstLoginSuccess')) {
      next({
        name: 'SignIn',
        query
      })
    } else {
      next({
        name: 'WelcomeSignIn',
        query
      })
    }
  } else if (isAuthenticated && to.path === '/sign-in') {
    next({
      name: 'Home',
      query: { ...query }
    })
  } else {
    next()
  }
}

const handleOnboarding = (to, from, next) => {
  const userStatus =
    store.getters['auth/user'] && store.getters['auth/user'].status

  if (!userStatus) {
    return
  }

  const isOnboardingComplete = !(userStatus.search('ON_BOARDING') !== -1)
  let query = null

  if (to.fullPath) {
    query = {
      next: to.fullPath
    }
  }

  if (to.meta.OnboardingGuard && !isOnboardingComplete) {
    next({
      name: 'QuestionnaireOnboarding',
      query
    })
  } else {
    next()
  }
}

router.beforeEach(async (to, from, next) => {
  updateAppMeta(to, from)
  handleAuthentication(to, from, next)
  handleOnboarding(to, from, next)
})

export default router
