import Vue from 'vue'
import Vuex from 'vuex'
import {
  AuthStore,
  getClientTimezone,
  SectionStore,
  UpcomingAppointments,
  Localization,
  Toast,
  NotificationsStore
} from '@seliaco/red-panda'
import {
  ConferencesStore,
  FiltersV1Store,
  FiltersV2Store,
  GrowthBookStore,
  MediaContentStore,
  MyAppointmentsStore,
  ServicesStore,
  SettingsStore,
  FlowStore,
  ProfileStore,
  MyPackagesStore,
  HomeStore,
  SpecialistByNameStore,
  ProgramsStore,
  TimerStore,
  ForYouStore,
  SharedAccountStore,
  SpecialistProfileServiceStore,
  ChatStore
} from './modules'
import translationsFile from '@/assets/translations.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    platform: null,
    query: {},
    language: {
      full: null,
      lang: null
    },
    form: null,
    specialistAppointmentCountSetting: null,
    nextAppointment: {
      appointment: null,
      specialist: null
    },
    deviceBackButtonCallback: null
  },
  getters: {
    platform (state) {
      return state.platform
    },
    language (state) {
      return state.language
    },
    form (state) {
      return state.form
    },
    specialistAppointmentCountSetting (state) {
      return state.specialistAppointmentCountSetting
    },
    query (state) {
      return state.query
    },
    userTimezone (state) {
      if (state.auth.user.time_zone) {
        return state.auth.user.time_zone
      } else {
        return getClientTimezone()
      }
    },
    nextAppointment (state) {
      return {
        ...state.nextAppointment
      }
    },
    deviceBackButtonCallback (state) {
      return state.deviceBackButtonCallback
    }
  },
  mutations: {
    UPDATE_PLAFORM (state, payload) {
      state.platform = payload
    },
    UPDATE_LANGUAGE_CODE (state, payload) {
      state.language = {
        full: payload.full,
        lang: payload.lang
      }
    },
    UPDATE_QUERY (state, payload) {
      state.query = payload
    },
    UPDATE_FORM (state, payload) {
      state.form = payload
    },
    UPDATE_SPECIALIST_APPOINTMENT_COUNT_SETTING (state, payload) {
      state.specialistAppointmentCountSetting = payload
    },
    UPDATE_NEXT_APPOINTMENT (state, payload) {
      state.nextAppointment = { ...payload }
    },
    UPDATE_DEVICE_BACK_BUTTON_CALLBACK (state, payload) {
      state.deviceBackButtonCallback = payload
    },
    UPDATE_USER_CURRENCY (state, payload) {
      state.auth.user.currency = payload.currency
    }
  },
  actions: {
    async setNextAppointment ({ commit, rootState }) {
      const lang = rootState.language.lang

      const nextAppointmentData = await UpcomingAppointments.getMyAppointments(
        'USER'
      ).catch(() => {
        Toast({
          text: translationsFile[lang].error.schedule,
          severity: 'error'
        })
      })

      let appointment = null

      if (nextAppointmentData?.appointment) {
        appointment = {
          ...nextAppointmentData.appointment,
          service: nextAppointmentData.appointment.specialist_service
        }
        delete appointment.specialist_service
      }

      const specialist = nextAppointmentData.specialist

      commit('UPDATE_NEXT_APPOINTMENT', {
        appointment,
        specialist
      })
    },
    setLocalization ({ commit, state }) {
      Localization.get().then((response) => {
        commit('UPDATE_USER_CURRENCY', { currency: response.currency.value })
        commit('UPDATE_LANGUAGE_CODE', {
          lang: response.language.value,
          full: state.language.full
        })
      })
    }
  },
  modules: {
    auth: AuthStore,
    section: SectionStore,
    filters: FiltersV1Store,
    filtersV2: FiltersV2Store,
    specialistByNameStore: SpecialistByNameStore,
    mediaContent: MediaContentStore,
    myAppointments: MyAppointmentsStore,
    conferences: ConferencesStore,
    services: ServicesStore,
    growthBook: GrowthBookStore,
    settings: SettingsStore,
    flow: FlowStore,
    profile: ProfileStore,
    myPackages: MyPackagesStore,
    dynamicHome: HomeStore,
    programs: ProgramsStore,
    timer: TimerStore,
    forYou: ForYouStore,
    notifications: NotificationsStore,
    sharedAccount: SharedAccountStore,
    specialistProfileService: SpecialistProfileServiceStore,
    chat: ChatStore
  }
})
