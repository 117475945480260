export default {
  methods: {
    /**
     * Methods
     */
    handleMatchingNavigation () {
      this.$store.commit('flow/RESET_MATCHING')
      this.$router.push({
        name: 'QuestionnaireContainer'
      })
        .then(() => null)
    }
  }
}
