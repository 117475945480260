import { Patients, Toast } from '@seliaco/red-panda'
import translationsFile from '@/assets/translations.json'

export const ProfileStore = {
  namespaced: true,

  state: {
    patient: null,
    loading: true
  },
  getters: {
    getProfile (state) {
      return state.patient
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_PROFILE (state, { patient }) {
      state.patient = patient
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit, state, rootState }, { user }) {
      const lang = rootState.language.lang

      const errorMessage = translationsFile[lang].error['profile-detail']

      if (!state.patient) {
        commit('SET_LOADING', {
          loading: true
        })
      }
      const patient = await Patients.read({
        columns: `
          id,
          first_name,
          last_name,
          birthday,
          gender,
          profile_picture,
          dial_code,
          phone,
          email,
          emergency_contact (
            id,
            display_name,
            phone,
            country
          ),
          address (
            country,
            city
          ),
          profile_picture,
          dial_country_code
        `,
        eq: {
          id: user.id
        }
      })

      if (!patient) {
        Toast({
          severity: 'error',
          life: 2000,
          closable: false,
          detail: errorMessage
        })
        return
      }

      commit('SET_PROFILE', {
        patient: patient?.data[0] || null
      })
      commit('SET_LOADING', {
        loading: false
      })
    }
  }
}
