<template>
  <DialogContainer
    :title="config.title"
    icon="icon-appointment-on text-red"
    contentIconBackground="bg-red-20"
    @closeDialog="closeModal"
  >
    <template v-slot:content>
      <div class="heading-mate margin-top">
        {{ config.description }}
      </div>
      <Button
        class="margin-top"
        v-for="button in buttons"
        :key="button.key"
        :text="button.text"
        :type="button.type"
        :color="button.color"
        @clicked="hdlClickFindSpecialistOption(button.key)"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Button } from '@seliaco/red-panda'

export default {
  name: 'ChangeDateModal',
  components: {
    DialogContainer,
    Button
  },
  props: {
    changeDate: Function
  },
  data () {
    const config = {
      title: this.$translations['appointment-pages']['error-date'].title,
      description:
        this.$translations['appointment-pages']['error-date'].description
    }

    const buttons = [
      {
        key: 'changeDate',
        text: this.$translations['appointment-pages'].buttons['choose-another'],
        type: 'block'
      },
      {
        key: 'close',
        text: this.$translations['appointment-pages'].buttons.close,
        type: 'outline',
        color: 'gray'
      }
    ]

    return {
      config,
      buttons
    }
  },
  methods: {
    hdlClickFindSpecialistOption (key) {
      if (key === 'changeDate') {
        this.changeDate()
      }

      this.closeModal()
    },
    closeModal () {
      this.$globalEvent.$emit('modal/change-date', {
        showDialog: { ChangeDate: { open: false, close: true } }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.heading-mate
  text-align: center
</style>
