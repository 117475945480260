<template>
  <div>
    <template v-if="showDialog.reschedule.open && !showDialog.reschedule.close">
      <RescheduleDialog
        :appointmentId="appointment.id"
        :reschedule="reschedule"
        :lang="lang"
      />
    </template>

    <template v-if="showDialog.cancel.open && !showDialog.cancel.close">
      <CancelDialog
        :appointment="appointment"
        :appointmentId="appointment.id"
        :service-type="appointment.serviceType"
        :show-options="showOptions"
        :redirect="appointment.redirect"
        :router="$router"
        :canceled_by="user.id"
        :origin="originEnums.user"
        :lang="lang"
        :country="user.country"
        @showLowCost="handleLowCost"
        @cancel="handlerCancel"
      />
    </template>

    <template
      v-if="
        showDialog.changeSpecialist.open && !showDialog.changeSpecialist.close
      "
    >
      <ChangeSpecialist
        :orientation="matching"
        :specialist="specialist"
        :lang="lang"
      />
    </template>

    <template
      v-if="
        showDialog.bussinessBenefit.open && !showDialog.bussinessBenefit.close
      "
    >
      <BussinessDialog />
    </template>

    <template
      v-if="showDialog.confirmClose.open && !showDialog.confirmClose.close"
    >
      <ConfirmCloseScheduleModal :closeAppointment="closeAppointment" />
    </template>

    <template v-if="showDialog.changeDate.open && !showDialog.changeDate.close">
      <ChangeDateModal :changeDate="showDialog.changeDate.callback" />
    </template>

    <template v-if="showDialog.survey.open && !showDialog.survey.close">
      <SurveyDialog
        :config="surveyConfig"
        :button-text="$translations.buttons.close"
        :lang="lang"
      />
    </template>

    <template v-if="showDialog.start.open && !showDialog.start.close">
      <StartModal />
    </template>

    <template v-if="showDialog.schedule.open && !showDialog.schedule.close">
      <ScheduleModal />
    </template>

    <template
      v-if="
        showDialog.questionnaireResult.open &&
        !showDialog.questionnaireResult.close
      "
    >
      <QuestionnaireDialog />
    </template>

    <template
      v-if="
        showDialog.dontLikeSpecialists.open &&
        !showDialog.dontLikeSpecialists.close
      "
    >
      <DontLikeSpecialistsDialog />
    </template>

    <template
      v-if="
        showDialog.dontFindSpecialists.open &&
        !showDialog.dontFindSpecialists.close
      "
    >
      <DontFindSpecialistsDialog />
    </template>

    <template
      v-if="showDialog.signUpSuccess.open && !showDialog.signUpSuccess.close"
    >
      <SignUpSuccessDialog :flowkey="showDialog.signUpSuccess.flowkey" />
    </template>

    <template
      v-if="
        showDialog.aftercallSurvey.open && !showDialog.aftercallSurvey.close
      "
    >
      <AftercallSurveyDialog :appointmentId="appointment.id" />
    </template>

    <template
      v-if="
        showDialog.flowModal.open &&
        !showDialog.flowModal.close &&
        showDialog.flowModal.data.title
      "
    >
      <SuccessDialogFlow
        :img="showDialog.flowModal.data.img"
        :title="showDialog.flowModal.data.title[lang]"
        :subtitle="showDialog.flowModal.data.subtitle[lang]"
        :button="showDialog.flowModal.data.button[lang]"
      />
    </template>

    <template
      v-if="showDialog.lowCostModal.open && !showDialog.lowCostModal.close"
    >
      <LowCostModal />
    </template>

    <template
      v-if="showDialog.checkInModal.open && !showDialog.checkInModal.close"
    >
      <CheckInDialog />
    </template>

    <template v-if="showDialog.timezone.open && !showDialog.timezone.close">
      <TimezoneDialog />
    </template>

    <template
      v-if="
        showDialog.sharedAccountStartModal.open &&
        !showDialog.sharedAccountStartModal.close
      "
    >
      <SharedAccountStartModal
        :invitation="showDialog.sharedAccountStartModal.invitation"
      />
    </template>

    <template
      v-if="
        showDialog.sharedAccountRemoveModal.open &&
        !showDialog.sharedAccountRemoveModal.close
      "
    >
      <SharedAccountRemoveModal
        :type="showDialog.sharedAccountRemoveModal.type"
        :member="showDialog.sharedAccountRemoveModal.member"
      />
    </template>
  </div>
</template>

<script>
import {
  RescheduleDialog,
  CancelDialog,
  ChangeSpecialist,
  SurveyDialog,
  SuccessDialogFlow
} from '@seliaco/red-panda'

import BussinessDialog from './BussinessDialog'
import TimezoneDialog from './TimezoneDialog'
import ConfirmCloseScheduleModal from '@/views/appointments/modal/ConfirmCloseScheduleModal'
import ChangeDateModal from '@/views/appointments/modal/ChangeDateModal'
import StartModal from '@/components/dialogs/StartModal'
import ScheduleModal from '@/components/dialogs/ScheduleModal'
import QuestionnaireDialog from '@/components/dialogs/QuestionnaireDialog'
import AftercallSurveyDialog from '@/components/dialogs/AftercallSurveyDialog'
import LowCostModal from '@/components/dialogs/LowCostModal'
import CheckInDialog from '@/components/dialogs/CheckInDialog'

import { mapGetters } from 'vuex'
import { OriginEnums } from '@/types/origins'
import {
  BookingAndAppointmentDetailEvent,
  AppointmentFlowEvent
} from '@/types/events'
import DontLikeSpecialistsDialog from '@/components/dialogs/DontLikeSpecialistsDialog'
import DontFindSpecialistsDialog from '@/components/dialogs/DontFindSpecialistsDialog'
import SignUpSuccessDialog from '@/components/dialogs/SignUpSuccessDialog'
import SharedAccountStartModal from '@/views/shared-accounts/modals/SharedAccountStartModal'
import SharedAccountRemoveModal from '@/views/shared-accounts/modals/SharedAccountRemoveModal'

import NavigateMatchingResetMixin from '@/mixins/navigate-matching-reset-mixin'
export default {
  name: 'GlobalDialog',
  components: {
    SignUpSuccessDialog,
    DontLikeSpecialistsDialog,
    DontFindSpecialistsDialog,
    RescheduleDialog,
    CancelDialog,
    ChangeSpecialist,
    BussinessDialog,
    TimezoneDialog,
    ConfirmCloseScheduleModal,
    ChangeDateModal,
    SurveyDialog,
    StartModal,
    ScheduleModal,
    QuestionnaireDialog,
    AftercallSurveyDialog,
    SuccessDialogFlow,
    LowCostModal,
    CheckInDialog,
    SharedAccountStartModal,
    SharedAccountRemoveModal
  },
  props: {
    appointment: Object,
    showDialog: Object,
    surveyConfig: Object
  },
  mixins: [NavigateMatchingResetMixin],
  data () {
    return {
      cancelLoading: false,
      originEnums: OriginEnums,
      lang: this.$store.getters.language.lang
    }
  },
  methods: {
    handleLowCost () {
      this.$globalEvent.$emit('modal/low-cost', {
        showDialog: { lowCostModal: { open: true, close: false } }
      })
    },
    reschedule () {
      let query = {
        back: this.$route.fullPath,
        appointmentId: this.appointment.id
      }

      if (this.$route.query.origin === 'shared-account') {
        query = {
          ...query,
          origin: this.$route.query.origin
        }
      }

      this.$router.push({
        name: 'Schedule',
        query: {
          ...query
        }
      })
    },
    matching () {
      this.handleMatchingNavigation()
      this.$segment.track(BookingAndAppointmentDetailEvent.select_beginnow)
    },
    specialist () {
      this.$router.push('/search?on_focus=true')
    },
    closeAppointment () {
      AppointmentFlowEvent.Appointment_Select_Leave_Flow({
        body: { user: this.$store.getters['auth/user'] }
      })
      if (this.$route.query.back) {
        this.$router.replace({
          path: this.$route.query.back
        })
      } else {
        this.$router.go(-1)
      }
    },
    handlerCancel () {
      this.$store.dispatch('timer/clearTimer')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    showOptions () {
      return (
        this.appointment.serviceType !== 'ORIENTATION' ||
        this.appointment.service.service_type.type !== 'ORIENTATION'
      )
    }
  }
}
</script>
