export const TransformUser = (user) => ({
  email: user.email,
  firstName: user.first_name,
  lastName: user.last_name,
  gender: user.gender,
  phone: user.phone,
  dialCode: user.dial_code,
  user_id: user.id,
  id: user.id,
  birthday: user.birthday
})

export const MapperOnboarding = (body) => ({
  user_id: body.user.id,
  email: body.user.email,
  marketing_channel: body.user.firebase_uid,
  platform: body.plaftorm,
  app_version: body.version
})
