<template>
  <DialogContainer
    :illustration="illustrationName.matching_agent"
    @closeDialog="closeDialog('close')"
  >
    <template v-slot:content>
      <div
        class="heading margin-y"
        v-text="$translations['suggested-specialists']['dont-find'].title"
      />
      <MediumCard
        class="cards"
        @clicked="navigateTo(option)"
        v-for="(option, index) in config"
        :key="index"
        :illustration="option.illustration"
        :title="option.title"
        :bottomLine="option.bottomLine"
        :showAction="option.showAction"
      />
      <Button
        @clicked="closeDialog('close')"
        :text="$translations['suggested-specialists'].dialog.close"
        type="outline"
        color="gray"
        class="margin-top"
      />
    </template>
  </DialogContainer>
</template>

<script>
import {
  Button,
  DialogContainer,
  IlustrationName,
  MediumCard,
  RoleType
} from '@seliaco/red-panda'
import { MatchingEvent } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'DontFindSpecialistsDialog',
  components: { DialogContainer, Button, MediumCard },
  data () {
    const config = [
      {
        illustration: IlustrationName.women_thinking_illustration,
        title:
          this.$translations['suggested-specialists']['dont-find'].cards[0]
            .title,
        bottomLine:
          this.$translations['suggested-specialists']['dont-find'].cards[0]
            .subtitle,
        showAction: false,
        routeName: 'RecommendedCounselling',
        key: 'ORIENTATION'
      },
      {
        illustration: IlustrationName.checklist_illustration,
        title:
          this.$translations['suggested-specialists']['dont-find'].cards[1]
            .title,
        bottomLine:
          this.$translations['suggested-specialists']['dont-find'].cards[1]
            .subtitle,
        showAction: false,
        routeName: 'QuestionnaireContainer',
        key: 'EDIT_QUESTIONNAIRE',
        callback: () => this.$store.commit('flow/RESET_MATCHING')
      },
      {
        illustration: IlustrationName.search_all_illustration,
        title:
          this.$translations['suggested-specialists']['dont-find'].cards[2]
            .title,
        bottomLine:
          this.$translations['suggested-specialists']['dont-find'].cards[2]
            .subtitle,
        showAction: false,
        routeName: 'Search',
        key: 'SPECIALIST_FILTER'
      }
    ]

    return {
      illustrationName: IlustrationName,
      config
    }
  },
  created () {
    if (this.role === RoleType.USER_HIDE_PRICES) {
      this.config = this.config.slice(-2)
    }
  },
  methods: {
    closeDialog (event) {
      if (event) {
        this.callSegmentEvent(event)
      }
      this.$globalEvent.$emit('modal/dont-find-specialists', {
        showDialog: { dontFindSpecialists: { open: false, close: true } }
      })
    },
    navigateTo (option) {
      if (option.callback) {
        option.callback()
      }
      this.callSegmentEvent(option.key)
      this.closeDialog()
      this.$router.push({ name: option.routeName })
    },
    callSegmentEvent (option) {
      const body = {
        type: 'Other matchings',
        option: option
      }
      MatchingEvent.matchingSeeOthersClick(body)
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role'
    })
  }
}
</script>

<style lang="sass" scoped>
.cards
  gap: 8px
</style>
