import { SpecialistProfile } from '@seliaco/red-panda'
export const SpecialistProfileServiceStore = {
  namespaced: true,
  state: {
    service: [],
    hasInPersonService: false,
    loading: false
  },
  getters: {
    service (state) {
      return state.service
    },
    hasInPersonService (state) {
      return state.hasInPersonService
    },
    loading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_SERVICE (state, { service }) {
      state.service = service
    },
    SET_HAS_IN_PERSON_SERVICE (state, { hasInPersonService }) {
      state.hasInPersonService = hasInPersonService
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async get ({ commit }, { id }) {
      commit('SET_LOADING', { loading: true })

      const services = await SpecialistProfile.getServices(id)

      const inpersonService = services.options.filter(
        (item) => item.kalendme_in_person_link_id
      )

      commit('SET_SERVICE', { service: services.options })
      commit('SET_HAS_IN_PERSON_SERVICE', {
        hasInPersonService: Boolean(inpersonService.length > 0)
      })

      commit('SET_LOADING', { loading: false })
    },
    reset ({ commit }) {
      commit('SET_SERVICE', { service: [] })
      commit('SET_HAS_IN_PERSON_SERVICE', { hasInPersonService: false })
    }
  }
}
