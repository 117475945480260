import { SharedAccount } from '@seliaco/red-panda'

export const SharedAccountStore = {
  namespaced: true,

  state: {
    sharedAccountId: null,
    members: [],
    member: null,
    user: null,
    loading: false
  },
  getters: {
    sharedAccountId (state) {
      return state.sharedAccountId
    },
    members (state) {
      return state.members
    },
    member (state) {
      return state.member
    },
    user (state) {
      return state.user
    },
    loading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_SHARED_ACCOUNT_ID (state, { sharedAccountId }) {
      state.sharedAccountId = sharedAccountId
    },
    SET_MEMBERS (state, { members }) {
      state.members = members
    },
    SET_MEMBER (state, { member }) {
      state.member = member
    },
    SET_USER (state, { user }) {
      state.user = user
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async getAccount ({ commit, dispatch }) {
      commit('SET_LOADING', { loading: true })

      const sharedAccount = await SharedAccount.getAccount()

      if (sharedAccount?.id) {
        commit('SET_SHARED_ACCOUNT_ID', { sharedAccountId: sharedAccount.id })
        dispatch('list')
      }
    },
    async createAccount ({ commit }) {
      const sharedAccountId = await SharedAccount.create()
      commit('SET_SHARED_ACCOUNT_ID', {
        sharedAccountId: sharedAccountId.shared_account
      })
    },
    async deleteAccount ({ commit, state }) {
      await SharedAccount.deleteAccount(state.sharedAccountId)
      commit('SET_SHARED_ACCOUNT_ID', { sharedAccountId: null })
    },
    async activation (
      { dispatch, commit },
      { sharedAccountId, sharedAccountMemberId }
    ) {
      if (sharedAccountId && sharedAccountMemberId) {
        await SharedAccount.activateMember(
          sharedAccountId,
          sharedAccountMemberId
        )
        commit('SET_SHARED_ACCOUNT_ID', { sharedAccountId })
        dispatch('list')
      } else {
        dispatch('getAccount')
      }
    },
    async list ({ commit, state, rootState }) {
      const members = await SharedAccount.list(state.sharedAccountId)
      const user = members.find(
        (member) => member.meta?.id === rootState.auth.user.id
      )
      commit('SET_USER', { user })
      commit('SET_MEMBERS', { members })
      commit('SET_LOADING', { loading: false })
    },
    async add ({ commit, dispatch, state }, props) {
      commit('SET_LOADING', { loading: true })
      await SharedAccount.addMember(props, state.sharedAccountId)
      dispatch('list')
    },
    async remove ({ commit, dispatch, state }, { sharedAccountMemberId }) {
      commit('SET_LOADING', { loading: true })
      await SharedAccount.removeMember(
        state.sharedAccountId,
        sharedAccountMemberId
      )
      dispatch('list')
    },
    async update ({ commit, state, dispatch }, { sharedAccountMemberId, body }) {
      commit('SET_LOADING', { loading: true })
      await SharedAccount.updateInvitation(
        state.sharedAccountId,
        sharedAccountMemberId,
        body
      )
      dispatch('list')
    },
    async getMember ({ commit, state }, { sharedAccountMemberId }) {
      commit('SET_LOADING', { loading: true })

      const member = await SharedAccount.getMember(
        state.sharedAccountId,
        sharedAccountMemberId
      )
      commit('SET_MEMBER', { member })

      commit('SET_LOADING', { loading: false })
    }
  }
}
