export const mapResponseData = (response, lang) => {
  return response.map((element) => {
    const name = element[`name_${lang}`]
    const subtitle = element.meta[`subtitle_${lang}`]
    const modality = element.meta[`modality_${lang}`]
    const duration = element.meta[`duration_${lang}`]

    return {
      id: element.id,
      price: element.price,
      currency: element.product_packages[0].price.currency,
      subtitle: name,
      description: subtitle,
      discount: element.discount,
      duration,
      modality,
      image: element.meta.image
    }
  })
}
