import { Home, Toast } from '@seliaco/red-panda'

export const HomeStore = {
  namespaced: true,
  state: {
    homeData: null,
    loading: true
  },
  getters: {
    getHomeData (state) {
      return state.homeData
    },
    getLoading (state) {
      return state.loading
    }
  },
  mutations: {
    SET_HOME_DATA (state, { homeData }) {
      state.homeData = homeData
    },
    SET_LOADING (state, { loading }) {
      state.loading = loading
    }
  },
  actions: {
    async getHomeData ({ commit, state }) {
      if (!state.homeData) {
        commit('SET_LOADING', { loading: true })
      }
      const homeData = await Home.getConfig()
        .catch((error) => {
          Toast({
            text: error.message,
            severity: 'error'
          })
        })

      commit('SET_HOME_DATA', { homeData })
      commit('SET_LOADING', { loading: false })
    }
  }
}
