export const ChatStore = {
  namespaced: true,
  state: {
    unreadMessages: 0,
    newMessage: null
  },
  getters: {
    unreadMessages ({ unreadMessages }) {
      return unreadMessages
    },
    newMessage ({ newMessage }) {
      return newMessage
    }
  },
  mutations: {
    SET_UNREAD_MESSAGES (state, payload) {
      state.unreadMessages = payload
    },
    SET_NEW_MESSAGE (state, payload) {
      state.newMessage = payload
    }
  }
}
