<template>
  <div class="sign-up-success-dialog">
    <DialogContainer
      :external-illustration="illustration"
      @closeDialog="closeDialog"
      v-if="config"
    >
      <template v-slot:content v-if="!loading">
        <div class="heading margin-y-lg" v-html="dialogTitle" />
        <div class="body margin-bottom" v-html="dialogSubtitle" />

        <ListInfo
          :items="config.items"
          :only-description="b2b && b2b.benefits && b2b.benefits.length > 0"
        />

        <Button
          @clicked="closeDialog"
          :text="config.button_label[language.lang]"
          class="margin-top"
        />
      </template>

      <template v-slot:content v-else>
        <div class="skeleton-class">
          <Skeleton width="100%" height="100px" border-radius="16px" />
          <Skeleton width="100%" height="64px" border-radius="16px" />
        </div>
        <div class="content-info" v-for="i in config.items.length" :key="i">
          <Skeleton width="48px" height="20px" border-radius="16px" />
          <Skeleton width="100%" height="60px" border-radius="16px" />
        </div>
        <Skeleton width="100%" height="48px" border-radius="8px" />
      </template>
    </DialogContainer>

    <SliderCarrousel
      :slides="slides"
      :totalSteps="totalSteps"
      :callback="closeDialog"
      :close="closeDialog"
      :modal="true"
      v-if="slides && !loading"
    />
  </div>
</template>

<script>
import {
  Button,
  DialogContainer,
  Settings,
  Promos,
  Skeleton,
  ListInfo,
  SliderCarrousel
} from '@seliaco/red-panda'
import { OnboardingEvent } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'SignUpSuccessDialog',
  components: {
    DialogContainer,
    Button,
    Skeleton,
    ListInfo,
    SliderCarrousel
  },
  props: {
    flowkey: String
  },
  data () {
    return {
      config: null,
      b2b: false,
      loading: false,
      totalSteps: 0,
      slides: []
    }
  },
  mounted () {
    this.init()
    OnboardingEvent.onboardingWelcomeView()
    localStorage.setItem('OnboardingWelcomeDialog', JSON.stringify(true))
  },
  methods: {
    async init () {
      this.loading = true
      const response = await Settings.get(
        'ONBOARDING_MODAL_CONFIGURATION_DYNAMIC'
      ).then((o) => o.parse_value)

      const media = response[this.flowkey]

      if (media && media.length) {
        this.slides = media
        this.slideVariation()
      } else {
        this.config = media

        // replace
        if (this.config?.prices) {
          this.config.items.forEach((element) => {
            const country =
              this.config.prices[this.user.country] || this.config.prices.US

            element.subtitle[this.language.lang] = element.subtitle[
              this.language.lang
            ].replaceAll('{PRICE}', country)

            return element
          })
        }

        // has b2b
        await this.hasB2B()
      }

      this.loading = false
    },
    async hasB2B () {
      this.b2b = await Promos.Benefits.getBenefits().catch(
        () => (this.loading = false)
      )

      if (this.b2b.benefits && this.b2b.benefits.length > 0) {
        const items = this.b2b.benefits.map((element) => ({
          image:
            'https://eoztuwqsaeoenxmxbvia.supabase.co/storage/v1/object/public/assets/gift.png',
          description: element.description
        }))
        this.config.items = [...items]
      }
    },
    slideVariation () {
      this.slides = this.slides.map((element) => ({
        ...element,
        title: element.title[this.language.lang],
        text: element.text[this.language.lang],
        button: {
          ...element.button,
          text: element.button.text[this.language.lang]
        }
      }))

      this.totalSteps = this.slides.length
    },
    closeDialog () {
      this.$globalEvent.$emit('modal/sign-up-success', {
        showDialog: { signUpSuccess: { open: false, close: true } }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    }),
    illustration () {
      return {
        url: this.config.image,
        alt: 'Selia-Confetti'
      }
    },
    dialogTitle () {
      const text = this.config.title[this.language.lang]
      if (!text) {
        return
      }
      return text.replaceAll('{USER_NAME}', this.user.first_name)
    },
    dialogSubtitle () {
      if (this.config.subtitle) {
        const text = this.config.subtitle[this.language.lang]
        if (this.b2b?.company?.name) {
          return text.replaceAll('{COMPANY}', this.b2b.company.name)
        }
        return text
      }

      return ''
    }
  }
}
</script>

<style lang="sass" scoped>
.content-info
  display: grid
  grid-template-columns: 32px auto
  @media (min-width: 840px)
    grid-template-columns: 48px auto
  padding: 16px
  gap: 16px
  text-align: left
  &-text
    display: flex
    flex-direction: column
    gap: 4px
  &-image
    width: 100%
.skeleton-class
  display: flex
  gap: 8px
  flex-direction: column
  background: white
</style>
