<template>
  <DialogContainer
    icon="icon-information-on text-purple"
    contentIconBackground="bg-purple-10"
    @closeDialog="handlerClose()"
  >
    <template v-slot:content>
      <div class="bussiness-title">
        {{ $translations.bussiness.dialog.title }}
      </div>
      <div class="bussiness-subtitle">
        {{ $translations.bussiness.dialog.confirmation }}
      </div>
      <Button @clicked="goBussiness" :text="$translations.bussiness.dialog.button" />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Promos, Button } from '@seliaco/red-panda'
import { BussinessEvent } from '@/types/events'

export default {
  name: 'BussinessDialog',
  components: {
    DialogContainer,
    Button
  },
  methods: {
    async handlerClose (updateAcknowledge = true) {
      if (updateAcknowledge) {
        await Promos.Benefits.updateAcknowledge(true)
        localStorage.setItem('BussinessBenefit', JSON.stringify(true))
      }
      this.$globalEvent.$emit('modal/bussiness-benefit', {
        showDialog: { bussinessBenefit: { open: false, close: true } }
      })
    },
    goBussiness () {
      this.$segment.track(BussinessEvent.select_seebenefit_business)
      this.handlerClose(false)
      this.$router.push('/bussiness-benefit')
    }
  }
}
</script>
<style lang="sass" scoped>
.bussiness-title
  width: 100%
  font-size: var(--xl)
  line-height: 120%
  font-weight: bold
  margin-top: 16px
  text-align: center

.bussiness-subtitle
  width: 100%
  margin-top: 16px
  text-align: center
  font-size: var(--base)
  margin-bottom: 44px
</style>
